import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useRef, useState } from "react";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { YuJaRadioBox } from "../../components/standardization/YuJaRadiobox";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { YuJaTooltip } from "../../components/standardization/YuJaTooltip";
import { ReactComponent as GradedIcon } from "../../images/GradedIcon.svg";
import ArrowIcon from "../../images/arrow_right.svg";
import {ReactComponent as NoResultIllustration} from "../../images/no_result.svg";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import { useLoading } from "../../utils/LoadingContext";
import {
    ANSWER_TEXT,
    NO_RESULTS_FOUND,
    CI_COORDINATE_SIZE,
    DEFAULT_IMAGE_ALT,
    GRADED_SHORT,
    IS_CORRECT_PREFIX,
    NOT_GRADED,
    NO_RESPONSE,
    POLL_TYPE,
    QUESTION_TEXT,
    QUESTION_TYPES,
    SEARCH_POLL,
    SEARCH_TEXT,
    UNANSWERED,
    UNGRADED_Cap,
    FIXED_ANSWER,
    POLL_SHARE_MODE,
    TEXTBOX, GRADE_TEXT
} from "../../utils/constants";
import { getAnswer } from "../../utils/helpers";
import { decodeCIParam } from "../../utils/questionUtils";
import { FilterDropDown } from "./FilterDropDown";
import styles from "./GradeQuestion.module.css";

export default function GradeQuestion({
    question,
    questions,
    attemptNo,
    response,
    setResponse,
    responses,
    pollShareMode, 
    // setResponses,
    institutionId,
    pollKey,
    // pollCode,
    // pollTitle,
    pollType,
    isCorrect,
    setIsCorrect,
    changedCorrect,
    setChangedCorrect,
    // lmsAttempt,
    // handleClose,
    // autoUpdateScore,
    // autoSync,
    handleSave
}) {
    // const history = useHistory();
    // const { queTitle, serialNo, suffix = "", image = "", imageAlt } = question;
    const [selectedQuestion, setSelectedQuestion] = useState(question);
    const [gradeEnabled, setGradeEnabled] = useState(!!question.weightage && pollType.toUpperCase !== POLL_TYPE.SURVEY);
    // const { gradeQuestion } = ResponseApiCalls();
    const { setLoading } = useLoading();
    const [questionImageURL, setQuestionImageURL] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const imageRef = useRef(null);
    const students = responses.filter(r => r.attempts).map(r => ({ label: `${r.firstName}${r.lastName ? " " + r.lastName : ""}`, value: `${r.userId}` }));
    students.sort((a, b) => a.label.localeCompare(b.label));
    const [data, setData] = useState(students);
    const [student, setStudent] = useState(students.find(r => r.value === response.userId));
    const [isVideo, setIsVideo] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [currAttempt, setCurrAttempt] = useState(`${attemptNo}`);
    const [gradedSelected, setGradedSelected] = useState(true);
    const [ungradedSelected, setUngradedSelected] = useState(true);
    const [hasScrollbar, setHasScrollbar] = useState(null);
    const divRef = useRef(null);

    // useEffect(() => {
    //     setStudent(!!response ? {label: `${response.firstName}${response.lastName ? " " + response.lastName : ""}`, value: `${response.userId}`} : {});
    // }, []);

    // useEffect(() => {
    //     setIsCorrect(prevState => ({
    //         ...prevState,
    //         [response.userId]: !!response && response.attempts[attemptNo].hasOwnProperty(IS_CORRECT_PREFIX + serialNo) ? response.attempts[attemptNo][IS_CORRECT_PREFIX + serialNo] : false
    //       }));
    // }, [response]);

    useEffect(() => {
        setLoading(true);
        // if (!isCorrect.hasOwnProperty(student.value)){
        // setIsCorrect(prevState => ({
        //     ...prevState,
        //     [student.value]: !!response && response.attempts[attemptNo].hasOwnProperty(IS_CORRECT_PREFIX + serialNo) ? response.attempts[attemptNo][IS_CORRECT_PREFIX + serialNo] : false
        //   }));
        // }
        setLoading(false);
    }, [student]);

    useEffect(() => {
        setImageLoaded(false);
        if(selectedQuestion.directLinkEvp) {
            setQuestionImageURL(selectedQuestion.directLinkEvp);
            setIsVideo(true);
        } else {
            setQuestionImageURL(selectedQuestion.imageURL);
            setIsVideo(false);
        }

    }, [institutionId, pollKey, selectedQuestion]);

    const SearchName = (keyword) => {
        let result = students.filter(student => student.label.toLowerCase().includes(keyword.toLowerCase()));
        setData(result.sort((a, b) => a.label.localeCompare(b.label)));
    };

    // const FilterStudents = (correct) => {
    //     if (correct) {
    //         let result = students.filter(student => isCorrect.hasOwnProperty(student.value));
    //         setData(result.sort((a, b) => a.label.localeCompare(b.label)));
    //     } else {
    //         let result = students.filter(student => !isCorrect.hasOwnProperty(student.value));
    //         setData(result.sort((a, b) => a.label.localeCompare(b.label)));
    //     }
    // };

    const EditStudent = (studentId, correct) => {
        setIsCorrect(prevState => ({
            ...prevState,
            [studentId]: {
                ...prevState[studentId],
                [selectedQuestion.serialNo]: {
                    ...prevState[studentId]?.[selectedQuestion.serialNo],
                    [currAttempt]: correct,
                },
            },
        }));

        setChangedCorrect(prevState => ({
            ...prevState,
            [studentId]: {
                ...prevState[studentId],
                [selectedQuestion.serialNo]: {
                    ...prevState[studentId]?.[selectedQuestion.serialNo],
                    [currAttempt]: correct,
                },
            },
        }));
    };

    useEffect(() => {
        if (JSON.stringify(changedCorrect) === "{}") {
            return;
        }

        console.log(`changedCorrect: ${JSON.stringify(changedCorrect)}`)

        // handleSave();
    }, [changedCorrect]);

    useEffect(() => {
        let serialNo = selectedQuestion.serialNo;

        for (const response of responses) {
            if ((!isCorrect.hasOwnProperty(response.userId) || !isCorrect[response.userId].hasOwnProperty(serialNo)) && response.attempts) {
                for (const [attemptKey, attemptValue] of Object.entries(response.attempts)) {
                    if (attemptKey != 0 && attemptValue.hasOwnProperty("Q" + serialNo) && attemptValue["Q" + serialNo] !== NOT_GRADED && attemptValue["Q" + serialNo] !== NO_RESPONSE) {
                        setIsCorrect(prevState => ({
                            ...prevState,
                            [response.userId]: {
                                ...prevState[response.userId],
                                [serialNo]: { // Include question number as a key
                                    ...prevState[response.userId]?.[serialNo], // Preserve existing data for the question
                                    [attemptKey]: !!attemptValue && attemptValue.hasOwnProperty(IS_CORRECT_PREFIX + serialNo) ? attemptValue[IS_CORRECT_PREFIX + serialNo] : false
                                }
                            }
                        }));
                    }
                }
            }
        }
    }, [responses, selectedQuestion]);

    useEffect(() => {
        let serialNo = selectedQuestion.serialNo;
        if (currAttempt !== null && (!isCorrect.hasOwnProperty(student.value) || !isCorrect[student.value]?.[serialNo]?.hasOwnProperty(currAttempt))) {
            for (const response of responses) {
                if (response.hasOwnProperty("attempts") && response.studentId === student.value && response.attempts[currAttempt]?.hasOwnProperty("Q" + serialNo) && response.attempts[currAttempt]["Q" + serialNo] !== NOT_GRADED && response.attempts[currAttempt]["Q" + serialNo] !== NO_RESPONSE) {
                    setIsCorrect(prevState => ({
                        ...prevState,
                        [student.value]: {
                            ...prevState[student.value],
                            [serialNo]: {
                                ...prevState[student.value]?.[serialNo],
                                [currAttempt]: !!response && response.attempts[currAttempt].hasOwnProperty(IS_CORRECT_PREFIX + serialNo) ? response.attempts[currAttempt][IS_CORRECT_PREFIX + serialNo] : false,
                            },
                        },
                    }));
                    break;
                }
            }
        }
    }, [currAttempt]);

    useEffect(() => {
        let result = students;
        if(!gradedSelected){
            result = result.filter(student => !isCorrect.hasOwnProperty(student.value));
        }
        if (!ungradedSelected) {
            result = result.filter(student => isCorrect.hasOwnProperty(student.value));
        }
        setData(result.sort((a, b) => a.label.localeCompare(b.label)));
    }, [gradedSelected, ungradedSelected])

    // const changeCurrAttempt = (student) => {
    //     if (lmsAttempt === SYNC_ATTEMPT.RECENT.value) {
    //         const response = responses.find(r => r.userId === student.value)
    //         return `${Object.keys(response.attempts).length - 1}`
    //     }
    //     else {
    //         return "1"
    //     }
    // }

    // const onSave = () => {
    //     // if (newResponse[IS_GRADED_PREFIX + serialNo] && response[IS_CORRECT_PREFIX + question.serialNo] === correct) {
    //     //     handleClose();
    //     //     return;
    //     // }

    //     // let newResponse = {...response};
    //     // newResponse[IS_GRADED_PREFIX + serialNo] = true;
    //     // newResponse[IS_CORRECT_PREFIX + serialNo] = correct;

    //     // var earnedPoints = 0;
    //     // var totalPoints = 0;

    //     // for (const question of questions) {
    //     //     if (newResponse[IS_CORRECT_PREFIX + question.serialNo]) {
    //     //         earnedPoints += question.weightage;
    //     //         console.log(`${question.weightage} / ${question.weightage}`);
    //     //     } else {
    //     //         console.log(`0 / ${question.weightage}`);
    //     //     }
    //     //     totalPoints += question.weightage;
    //     // }
    //     // const score = ((earnedPoints/totalPoints)*100).toFixed(2); // 2 Decimal Places
    //     // console.log(score);
    //     // autoUpdateScore(score);
    //     // autoSync(pollCode, newResponse["userId"], newResponse["score"]);

    //     // let newResponses = [...responses];
    //     // if (newResponses.find(r => r.userId === student.value)) {
    //     //     newResponses[newResponses.findIndex(r => r.userId === student.value)].attempts[attemptNo] = newResponse;
    //     // }
    //     // setResponses(newResponses);
    //     setLoading(true);
    //     try {
    //         var questionObj = question;
    //         questionObj["selectedOption"] = getAnswer();
    //         gradeQuestion(institutionId, pollKey, pollCode, response.userId, correct, questionObj, attemptNo);
    //         notifySuccess(GRADE_QUESTION_SUCCESS);
    //     } catch (error) {
    //         console.log(error);
    //         notifyError(GRADE_QUESTION_ERROR);
    //     }
    //     history.push({
    //         pathname: "/gradebook", 
    //         state: {
    //             pollKey : removePrefixNew(pollKey),
    //             uniqueCode: removePrefixNew(pollCode),
    //             autoSync: {
    //                 userId: response.userId,
    //                 // score: newResponses[newResponses.findIndex(r => r.userId === student.value)].attempts[0] // score picked from main attempt
    //             }
    //         }
    //     });
    //     handleClose();
    //     setLoading(false);
    // };

    const renderAnswer = () => {
        const { serialNo, questionType, optionsMap, optionsImageMap={}} = selectedQuestion;
        const answer = response.attempts[currAttempt]['A' + serialNo];
        if (response.attempts[currAttempt]['Q' + serialNo] === NO_RESPONSE || answer === FIXED_ANSWER.UNANSWERED || !answer) {
            return FIXED_ANSWER.UNANSWERED;
        }
        switch (questionType) {
            case QUESTION_TYPES.MCSS.name:
                if (!gradeEnabled) {
                    return answer.map((key, index) => {
                        return (
                            <p
                                key={index}
                                style={{ marginTop: index === 0 ? 0 : 10, marginBottom: 0, gap: 5, display: "flex", alignItems: "center" }}
                            >
                                <span style={{width: 20, flexShrink: 0}}>{key.toUpperCase() + "."}</span>
                                {optionsImageMap.hasOwnProperty(key) && !!optionsImageMap[key].imageURL &&
                                    <div className="MCSS-opt-image-container" style={{width: 40, height: 40, borderRadius: 3}} >
                                        <img
                                            tabIndex={0}
                                            role={TEXTBOX}
                                            alt={`Option ${key} image preview`}
                                            src={`${optionsImageMap[key].imageURL}`}
                                            style={ { maxWidth: "100%", maxHeight: "100%"}}
                                        />
                                    </div>
                                }
                                <span>{optionsMap[key]}</span>
                            </p>
                        );
                    });
                }
                return (
                    <p  style={{ marginTop: 0, marginBottom: 0, gap: 5, display: "flex", alignItems: "center" }}
                    >
                        <span style={{width: 20, flexShrink: 0}}>{answer.toUpperCase() + "."}</span>
                        {optionsImageMap.hasOwnProperty(answer) && !!optionsImageMap[answer].imageURL &&
                            <div className="MCSS-opt-image-container" style={{width: 40, height: 40, borderRadius: 3}} >
                                <img
                                    tabIndex={0}
                                    role={TEXTBOX}
                                    alt={`Option ${answer} image preview`}
                                    src={`${optionsImageMap[answer].imageURL}`}
                                    style={ { maxWidth: "100%", maxHeight: "100%"}}
                                />
                            </div>
                        }
                        <span>{optionsMap[answer]}</span>
                    </p>
                );
            case QUESTION_TYPES.TF.name:
                return answer;
            case QUESTION_TYPES.SA.name:
                return getAnswer(response, currAttempt, selectedQuestion.serialNo);
            case QUESTION_TYPES.OE.name:
                return getAnswer(response, currAttempt, selectedQuestion.serialNo);
            case QUESTION_TYPES.FITB.name:
                return Object.keys(answer).map((key, index) =>
                    <p
                        key={index}
                        style={{ marginTop: index === 0 ? 0 : 10, marginBottom: 0, wordBreak: 'break-word' }}
                    >
                        {key}: {answer[key]}
                    </p>
                );
            case QUESTION_TYPES.CI.name:
                return (<div className="img-container" style={{ display: 'flex', justifyContent: 'left', flex: "1", marginRight: 0, padding: "0px 25px 0px 0px" }}>
                    <div style={{ position: "relative" }}>
                        <img ref={imageRef} className={`${styles.saImg}`} src={questionImageURL} tabIndex={0} aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT} alt="Question Image" onLoad={() => setImageLoaded(true)} />
                        {questionImageURL && imageLoaded && imageRef.current !== null && !!answer?.x && !!answer?.y &&
                            <COORDINATE
                                style={{
                                    position: "absolute",
                                    top: `${decodeCIParam(answer.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                    left: `${decodeCIParam(answer.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                }}
                            />
                        }
                    </div>
                </div>);
            case QUESTION_TYPES.MH.name:
                return (selectedQuestion.optionsMap.filter(sublist => sublist.length === 2).map((key, index) => {
                	if (key === "") return <></>;
                	return (
                		<div style={{ display: "flex", width: "100%", gap: "10px", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                			<p className="questionPanelAnswersText" id={"OptionPart1" + index} style={{ border: ".5px solid #909090", padding: "10px", borderRadius: "3px", flex: 1, fontSize: 14, margin: 0, }}>
                				{key[0]}
                			</p>
                			<img
                				src={ArrowIcon}
                				alt="Arrow icon"
                				className="questions-page-icons cursor-pointer"
                				style={{ width: 30}}
                			/>
                			<p className="questionPanelAnswersText" id={"OptionPart2" + index} style={{ border: ".5px solid #909090", padding: "10px", borderRadius: "3px", flex: 1, fontSize: 14, margin: 0}}>
                				{answer.hasOwnProperty(index) ? answer[index] : UNANSWERED}
                			</p>
                		</div>
                	);
                }));
            case QUESTION_TYPES.RK.name:
                return (answer.map((key, index) =>
                    <p
                        key={index}
                        tabIndex={0}
                        aria-label={`Rank ${index + 1}:  ${key}`}
                        style={{ marginTop: index === 0 ? 0 : 10, marginBottom: 0, wordBreak: 'break-word' }}
                    >
                        {`Rank ${index + 1}:  ${key}`}
                    </p>
                ));
            case QUESTION_TYPES.WC.name:
                return Object.values(answer)
                    .filter(key => key !== "").map((key, index) => {
                        if (key === "") return <></>;
                        return <p
                            key={index}
                            style={{ marginTop: index === 0 ? 0 : 10, marginBottom: 0, wordBreak: 'break-word' }}
                        >
                            Entry {index + 1}: {key}
                        </p>
                    });
            default:
                return <></>;
        }
    };

    useEffect(() => {
        const divElement = divRef.current;
        if (divElement) {
            const hasVerticalScrollbar = divElement.scrollHeight > divElement.clientHeight;
            if(hasScrollbar == null) {
                setHasScrollbar(!hasVerticalScrollbar);
            }
            else {
            setHasScrollbar(hasVerticalScrollbar);
            }
        }
    }, [gradedSelected, ungradedSelected]);

    return (
        <>
            <div style={{ display: "flex", overflowX: "auto" }}>
                <div ref={divRef} style={{ paddingRight: hasScrollbar ? 33 : 25, minWidth: "200px", height: "400px", overflowY: "auto", resize: "horizontal" }}>
                    <div style={{ display: "flex", gap: 5, alignItems: "center", flexWrap: "wrap" }}>
                        {gradeEnabled && <div>
                            <FilterDropDown isDropDownOpen={isDropDownOpen} setIsDropDownOpen={setIsDropDownOpen}>
                                <FilterDropDown.Menu >
                                    <FilterDropDown.Item>
                                        <div style={{ display: "flex" }}>
                                            <input
                                                type="checkbox"
                                                tabIndex={0}
                                                aria-label={`checkbox graded`}
                                                className="option-checkbox"
                                                checked={gradedSelected}
                                                onChange={() => { setGradedSelected(!gradedSelected) }}
                                            />
                                            <div style={{ fontSize: "13px",  color: "#000" }}>
                                                {GRADED_SHORT}
                                            </div>
                                        </div>
                                    </FilterDropDown.Item>

                                    <FilterDropDown.Item>
                                        <div style={{ display: "flex" }}>
                                            <input
                                                type="checkbox"
                                                tabIndex={0}
                                                aria-label={`checkbox ungraded`}
                                                className="option-checkbox"
                                                checked={ungradedSelected}
                                                onChange={() => { setUngradedSelected(!ungradedSelected) }}
                                            />
                                            <div style={{ fontSize: "13px",  color: "#000" }}>
                                                {UNGRADED_Cap}
                                            </div>
                                        </div>
                                    </FilterDropDown.Item>
                                </FilterDropDown.Menu>
                            </FilterDropDown>
                        </div>}
                        <div style={{display: "flex", alignItems: "center", gap: 5, flex: 1}}>
                            <YuJaTextBox
                                label={SEARCH_POLL}
                                containerStyle={{ fontSize: 13, flex: 1, minWidth: 120 }}
                                placeholder={SEARCH_TEXT}
                                before={<SearchIcon style={{ marginRight: 5, width: 20, height: 20 }} />}
                                onChange={e => setKeyword(e.target.value)}
                            />
                            <YuJaButton style={{ height: "32px", width: "61px", fontSize: "14px", display: "flex", justifyContent: "center" }} onClick={() => { SearchName(keyword) }}>Search</YuJaButton>
                        </div>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        {data.map((item, index) => (
                            <div
                                onClick={() => {
                                    setStudent(item);
                                    setResponse(responses.find(r => r.userId === item.value));
                                    // if (pollType.toUpperCase() !== POLL_TYPE.SURVEY) {
                                    setCurrAttempt(null);
                                    // }
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 5,
                                    cursor: "pointer",
                                    border: "0.459px solid #787878",
                                    borderTop: index === 0 ? "0.459px solid #787878" : "none",
                                    fontSize: "14px",
                                    color: "#585858",
                                    padding: "8.263px 11.935px",
                                    backgroundColor: item == student ? "#E8E8E8" : "#FFF",
                                }}
                            >
                                <span style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", flex: 1, minWidth: 0}}>
                                    {item.label}
                                </span>
                                {gradeEnabled && isCorrect.hasOwnProperty(item.value) && isCorrect[item.value].hasOwnProperty(selectedQuestion.serialNo) &&
                                    <YuJaTooltip text={"Graded student"} placement="bottom"><GradedIcon /></YuJaTooltip>
                                }
                            </div>
                        ))}
                        {
                            data.length === 0 &&
                            <div style={{height:300, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", gap: "5px"}}> <NoResultIllustration /> <div style={{fontSize: "20px",  marginTop: "10px", fontWeight: 600}}> {NO_RESULTS_FOUND} </div> </div> 
                        }
                    </div>
                </div>
                <div style={{ flex: 1, paddingLeft: "25px", minWidth: "350px", borderLeft: "0.5px solid #858585", display: "flex", flexDirection: "column", gap: 10 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", rowGap: 5, columnGap: 20 }}>
                        <div style={{ color: "#000",  fontSize: "14px", fontWeight: "700", }}>
                            {student?.label}
                        </div>
                        <div style={{ display: "flex", gap: "15px" }}>
                            <YuJaDropdown
                                data={pollShareMode === POLL_SHARE_MODE.SHARE_EACH ? questions.filter(q => q?.shareCount !== undefined) : questions }
                                placeholder={"Please select a question"}
                                getOptionLabel={option => "Question " + option.serialNo}
                                getOptionValue={option => option}
                                value={selectedQuestion}
                                textStyle={{ wordBreak: "break-word" }}
                                onChange={(option) => {
                                    setQuestionImageURL("");
                                    setGradeEnabled(!!option?.weightage && pollType?.toUpperCase !== POLL_TYPE.SURVEY);
                                    setSelectedQuestion(option);
                                }}
                                style={{
                                    fontSize: 14, padding: 10, gap: 5, height: "34px", borderRadius: "3px", borderColor: "#909090", minWidth: 120
                                }}
                            />
                            <YuJaDropdown
                                data={Object.keys(response.attempts).filter(key => key !== '0')}
                                placeholder={"Select an Attempt"}
                                getOptionLabel={option => "Attempt " + option}
                                getOptionValue={option => option}
                                value={currAttempt}
                                textStyle={{ wordBreak: "break-word" }}
                                onChange={(option) => {
                                    setCurrAttempt(option);
                                }}
                                style={{
                                    fontSize: 14, padding: 10, gap: 5, height: "34px", borderRadius: "3px", borderColor: "#909090", minWidth: 120
                                }}
                            />
                        </div>
                    </div>
                    <hr style={{ margin: "0px" }} />
                    {currAttempt !== null &&
                        <div style={{ flex: 1, overflowY: "auto", border: "1px solid #858585",  borderRadius: "4px", display: "flex", flexDirection: "column", gap: "11px", padding: "13px" }}>
                            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                <div>
                                    <div style={{
                                        fontSize: 15,
                                        fontWeight: "700",
                                    }}>
                                        {QUESTION_TEXT} {selectedQuestion.serialNo}:
                                    </div>
                                    <div style={{
                                        fontSize: 14,
                                        fontWeight: "400",
                                        wordBreak: "break-word"
                                    }}>
                                        {selectedQuestion.queTitle}
                                    </div>
                                </div>


                                {questionImageURL && (selectedQuestion.questionType !== QUESTION_TYPES.CI.name || response['Q' + selectedQuestion.serialNo] === NO_RESPONSE) && (
                                    <div className={`${styles.questionImgContainer}`} style={{ height: 200}}>
                                        {!isVideo &&
                                            <img className={`${styles.saImg}`} tabIndex={0}
                                                          aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}
                                                          src={questionImageURL} alt="Question"/>
                                        }
                                        {isVideo &&
                                            <iframe className={`${styles.saImg}`} allowFullScreen src={questionImageURL}
                                                    alt="Question"/>}
                                    </div>
                                )}

                                <div >
                                    <div style={{fontSize: 15, fontWeight: "700"}}>
                                        {ANSWER_TEXT}:
                                    </div>
                                    <div style={{fontSize: 14, fontWeight: "400", wordBreak: "break-word", minWidth: 0}}>
                                        {renderAnswer()}
                                    </div>
                                </div>


                                {gradeEnabled &&
                                    <div >
                                        <div style={{fontSize: 15, fontWeight: "700"}}>
                                            {GRADE_TEXT}:
                                        </div>
                                        <div style={{display: "flex", gap: "20px", marginTop: 3}}>
                                            <YuJaRadioBox
                                                value="correct"
                                                name="isCorrect"
                                                onClick={() => EditStudent(student.value, true)}
                                                checked={isCorrect && isCorrect[student.value] && isCorrect[student.value][selectedQuestion.serialNo] && isCorrect[student.value][selectedQuestion.serialNo][currAttempt]}
                                                label={"Correct"}
                                                style={{fontSize: "14px"}}
                                                // disabled={response.attempts[currAttempt]["Q" + selectedQuestion.serialNo] === NO_RESPONSE || response.attempts[currAttempt]["A" + selectedQuestion.serialNo] === FIXED_ANSWER.UNANSWERED}
                                                // disabled={isDisabledRadio(attemptNo, response?.attempts, lmsAttempt, pollType?.toUpperCase())}
                                            />
                                            <YuJaRadioBox
                                                value="incorrect"
                                                name="isCorrect"
                                                onClick={() => EditStudent(student.value, false)}
                                                checked={isCorrect && isCorrect[student.value] && isCorrect[student.value][selectedQuestion.serialNo] && isCorrect[student.value][selectedQuestion.serialNo][currAttempt] === false}
                                                label={"Incorrect"}
                                                style={{fontSize: "14px"}}
                                                // disabled={response.attempts[currAttempt]["Q" + selectedQuestion.serialNo] === NO_RESPONSE || response.attempts[currAttempt]["A" + selectedQuestion.serialNo] === FIXED_ANSWER.UNANSWERED}
                                                // disabled={isDisabledRadio(attemptNo, response?.attempts, lmsAttempt, pollType?.toUpperCase())}
                                            />
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
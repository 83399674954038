import "bootstrap/dist/css/bootstrap.css";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { YuJaRadioBox } from "../../components/standardization/YuJaRadiobox";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from "../../hooks/useMobileAccess";
import ArrowIcon from "../../images/arrow-right-big.svg";
import { ReactComponent as ArrowRight } from "../../images/arrow-right-question.svg";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import DragDropIcon from "../../images/drag_drop.svg";
import { ReactComponent as THUMB_DOWN } from "../../images/thumbdownhovered.svg";
import { ReactComponent as THUMB_UP } from "../../images/thumbuphovered.svg";
import NormalBox from "../../public/boxes/NormalBox";
import { YuJaGlobalState } from "../../utils/LoadingContext";
import {
  ASCII_CODE_A,
  ASCII_CODE_SMALL_A,
  BLANK_REGEX,
  BLANK_REGEX_NEW,
  BLANK_REGEX_WRAPPED,
  BLANK_REGEX_WRAPPED_NEW,
  BUTTON,
  CI_COORDINATE_SIZE,
  CONFIRM_UNANSWERED_SUBMIT_CONTENT,
  CONFIRM_UNANSWERED_SUBMIT_TITLE,
  CORRECT_MODAL_BODY,
  CORRECT_MODAL_HEADER,
  DEFAULT_ENTRIES_PER_PARTICIPANT,
  DEFAULT_IMAGE_ALT,
  DEFAULT_SHOWN_ENTRIES,
  FIRST_WC_PLACEHOLDER,
  FITB_ANSWER_LENGTH,
  FIXED_ANSWER,
  INCORRECT_MODAL_BODY,
  INCORRECT_MODAL_HEADER,
  MCSSoptLower,
  OE_PLACE_HOLDER,
  OTHER_WC_PLACEHOLDER,
  POINTS_TEXT,
  POLL_TITLE_LABEL,
  PREMISE,
  QUESTION_TITLE,
  QUESTION_TYPES,
  QUIZ_STATE,
  RESPONSE,
  SA_MODAL_BODY,
  SA_MODAL_HEADER,
  SA_PLACE_HOLDER,
  SUBMITTED_MODAL_TEXT,
  SUBMITTED_TOAST_TITLE,
  SUBMIT_TEXT,
  TEXTBOX,
  THUMBS_DOWN,
  THUMBS_UP,
  TIMEUP_CORRECT_MODAL_BODY,
  TIMEUP_INCORRECT_MODAL_BODY,
  TIMEUP_MODAL_BODY,
  TIMEUP_MODAL_HEADER,
  UNGRADED_TEXT,
  WC_ANSWER_LENGTH
} from "../../utils/constants";
import {
  checkBlankAnswered,
  checkUnanswered, checkUnansweredNew,
  handleUserAns,
  newPageLog,
  notifyInfo,
  parseWCId
} from "../../utils/helpers";
import { decodeCIParam, encodeCIParam, getOption4CI } from "../../utils/questionUtils";
import CountdownTimer from "./CountdownTimer";
import NetworkStatus from "./NetworkStatus";
import { OptionImageViewer } from "./OptionImageViewer";
import correct from "./animations/correct.json";
import incorrect from "./animations/incorrect.json";
import deleteIcon from "./images/delete.svg";
import OpenEndedResult from "./openEndedResult";
import "./styles.css";
import styles from "./styles.module.css";

export default React.memo(PollReady, (prevProps, nextProps) => {
  return (prevProps.pollInfo === nextProps.pollInfo && prevProps.currentQuestion === nextProps.currentQuestion);
});

function PollReady({
                     pollInfo,
                     currentQuestion = {},
                     handleQuestionComplete,
                     handleSAQuestionComplete,
                     state,
                     stopped,
                     institutionId,
                    //  quesShareCount,
                     changeCurQue,
                     websocketState,
                     allSharecount,
                     totalQuestion,
                     setCountdownContent
}) {
  const isMobile = useMobileAccess(800);
  const isNarrow = useMobileAccess(1200);
  const {isOnline, effectiveType} = useContext(YuJaGlobalState);
  const { pollKey, uniqueCode, userName, pollTitle, showAnswerOnViewer} = pollInfo;
  const { question, questionEndTime, questionResponse = {} } = currentQuestion;
  const {
    queTitle,
    correctAnswers,
    optionsMap,
    optionsImageMap={},
    questionType,
    weightage = 0,
    timeLimit = 0,
    entries = DEFAULT_ENTRIES_PER_PARTICIPANT,
    serialNo,
    imageAlt,
    hasBlankRestriction = false
  } = question;
  const blankWrapperRegex = hasBlankRestriction ? BLANK_REGEX_WRAPPED_NEW : BLANK_REGEX_WRAPPED;
  const blankRegex = hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX;
  const gradeEnabled = !!weightage;
  const timed = !!timeLimit;

  const [isDropdownOpen, setIsDropdownOpen] = useState(null);

  const { selectedOption = null, isCorrect = null } = questionResponse;
  const [isResponseCorrect, setIsResponseCorrect] = useState(isCorrect);
  const [modalShow, setModalShow] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [canInput, setCanInput] = useState(true);
  const imageRef = useRef(null);
  // const timeoutRedirectRef = useRef(null);
  const wcInputValRef = useRef(null);
  const { getAudienceResponseByUser, handleSubmitIndividualResponse, updateReaction } = ResponseApiCalls();
  const [thumbup, setThumbUp] = useState(false);
  const [thumbdown, setThumbDown] = useState(false);
  const [WCElements, setWCElements] = useState([]);
  const [shownEntries, setShownEntries] = useState(entries); // (isNarrow || entries == 1 ? 1 : 2);
  const [alertShow, setAlertShow] = useState(false);
  const [clickedSub, setClickedSub] = useState(false);
  // const [hoveringThumbUp, setHoveringThumbUp] = useState(false);
  // const [hoveringThumbDown, setHoveringThumbDown] = useState(false);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [newRank, setNewRank] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [openEndedExpanded, setOpenEndedExpanded] = useState(false);
  const [curAnswer, setCurAnswer] = useState(() => {
    if (!checkUnansweredNew(!!weightage, questionType, selectedOption)) {
      return selectedOption;
    }

    //FITB
    if (questionType === QUESTION_TYPES.FITB.name) {
      const ans = {};
      for (const key of Object.keys(correctAnswers)) {
        ans[key] = "";
      }
      return ans;
    } else if (questionType === QUESTION_TYPES.WC.name) {
      const ans = {};
      for (let i = 0; i < entries; i++) {
        ans[i] = "";
      }
      return ans;
    } else if (questionType === QUESTION_TYPES.MH.name) {
      const ans = {};
      for (let i = 0; i < correctAnswers.length; i++) {
        ans[i] = "";
      }
      return ans;
    } else if (questionType === QUESTION_TYPES.RK.name) {
      return optionsMap.filter(option => option !== "");
    } else if (!gradeEnabled && questionType === QUESTION_TYPES.MCSS.name) {
      return [];
    }else {
      return FIXED_ANSWER.UNANSWERED;
    }
  });

  const [isAnsweredState, setIsAnsweredState] = useState(!!selectedOption);
  // BEGIN VARIABLE INIT FOR QUESTION TYPES
  let MCSSelements = [];
  let newOptionsMap = questionType === QUESTION_TYPES.MCSS.name ? optionsMap : {};
  const MCSSnum = Object.keys(newOptionsMap).length;

  let TFelements = ["TF0", "TF1"];
  const TFnum = 2;
  const TFopt = ["True", "False"];

  let FITBElements = [];
  let idx = -1;

  if (questionType === QUESTION_TYPES.MCSS.name) {
    for (let i = 0; i < MCSSnum; i++) {
      MCSSelements.push("MCSS" + i);
    }
  } else if (questionType === QUESTION_TYPES.FITB.name) {
    for (const key of Object.keys(correctAnswers)) {
      FITBElements.push(key);
    }
  }

  const corrOptions = useMemo(() => {
    return {
    loop: true,
    autoplay: true,
    animationData: correct,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      // filterSize: {
      //   width: '200%',
      //   height: '200%',
      //   x: '-50%',
      //   y: '-50%',
      // }
      }
    };
  }, [correct]);

  const incorrOptions = useMemo(() => {
    return {
      loop: true,
      autoplay: true,
      animationData: incorrect,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        // filterSize: {
        //   width: '200%',
        //   height: '200%',
        //   x: '-50%',
        //   y: '-50%',
        // }
      }
    };
  }, [incorrect]);


  // END VARIBALE INIT

  useEffect(() => {
    newPageLog("PollReady.js");
    if(isNarrow && document.getElementById("viewer-navbar-container") !== null) {
      document.getElementById("viewer-navbar-container").style.backgroundColor = "#FCFBFF";
    }

  }, []);

  if (document.getElementById("root") !== null) {
    document.getElementById("root").style.backgroundImage = "none";
  }
  // END DOCUMENT FORMATTING

  // const enableInput = () => {
  //   console.log("enableInput", questionType, gradeEnabled, curAnswer, isAnsweredState);
  //   if (questionType === QUESTION_TYPES.MCSS.name) {
  //     for (let i = 0; i < MCSSnum; i++) {
  //       if (document.getElementById("MCSS" + i) !== null)
  //         document.getElementById("MCSS" + i).disabled = false;
  //     }
  //     if (gradeEnabled && !isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }

  //     if (!gradeEnabled && !isAnsweredState && !!curAnswer && curAnswer.length) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }
  //   } else if (questionType === QUESTION_TYPES.TF.name) {
  //     for (let i = 0; i < TFnum; i++) {
  //       document.getElementById("TF" + i).disabled = false;
  //     }

  //     if (!isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }
  //   } else if (questionType === QUESTION_TYPES.SA.name) {
  //     document.getElementById("SA-IN").disabled = false;
  //     if (!isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED && Object.keys(curAnswer).length) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }
  //   } else if (questionType === QUESTION_TYPES.FITB.name) {
  //     for (const key of Object.keys(correctAnswers)) {
  //       document.getElementById(key).disabled = false;
  //     }
  //     if (!isAnsweredState) {
  //       document.getElementById("sub-btn").style.display = checkBlanks(curAnswer) ? "none" : "block";
  //     }
  //   } else if (questionType === QUESTION_TYPES.CI.name) {
  //     setCanInput(true);
  //     if (!isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED && Object.keys(curAnswer).length) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }

  //     //for multiple click survey
  //     // if (pollType === POLL_TYPE.SURVEY && !isAnsweredState && !!curAnswer && curAnswer.length) {
  //     //   document.getElementById("sub-btn").style.display = "block";
  //     // }
  //   } else if (questionType === QUESTION_TYPES.WC.name) {
  //     for (let i = 0; i < entries; i++) {
  //       document.getElementById(`WC-IN-${i}`).disabled = false;
  //     }
  //     if (!isAnsweredState) {
  //       document.getElementById("sub-btn").style.display = checkBlankAnswered(curAnswer) ? "none" : "block";
  //     }
  //   }
  // };

  const disableInput = () => {
    if (document.getElementById("sub-btn")) {
      document.getElementById("sub-btn").style.display = "none";
    }

    if (questionType === QUESTION_TYPES.MCSS.name) {
      for (let i = 0; i < MCSSnum; i++) {
        if (document.getElementById("MCSS" + i) !== null) {
          document.getElementById("MCSS" + i).disabled = true;
        }
      }
    } else if (questionType === QUESTION_TYPES.TF.name) {
      for (let i = 0; i < TFnum; i++) {
        document.getElementById("TF" + i).disabled = true;
      }
    } else if (questionType === QUESTION_TYPES.SA.name) {
      document.getElementById("SA-IN").disabled = true;
    } else if (questionType === QUESTION_TYPES.FITB.name) {
      for (const key of Object.keys(correctAnswers)) {
        document.getElementById(key).disabled = true;
      }
    } else if (questionType === QUESTION_TYPES.CI.name) {
      setCanInput(false);
    } else if (questionType === QUESTION_TYPES.WC.name) {
      for (let i = 0; i < entries; i++) {
        if(document.getElementById(`WC-IN-${i}`)) {
          document.getElementById(`WC-IN-${i}`).disabled = true;
        }
      }
    } else if (questionType === QUESTION_TYPES.MH.name) {
      for (let element of document.getElementsByName("MH-dropdown")) {
        element.disabled = false;
      }
    }
  };

  useEffect(() => {
    const subBtn = document.getElementById("sub-btn");
    if (state === QUIZ_STATE.POLL_TIME && !gradeEnabled && subBtn) {
      subBtn.style.display = "block";
    }
  }, [state]);

  useEffect(() => {
    if (isResponseCorrect === null) {
      return;
    }

    if (!gradeEnabled) {
      handleQuestionComplete();
      return;
    }


    const isTimeUp = stopped || isTimeOut;

    if (isMobile) {
      setModalShow(true);
      if (isTimeUp) {
        if (questionType === QUESTION_TYPES.SA.name) {
          setModalMessage(TIMEUP_MODAL_HEADER + ". " + SA_MODAL_BODY);
        } else {
          setModalMessage(TIMEUP_MODAL_HEADER + ". " + TIMEUP_MODAL_BODY);
        }
      } else {
        if (!clickedSub) {
          return;
        }

        if (questionType === QUESTION_TYPES.SA.name) {
          setModalMessage(SA_MODAL_BODY);
        } else if (gradeEnabled && showAnswerOnViewer && isResponseCorrect) {
          setModalMessage(CORRECT_MODAL_HEADER + ". " + CORRECT_MODAL_BODY);
        } else if (gradeEnabled && showAnswerOnViewer && !isResponseCorrect) {
          setModalMessage(INCORRECT_MODAL_HEADER + ". " + INCORRECT_MODAL_BODY);
        } else {
          setModalMessage(SUBMITTED_MODAL_TEXT);
        }
      }
    } else {
      if (isTimeUp) {
        if (questionType === QUESTION_TYPES.SA.name) {
          notifyInfo(SA_MODAL_BODY, true, TIMEUP_MODAL_HEADER);
        } else {
          notifyInfo(TIMEUP_MODAL_BODY, true, TIMEUP_MODAL_HEADER);
        }
      } else {
        if (!clickedSub) {
          return;
        }

        if (questionType === QUESTION_TYPES.SA.name) {
          notifyInfo(SA_MODAL_BODY, true, SA_MODAL_HEADER);
        } else if (gradeEnabled && showAnswerOnViewer && isResponseCorrect) {
          notifyInfo(CORRECT_MODAL_BODY, true, CORRECT_MODAL_HEADER);
        } else if (gradeEnabled && showAnswerOnViewer && !isResponseCorrect) {
          notifyInfo(INCORRECT_MODAL_BODY, true, INCORRECT_MODAL_HEADER);
        } else {
          notifyInfo(SUBMITTED_MODAL_TEXT, true, SUBMITTED_TOAST_TITLE);
        }
      }
    }

    if (state === QUIZ_STATE.POLL_TIME) {
      if (questionType === QUESTION_TYPES.SA.name) {
        document.body.classList.add("not-graded");
      } else if (isResponseCorrect) {
        document.body.classList.add("correct-ans");
      } else {
        document.body.classList.add("wrong-ans");
      }
    }

    return () => {
      if (questionType === QUESTION_TYPES.SA.name) {
        document.body.classList.remove("not-graded");
      } else if (isResponseCorrect) {
        document.body.classList.remove("correct-ans");
      } else {
        document.body.classList.remove("wrong-ans");
      }
    };
  }, [isResponseCorrect, isTimeOut, stopped]);

  useEffect(() => {
    if (!isOnline) {
      return;
    }

    if(question.directLinkEvp) {
      setIsVideo(true);
    }else {
      setIsVideo(false);
    }
  }, [institutionId, pollKey, serialNo, isOnline]);

  useEffect(() => {
    if (!setCountdownContent) {
      console.log("cannot set countdown content");
      return;
    }
    if (!timed || window.innerWidth >= 1200) {
      console.log("setting null countdown content");
      setCountdownContent(null);
    } else {
      console.log("setting countdown content");
      setCountdownContent(<CountdownTimer
        duration={timeLimit}
        questionEndTime={questionEndTime}
        setIsTimeOut={setIsTimeOut}
        state={state}
      />);
    }
  }, [setCountdownContent, window.innerWidth, timed, timeLimit, questionEndTime, state]);

  const autoScroll = () => {
    if (window.innerHeight >= 480 || window.innerWidth <= window.innerHeight) {
      return;
    }
    const mainContainer = document.getElementById("everything");
    const questionContainer = document.getElementById("question-container");
    if (!mainContainer || !questionContainer) {
      return;
    }
    const questionContainerTop = questionContainer.getBoundingClientRect().top;
    const mainContainerTop = mainContainer.getBoundingClientRect().top;
    const scrollDistance = questionContainerTop - mainContainerTop;
    mainContainer.scrollTo({
      top: mainContainer.scrollTop + scrollDistance,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(autoScroll, 100);
  }, [window.innerWidth, window.innerHeight, state]);

  const handleType = (e) => {
    e.stopPropagation();
    if (isAnsweredState) {
      return;
    }
    if (questionType === QUESTION_TYPES.SA.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("SA-IN").value = "";
        setCurAnswer(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurAnswer(ans);
      }
      // if (gradeEnabled) {
      //   document.getElementById("sub-btn").style.display = e.target.value === "" ? "none" : "block";
      // }
    } else if (questionType === QUESTION_TYPES.OE.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("OE-IN").value = "";
        setCurAnswer(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurAnswer(ans);
      }
      // if (gradeEnabled) {
      //   document.getElementById("sub-btn").style.display = e.target.value === "" ? "none" : "block";
      // }
    }  else if (questionType === QUESTION_TYPES.FITB.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      }
      const copy = curAnswer === FIXED_ANSWER.UNANSWERED ? {} : { ...curAnswer };
      copy[e.target.getAttribute("id")] = e.target.value.toString();
      setCurAnswer(copy);
      // if (gradeEnabled) {
      //   document.getElementById("sub-btn").style.display = checkBlanks(copy) ? "none" : "block";
      // }
    } else if (questionType === QUESTION_TYPES.WC.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      } else {
        if (parseWCId(e.target.getAttribute("id")) == shownEntries-1 && shownEntries < entries && wcInputValRef.current.length < e.target.value.toString().length) {
          // const diff = entries - shownEntries;
          // if (diff === 1) {
            setShownEntries(shownEntries+1);
          // } else if (diff > 1) {
            // if (isNarrow) setShownEntries(shownEntries+1);
            // else setShownEntries(shownEntries+2-(shownEntries % 2));
          // }
        }
      }
      const copy = curAnswer === FIXED_ANSWER.UNANSWERED ? {} : { ...curAnswer };
      copy[parseWCId(e.target.getAttribute("id"))] = e.target.value.toString();
      // console.log("copy", copy);
      setCurAnswer(copy);
      // console.log("answered", checkBlankAnswered(copy));
      wcInputValRef.current = e.target.value;
    }
  };


  const onChangeCustomRank = (e, index) => {
    let w = e.target.value.replace(/[^0-9]/g, '');
    w = parseInt(w);
    if(!isNaN(w) && w <=  curAnswer.length && w > 0) {
      setNewRank({[index] : w});
    }
    else {
      setNewRank({[index] : ""});
    }
};

 const handleDelete = (i) => {
    const copy = { ...curAnswer };
    copy[i] = "";
    for (let j = i; j < entries-1; ++j) {
      copy[j] = copy[j+1];
    }
    copy[entries-1] = "";
    setCurAnswer(copy);
    if (shownEntries > 1) {
      setShownEntries(shownEntries-1);
    }
  };

  const handleChooseOption = (optIdx) => {
    if (isAnsweredState) {
      return;
    }
    if (!gradeEnabled && questionType === QUESTION_TYPES.MCSS.name) {
      const copy = JSON.parse(JSON.stringify(curAnswer));
      if (copy.includes(optIdx)) {
          copy.splice(copy.indexOf(optIdx), 1);
          setCurAnswer(copy);
      } else {
          copy.push(optIdx);
          setCurAnswer(copy);
      }
      document.getElementById("sub-btn").style.display = copy.length ? "block" : "none";
    } else {
      document.getElementById("sub-btn").style.display = "block";
      setCurAnswer(optIdx);
    }

  };

  const handleClickImage = (e) => {
    if (isAnsweredState || !isLoaded || !canInput) {
      return;
    }

    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    const encodedX = encodeCIParam(x, imageRef.current.width);
    const encodedY = encodeCIParam(y, imageRef.current.height);
    const option = getOption4CI(encodedX, encodedY, optionsMap);
    setCurAnswer(option === FIXED_ANSWER.UNANSWERED
        ? FIXED_ANSWER.UNANSWERED
        : {x: encodedX, y: encodedY, selected: option});
    document.getElementById("sub-btn").style.display = "block";
  }

  // const handleMultipleClick = (e) => {
  //   if (!isLoaded || !canInput) {
  //     return;
  //   }

  //   const x = e.nativeEvent.offsetX;
  //   const y = e.nativeEvent.offsetY;

  //   const encodedX = encodeCIParam(x, imageRef.current.width);
  //   const encodedY = encodeCIParam(y, imageRef.current.height);
  //   const option = getOption4CI(encodedX, encodedY, optionsMap);
  //   let curAnswerCopy = JSON.parse(JSON.stringify(curAnswer));

  //   curAnswerCopy.push(option === FIXED_ANSWER.UNANSWERED
  //       ? FIXED_ANSWER.UNANSWERED
  //       : {x: encodedX, y: encodedY, selected: option});

  //   if (curAnswerCopy.includes(FIXED_ANSWER.UNANSWERED)) {
  //     curAnswerCopy = curAnswerCopy.filter(answer => answer !== FIXED_ANSWER.UNANSWERED);
  //   }
  //   setCurAnswer(curAnswerCopy);
  //   document.getElementById("sub-btn").style.display = "block";
  // }

  const checkBlanks = (opts) => { // returns true if all blanks are empty
    for (const key of Object.keys(correctAnswers)) {
      if (!!opts && !!opts[key] && opts[key] !== "") {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (questionType === QUESTION_TYPES.WC.name) {
      let tempWCElements = [];
      for (let i = 0; i < shownEntries; i++) {
        tempWCElements.push(`WC-IN-${i}`);
      }
      setWCElements(tempWCElements);
    }
  }, [shownEntries]);


  useEffect(() => {
    if (!!questionResponse && !!Object.entries(questionResponse).length) {
      return;
    }
    const liveQuestion = JSON.parse(JSON.stringify(question));
    const answer = {
      serialNo: liveQuestion.serialNo,
      questionType: liveQuestion.questionType,
      queTitle: liveQuestion.queTitle,
      optionsMap: liveQuestion.optionsMap,
      isCorrect: false,
      attemptsCount: 1,
      selectedOption: FIXED_ANSWER.UNANSWERED,
      correctAnswers: liveQuestion.correctAnswers,
    };

    handleSubmitIndividualResponse(pollInfo, gradeEnabled, answer, true).then();

  }, []);

  useEffect(() => {
    if(stopped) {
      timeUp();
    }
  }, [stopped]);

  useEffect(() => {
    if (timed && isTimeOut) {
      timeUp();
    }
  }, [timed, isTimeOut]);

  const timeUp = async () => {
    disableInput();

    if (isAnsweredState === false) {
      await handleSub();
    }

    // handleMCTFHighlight();
    //delRc();
    setTimeout(() => {
      if (questionType === QUESTION_TYPES.SA.name && gradeEnabled) {
        handleSAQuestionComplete();
      } else {
        handleQuestionComplete();
      }
    }, 5000);
  };

  const alertConfig = {
    title: CONFIRM_UNANSWERED_SUBMIT_TITLE,
    okText: SUBMIT_TEXT,
    submit: () => {
      setAlertShow(false);
      handleSub();
    },
    close:  () => setAlertShow(false),
  };

  const handleClickSub = () => {
    if (!weightage && !isAnsweredState &&
      ((questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.WC.name) ?
        checkBlankAnswered(curAnswer) : checkUnanswered(!!weightage, questionType, curAnswer))
    ) {
      setAlertShow(true);
    } else {
      setClickedSub(true);
      handleSub();
    }
  };

  const handleSub = async () => {
    let copy = JSON.parse(JSON.stringify(curAnswer));
    if (checkUnansweredNew(!!weightage, questionType, copy)) {
      copy = FIXED_ANSWER.UNANSWERED;
    }

    const isCorr = handleUserAns(question, copy);
    setIsAnsweredState(true);
    await setIsResponseCorrect(isCorr);
    await handleSubmitResponse(copy, isCorr);
    const curQueCopy = JSON.parse(JSON.stringify(currentQuestion));
    curQueCopy.questionResponse = { selectedOption: copy, isCorrect: isCorr };
    changeCurQue(curQueCopy);
    // handleMCTFHighlight();
  };

  const handleSubmitResponse = async (ansIn, isCorr) => {
    const liveQuestion = question;
    const answer = {
      serialNo: liveQuestion.serialNo,
      questionType: liveQuestion.questionType,
      queTitle: liveQuestion.queTitle,
      optionsMap: liveQuestion.optionsMap,
      isCorrect: isCorr,
      attemptsCount: 1,
      selectedOption: ansIn,
      correctAnswers: liveQuestion.correctAnswers,
    };

    await handleSubmitIndividualResponse(pollInfo, gradeEnabled, answer);
  };

  const handleReaction = async (reaction) => {
    if (reaction == 1) {
      if(thumbdown == true) {
        setThumbDown(false);
      }
      setThumbUp(true);

    }
    else {
      if(thumbup == true) {
        setThumbUp(false);
      }
      setThumbDown(true);
    }

    const reactionMap = {};
    let allShareArray = JSON.parse(allSharecount.replace(/null/g, "null"));
    reactionMap[allShareArray[serialNo - 1]] = [reaction];
    const questionReactionMap = {};
    questionReactionMap[serialNo] = reactionMap;
    await updateReaction(pollKey, uniqueCode, questionReactionMap, null , serialNo);
  }

  useEffect(async () => {
    let res = await getAudienceResponseByUser(userName, uniqueCode);
    if(!res.hasOwnProperty("data")) {
      res.data = [];
    }
    let currentPollRes = res.data;
    let isSet = false;
    console.log(currentPollRes);
    let allShareArray = JSON.parse(allSharecount.replace(/null/g, "null"));
    for (const tempResult of currentPollRes) {
      if (tempResult.reactionMap && tempResult.reactionMap[serialNo]) {
        if (tempResult.reactionMap[serialNo][allShareArray[serialNo - 1]][0] == "1") {
          setThumbUp(true);
          setThumbDown(false);
          isSet= true;
        } else if (tempResult.reactionMap[serialNo][allShareArray[serialNo - 1]][0] == "2") {
          setThumbDown(true);
          setThumbUp(false);
          isSet= true;
        }
      }  else if (tempResult.reaction && tempResult.reaction[serialNo]) {
        if (tempResult.reaction[serialNo][0] == "1") {
          setThumbUp(true);
          setThumbDown(false);
          isSet= true;
        } else if (tempResult.reaction[serialNo][0] == "2") {
          setThumbDown(true);
          setThumbUp(false);
          isSet= true;
        }
      }
    }
    if (!isSet) {
      setThumbUp(false);
      setThumbDown(false);
    }
  }, [allSharecount]);


  const onLoad = () => {
    setIsLoaded(true);
  }

  // const handleMouseEnterThumbUp = (reaction) => {
  //   if (reaction === 1) {
  //     setHoveringThumbUp(true);
  //   }
  //   else if (reaction === 2) {
  //     setHoveringThumbDown(true)
  //   }
  // };

  // const handleMouseLeaveThumbUp = (reaction) => {
  //   if (reaction === 1) {
  //     setHoveringThumbUp(false);
  //   }
  //   else if (reaction === 2) {
  //     setHoveringThumbDown(false)
  //   }
  // };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceKey = result.source.index;
    const destinationKey = result.destination.index;

    let newSelectedOption = [ ...curAnswer ];
    const [removed] = newSelectedOption.splice(sourceKey, 1);
    newSelectedOption.splice(destinationKey, 0, removed);
    setCurAnswer(newSelectedOption);
  };

  // const onMouseEnter = (idx) => {
  //   document.getElementById(`coordinate-close${idx}`).style.display = "block";
  // }

  // const onMouseLeave = (idx) => {
  //   document.getElementById(`coordinate-close${idx}`).style.display = "none";
  // }

  // const onDelete = (idx) => {
  //   const curAnswerCopy = JSON.parse(JSON.stringify(curAnswer));
  //   curAnswerCopy.splice(idx, 1);
  //   if (!curAnswerCopy.length) {
  //     document.getElementById("sub-btn").style.display = "none";
  //   }
  //   setCurAnswer(curAnswerCopy);
  // }

  return (
      <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
        <YuJaGeneralAlert
            show={alertShow}
            setModalShow={setAlertShow}
            content={CONFIRM_UNANSWERED_SUBMIT_CONTENT}
            config={alertConfig}
        />

        <div
            id="everything"
            className={`${styles.centerLayout} ${styles.pollShareAllLayOut}`}
            style={{flex: 1, overflowY: "auto", flexDirection: "column", padding: 20, gap: 10}}
        >

          {/* <NormalBox
        boxType="lightBlue"
        className={`${styles.shadow} ${styles.centerLayout} ${styles.pollShareAllLayOut}`}
      > */}
          {/* {isNarrow &&
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: '87.5%'}}>
              {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                <CountdownTimer
                    duration={timeLimit}
                    questionEndTime={questionEndTime}
                    setIsTimeOut={setIsTimeOut}
                    state={state}
                />
              }
              <NetworkStatus tooltipPlacement={'bottom'} websocketState={websocketState}/>
            </div>
        } */}
          {!isMobile && window.innerWidth >= 1200 && <>
            <NormalBox boxType="light" className={`${styles.containerTop}`} style={{padding: "0px 3vw 0px 3vw"}}>
              <div id="header" className="d-flex justify-content-between" style={{height: "90px"}}>
                <div tabIndex={0} aria-label={POLL_TITLE_LABEL + pollTitle} role={TEXTBOX} className="poll-title">{pollTitle}</div>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20}}>
                  <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState}/>
                  {timed &&
                    <CountdownTimer
                      duration={timeLimit}
                      questionEndTime={questionEndTime}
                      setIsTimeOut={setIsTimeOut}
                      state={state}
                    />
                  }
                </div>
              </div>
            </NormalBox>
          </>}

          <div
              className={`${styles.containerBottom}`} style={{display: "flex" ,
            flexDirection: "column", maxHeight: isMobile ? "calc(100vh - 75px)" : "", padding: "0px"}}
          >
            {(isMobile || window.innerWidth < 1200) &&
                <>
                  {/* <div style={{ position: "fixed", top: "20px", left: isMobile ? "calc((100% - 120px)/2)" : "calc((100% - 170px)/2)" }}>
                    {pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                      <CountdownTimer
                        duration={timeLimit}
                        questionEndTime={questionEndTime}
                        setIsTimeOut={setIsTimeOut}
                        state={state}
                      />
                    }
                  </div> */}
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20, padding: "0px 10px 0px 10px" }}>
                    <div className="poll-title" style={{ maxWidth: "100%", flex: 1 }}>{pollTitle}</div>
                    <NetworkStatus tooltipPlacement={'bottom'} refreshAlignFirst={true} websocketState={websocketState} />
                  </div>
                  <div style={{ padding: "0px 10px 0px 10px" }}> <hr /> </div>
                </>
            }
            <div id="question-container" style={{display: "flex", flexDirection: "column",  height: "100%", flex: 1, padding: isNarrow ? "0px 10px 5px 10px" : "0px 21px 5px 21px"}}>
              {!isMobile && window.innerWidth >= 1200 ?
                <div className="header-board" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                  <div tabIndex={0} role={TEXTBOX} className="ques-name">Question {serialNo} of {totalQuestion}</div>
                  {questionType !== QUESTION_TYPES.FITB.name && (
                      <>
                        <ArrowRight style={{flexShrink: "0"}}/>
                        <p tabIndex={0} aria-label={QUESTION_TITLE + queTitle} className="ques-desc" style={{ fontSize: "24px", letterSpacing: "0.84px", lineHeight: "normal", color: "#000", order: "unset", marginTop: "0px" , flexShrink: "1"}}>
                          {queTitle}
                        </p>
                      </>
                  )}
                  <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1"}}>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                      <div
                          className={`reaction-container ${thumbup ? "reaction-container-activated" : ""}`}
                          onClick={() => { handleReaction(1) }}
                          tabIndex={0}
                          aria-label={THUMBS_UP}
                          role={BUTTON}
                      >
                        <THUMB_UP style={{ width: 24, height: 24 }} />
                      </div>
                      <div
                          className={`reaction-container ${thumbdown ? "reaction-container-activated" : ""}`}
                          onClick={() => { handleReaction(2) }}
                          tabIndex={0}
                          aria-label={THUMBS_DOWN}
                          role={BUTTON}
                      >
                        <THUMB_DOWN style={{ width: 24, height: 24 }} />
                      </div>
                    </div>

                    <span className="gradientWrapQuesType" style={{ height: "45px", width: "195px" }}>
                      <span className="questionPanelQuestionTypeContainer" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div tabIndex={0} role={TEXTBOX} className="questionPanelQuestionType" style={{ fontSize: "18px", lineHeight: "normal" }}>
                          {gradeEnabled ? `${weightage} ${POINTS_TEXT}` : UNGRADED_TEXT}
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
                :
                <>
                  <div className="header-board" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div tabIndex={0} role={TEXTBOX} className="ques-name">Question {serialNo} of {totalQuestion}</div>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", flex: "1", alignItems: "center"}}>
                      <div style={{ display: "flex", gap: "10px",  }}>
                        <div
                            className={`reaction-container ${thumbup ? "reaction-container-activated" : ""}`}
                            style={{ width: 36, height: 36 }}
                            onClick={() => { handleReaction(1) }}
                            tabIndex={0}
                            aria-label={THUMBS_UP}
                            role={BUTTON}
                        >
                          <THUMB_UP style={{ width: 18, height: 18 }} />
                        </div>
                        <div
                            className={`reaction-container ${thumbdown ? "reaction-container-activated" : ""}`}
                            style={{ width: 36, height: 36 }}
                            onClick={() => { handleReaction(2) }}
                            tabIndex={0}
                            aria-label={THUMBS_DOWN}
                            role={BUTTON}
                        >
                          <THUMB_DOWN style={{ width: 18, height: 18 }} />
                        </div>
                      </div>
                      {gradeEnabled &&
                          <span className="gradientWrapQuesType" style={{ height: "23px", width: "75px", marginLeft: "0px" }}>
                          <span className="questionPanelQuestionTypeContainer" style={{ width: "71px", padding: "0px", height: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className="questionPanelQuestionType" role={TEXTBOX} style={{ fontSize: "12px", lineHeight: "normal", display: "flex", alignItems: "center", justifyContent: "center", height: "12px" }}> {weightage} Points </div>
                          </span>
                        </span>
                      }
                    </div>

                  </div>
                  {questionType !== QUESTION_TYPES.FITB.name && (questionType !== QUESTION_TYPES.OE.name || !openEndedExpanded) && (
                      <p tabIndex={0} aria-label={QUESTION_TITLE + queTitle} className="ques-desc" style={{order: "initial", letterSpacing: "0px"}}>
                        {queTitle}
                      </p>
                  )}
                </>
              }
              {/* {isNarrow && <div className="header-board-question">
            <div className="ques-name">Question {serialNo} {pollType === POLL_TYPE.SURVEY ? <></> : <p className="ques-pts"> - {weightage} points</p>}</div>
            <div className="ques-name">Total Shared: {quesShareCount}</div>
          </div>} */}


              {(!!question.directLinkEvp || !!question.imageURL) && !!isMobile &&(
                  <div className="img-container-question" style={{alignSelf: "center", margin: "0px", marginTop: "30px", width: questionType === QUESTION_TYPES.CI.name ? "100%" : ""}}>
                    {questionType !== QUESTION_TYPES.CI.name &&  (questionType !== QUESTION_TYPES.OE.name || !openEndedExpanded) && <>
                      {!isVideo && <img className="img" tabIndex={0} aria-label={imageAlt ? imageAlt : DEFAULT_IMAGE_ALT} src={question.imageURL} alt="Question"/>}
                      {isVideo && <iframe allowFullScreen src={question.directLinkEvp} alt="Question"/>}
                    </>}
                    {questionType === QUESTION_TYPES.CI.name &&
                        <div style={{position: "relative"}}>
                          <img
                              className="img"
                              ref={imageRef}
                              src={question.imageURL}
                              onLoad={onLoad}
                              onClick={handleClickImage}
                              alt="Question"/>
                          {isLoaded
                              && !!curAnswer
                              && curAnswer.hasOwnProperty("x")
                              && curAnswer.hasOwnProperty("y")
                              &&
                              <COORDINATE
                                  style={{
                                    position: "absolute",
                                    top: `${decodeCIParam(curAnswer.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                    left: `${decodeCIParam(curAnswer.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                  }}
                              />
                          }
                        </div>
                    }
                  </div>
              )}
              <div className="main-body">
                <div style={{display: "flex", gap: "70px"}}>

                  {/* Create the options for MCSS */}
                  {questionType === QUESTION_TYPES.MCSS.name && (
                      <div id="body-MCSS" style={{display: "flex", flexDirection: "column", gap: isMobile ? "17px" :"30px", flex: "1", marginTop: isMobile ? "20px" : "", width: "100%"}}>
                        {MCSSelements.map((value, index) => {
                          const optIdx = String.fromCharCode(ASCII_CODE_SMALL_A + index);
                          const optIdxDisplay = String.fromCharCode(ASCII_CODE_A + index);
                          const isSelectedOpt = (!!curAnswer && optIdx === curAnswer)
                              || (Array.isArray(curAnswer) && curAnswer.includes(MCSSoptLower[index]));
                          return (
                              <div>
                                {!isMobile && index === 0 && <div style={{ display: "block", height: "30px" }} />}
                                <div
                                    className={`MCSS-label ${isSelectedOpt ? "selected" : ""} ${isAnsweredState ? "disabled" : ""}`}
                                    tabIndex={0}
                                    style={{
                                      width: "100%",
                                      minHeight: isMobile ? 50 : "",
                                      borderRadius: isMobile ? "4px" : "",
                                      border: isMobile ? "1.5px solid #909090" : "",
                                      gap: isMobile ? 10 : 20,
                                      paddingLeft: isMobile ? 20 : 30,
                                      paddingRight: isMobile ? 20 : 30,
                                    }}
                                    onClick={() => handleChooseOption(optIdx)}
                                >
                                  <div
                                      className="MCSS-letter-container"
                                      style={{
                                        border: isSelectedOpt ? "1.5px solid #FFF" : isMobile ? "2px solid #000" : "2.5px solid #000"
                                      }}
                                  >
                                    <span
                                        className="MCSS-letter"
                                        id={"MCSSLetter" + index}
                                        style={{
                                          color: isSelectedOpt ? "#42296E" : "#000"
                                        }}
                                    >
                                      {optIdxDisplay}
                                    </span>
                                  </div>
                                  <OptionImageViewer optionsImageMap={optionsImageMap} optIdx={optIdx} isSelected={isSelectedOpt}/>
                                  <p
                                      className="MCSS-option"
                                      id={index + value}
                                      style={{
                                        color: isSelectedOpt ? "#FFF" : "#000",
                                        fontSize: isMobile ? 18 : ""
                                      }}
                                  >
                                    {optionsMap[optIdx]}
                                  </p>
                                </div>
                              </div>
                          );
                        })}
                      </div>
                  )}

                  {/* Create the TF options */}
                  {questionType === QUESTION_TYPES.TF.name && (
                      <div id="body-TF" className= "TF-option" style={{flex: "1", display: "flex", flexDirection: "column", gap: 30}}>
                        {TFelements.map((value, index) => {
                          return (
                              // <YuJaRadioBox
                              //     size={32}
                              //     disabled={isAnsweredState}
                              //     id={value}
                              //     value={TFopt[index]}
                              //     name="TF"
                              //     checked={o
                              //         !!curAnswer &&
                              //         TFopt[index] === curAnswer
                              //     }
                              //     onClick={() => {handleChooseOption(TFopt[index])}}
                              //     labelStyle={{margin: "0px 0px 0px 16px"}}
                              //     label={TFopt[index]}
                              //     style={{fontSize: isMobile ? 18 : 24, marginTop: 30}}
                              // />
                              <div>
                                {!isMobile && index === 0 && <div style={{ display: "block", height: "30px" }} />}
                                <div
                                    className={`MCSS-label ${!!curAnswer && TFopt[index] === curAnswer ? "selected" : ""} ${isAnsweredState ? "disabled" : ""}`}
                                    id={value}
                                    tabIndex={0}
                                    style={{
                                      width: "100%",
                                      minHeight: isMobile ? 50 : "",
                                      borderRadius: isMobile ? "4px" : "",
                                      border: isMobile ? "1.5px solid #909090" : "",
                                      gap: isMobile ? 10 : 20,
                                      paddingLeft: isMobile ? 20 : 30,
                                      paddingRight: isMobile ? 20 : 30,
                                    }}
                                    onClick={() => {handleChooseOption(TFopt[index])}}
                                >
                                  <p
                                      className="MCSS-option"
                                      id={index + value}
                                      style={{
                                        color: !!curAnswer && TFopt[index] === curAnswer ? "#FFF" : "#000",
                                        fontSize: isMobile ? 18 : ""
                                      }}
                                  >
                                    {TFopt[index]}
                                  </p>
                                </div>
                              </div>
                          );
                        })}
                      </div>
                  )}

                  <DragDropContext onDragEnd={handleDragEnd}>
                    {questionType === QUESTION_TYPES.RK.name && (
                        <div style={{ display: "flex", flexDirection: "column", width: "auto", gap: "20px", paddingTop: "20px" }}>
                          <Droppable droppableId="droppable-selected-options">
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    {...provided.dropHandleProps} style={{display: "flex", flexDirection: "column", gap: "20px", marginTop: "10px"}}>
                                  {curAnswer.map((value, index) => (
                                      <Draggable draggableId={"selected option" + index} key={"selected option" + index} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                              {value !== "" && (
                                                  <div
                                                      style={{display: "flex", flex: 1, alignItems: "center", paddingTop: "5px", gap: "10px"}}
                                                  >
                                                    <img src={DragDropIcon} alt="drag drop icon" width={15} height={25}/>
                                                    <div style={{fontSize: "20px", fontWeight: 700}}>{index + 1 }</div>
                                                    <div
                                                        style={{ borderRadius: "3px", flexGrow: "1", padding: "12.5px 12.5px 12.5px 5px", border: "1.13px solid #858687", minWidth: "50vw", boxShadow: "0 0 0 1px #B9B9B9", backgroundColor: "#FFFFFF"}}>
                                                      <div>
                                                        <p className="MCSS-option" id={index + value} style={{display: "flex", paddingLeft: isMobile ? "20px" : "10px", fontSize: isMobile ? "18px" : "", paddingRight: "5px", height: "fit-content"}}>
                                                          <div style={{display: "flex", width: "100%"}}> {value}   <div style={{flex: 1, display: "flex", justifyContent: "end"}}> <input
                                                              className="custom-rank-setting"
                                                              aria-label={"type custom rank"}
                                                              tabIndex={0}
                                                              type="number"
                                                              min="1"
                                                              max={curAnswer.length}
                                                              placeholder="00"
                                                              value={newRank === "" || !newRank.hasOwnProperty(index) ? index + 1 : newRank[index]}
                                                              onChange={(e) => onChangeCustomRank(e, index)}
                                                              onBlur={()=> { if(newRank && newRank !== "" && newRank[index] !== "") {let newSelectedOption = [ ...curAnswer ];
                                                                newSelectedOption.splice(newRank[index] - 1, 0, newSelectedOption.splice(index, 1)[0]);
                                                                setCurAnswer(newSelectedOption); setNewRank("")} else {setNewRank("")}}}
                                                              style={{display: "flex", justifyContent: "center", paddingLeft: "10px",  width: "60px", height: "26px", borderRadius: "2px"}}
                                                          /> </div></div>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                              )}
                                            </div>
                                        )}
                                      </Draggable>
                                  ))}
                                </div>
                            )}
                          </Droppable>
                        </div>
                    )}
                  </DragDropContext>

                  {/* Create the input box for SA */}
                  {questionType === QUESTION_TYPES.SA.name && (
                      <div id="body-SA" className="sa-center" style={{flex: "1"}}>
                  <textarea
                      disabled={isAnsweredState}
                      className="sa-ans"
                      id="SA-IN"
                      name="sa-in"
                      placeholder={SA_PLACE_HOLDER}
                      value={
                        !curAnswer ||
                        curAnswer === FIXED_ANSWER.UNANSWERED
                            ? ""
                            : curAnswer["0"]
                      }
                      onChange={handleType}
                  />
                      </div>
                  )}

                {questionType === QUESTION_TYPES.OE.name && (
                  <div id="body-SA" className="sa-center" style={{ flex: "1", width: '100%', display: "flex", flexDirection: "column", gap: isMobile ? "15px" : "25px", height: "100%" }}>
                    {!openEndedExpanded &&
                      <>
                        <textarea
                          disabled={isAnsweredState}
                          className="oe-ans"
                          id="OE-IN"
                          name="oe-in"
                          placeholder={OE_PLACE_HOLDER}
                          value={
                            !curAnswer ||
                              curAnswer === FIXED_ANSWER.UNANSWERED
                              ? ""
                              : curAnswer["0"]
                          }
                          onChange={handleType}
                        />
                        {(!isAnsweredState) &&
                          <>
                            <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                              <YuJaButton id="sub-btn" onClick={handleClickSub} wrapperStyle={{ height: isMobile ? "100%" : "" }} style={{ fontSize: isMobile ? "15px" : "15px", padding: isMobile ? "auto" : "10px 15px", height: isMobile ? "36px" : "", display: "flex", alignItems: "center", color: isMobile ? "#FFF" : "", backgroundColor: isMobile ? "#42296E" : "", width: isMobile ? "70px" : "75px" }}>
                                {SUBMIT_TEXT}
                              </YuJaButton>
                            </div>
                          </>
                        }
                      </>
                    }
                    <div style={{ width: "100%", height: "100%" }}> <OpenEndedResult pollKey={pollKey} uniqueCode={uniqueCode} question={question} queTitle={queTitle} openEndedExpanded={openEndedExpanded} setOpenEndedExpanded={setOpenEndedExpanded} /> </div>
                  </div>
                )}

                  {/* Create the input box for FITB */}
                  {questionType === QUESTION_TYPES.FITB.name && (
                      <div
                          id="body-FITB"
                          style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "5px", marginTop: "30px" , flex: "1"}}
                      >
                        {/* {reactStringReplace(queTitle, /(\(.*?\))/g, (m, i, o) => {
                     if (m !== "()") {
                    idx += 1;
                    return (
                      <YuJaTextBox
                        disabled={isAnsweredState}
                        key={`FITB${i}`}
                        id={Object.values(FITBElements)[idx]}
                        containerStyle={{width: 150}}
                        onChange={handleType}
                        maxLength={FITB_ANSWER_LENGTH}
                        value={
                          !curAnswer ||
                            curAnswer === FIXED_ANSWER.UNANSWERED ||
                            !curAnswer.hasOwnProperty(FITBElements[idx])
                            ? ""
                            : curAnswer[FITBElements[idx]]
                        }
                      />
                    );
                  }
                  else {
                    return ("()");
                  }
                  })} */}

                        {
                          queTitle.split(blankWrapperRegex).map((str, i) => {
                            let re = new RegExp(blankRegex);
                            if (!re.test(str)) {
                              const oneLine = str.replace(/[\r\n]/g, " @newLine ");
                              const wordArr = oneLine.split(" ");
                              return (
                                  <>
                                    {wordArr.map((s, idx) => (<>
                                      {s === '@newLine' &&
                                          <div key={idx} style={{width: "100%"}}></div>
                                      }
                                      {!!s && s !== '@newLine' &&
                                          <div key={idx}>{s}</div>
                                      }
                                    </>))}
                                  </>
                              );
                            } else {
                              idx += 1;
                              return (
                                  <YuJaTextBox
                                      disabled={isAnsweredState}
                                      key={`FITB${i}`}
                                      id={Object.values(FITBElements)[idx]}
                                      containerStyle={{ width: 150 }}
                                      onChange={handleType}
                                      maxLength={FITB_ANSWER_LENGTH}
                                      value={
                                        !curAnswer ||
                                        curAnswer === FIXED_ANSWER.UNANSWERED ||
                                        !curAnswer.hasOwnProperty(FITBElements[idx])
                                            ? ""
                                            : curAnswer[FITBElements[idx]]
                                      }
                                  />
                              );
                            } } )}
                      </div>
                  )}
                  {questionType === QUESTION_TYPES.MH.name && (<>
                    <div id="body-MCSS" style={{ flex: "1", maxWidth: "100%", overflow: "auto" }}>
                      <div>
                        <div style={{ display: "flex", marginTop: "30px", width: "100%"}}>
                          <div style={{width: "100%"}}>
                            {correctAnswers.map((element, index) => (
                                <div key={element[0]} id={"Option" + index} className="questionPanelOptionsContainer" style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "0px", display: "flex", alignItems: "center" }}>
                                  {element.length > 1 ?
                                      <div style={{ display: "flex", flex: 1,  width: "100%", borderTop: isNarrow && index ===0 ? "none" : ""}} className={isNarrow ? styles.MatchingNarrow : ""}>
                                        {isNarrow && <div style={{fontWeight: "700", fontSize: "45", marginTop:"10px", marginBottom: "10px"}}> {PREMISE} </div>}
                                        <div
                                            tabIndex={0}
                                            role={TEXTBOX}
                                            className="questionPanelAnswersText matchingPremise"
                                            id={"OptionPart1" + index}
                                            style={{
                                              marginRight: "0px",
                                              border: "1px solid #909090",
                                              padding: "10px",
                                              borderRadius: "10px",
                                              backgroundColor: "#F5F5F5",
                                              minWidth: !isNarrow ? "250px": "",
                                              height: "fit-content",
                                              flex: 1,
                                              wordBreak: "keep-all"
                                            }}
                                        >{element[0]}</div>
                                        <img
                                            src={ArrowIcon}
                                            alt="Arrow icon"
                                            className="questions-page-icons cursor-pointer"
                                            style={{ width: "48px", margin: "0 20px", height: "fit-content", display: isNarrow ? "none" : "block"}}
                                        />
                                        <div style={{minWidth: "50%", position: "relative", flex: "1"}} onBlur={() => {if(isDropdownOpen != null) {setIsDropdownOpen(null)}}}>
                                          {isNarrow && <div style={{fontWeight: "700", fontSize: "45", marginTop:"10px", marginBottom: "10px"}}> {RESPONSE} </div>}
                                          <YuJaDropdown
                                              data={optionsMap}
                                              getOptionLabel={option => option.length > 1 ? option[1]: option[0]}
                                              getOptionValue={option => option.length > 1 ? option[1]: option[0]}
                                              value={curAnswer[index]}
                                              disabled={isAnsweredState}
                                              itemStyle={{borderWidth: "2.5px", borderColor: "#909090" }}
                                              placeholder={"Select an option"}
                                              textStyle={{wordBreak: "break-word"}}
                                              onChange={(option) => {
                                                let newAnswer = {[index]: option.length > 1 ? option[1]: option[0]};
                                                if(curAnswer !==  FIXED_ANSWER.UNANSWERED) {
                                                  newAnswer = { ...curAnswer, [index]: option.length > 1 ? option[1]: option[0] };
                                                }
                                                setCurAnswer(newAnswer);
                                                document.getElementById("sub-btn").style.display = "block";
                                                setIsDropdownOpen(false);
                                              }}
                                              style={{fontSize: 18, padding: 10, borderRadius: "10px",
                                                border: "2.5px solid var(--color-guide-primary-color, #42296E)"}}
                                              arrowSize={"24px"}
                                          />
                                        </div>
                                      </div> : <></>}
                                </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </> ) }
                  {/* Create the input boxes for WC */}
                  {questionType === QUESTION_TYPES.WC.name &&
                      <>
                        {WCElements.length > 1 &&
                            <div id="body-WC" className={isNarrow ? "wc-center-narrow" : "wc-center"} style={{flex: "1", height: "fit-content"}}>
                              {Array(shownEntries).fill(null).map((id, i) => {
                                return (
                                    <div className="wc-ans-container" key={i} style={{width: isNarrow ? "100%" : ""}}>
                                      <YuJaTextBox
                                          disabled={isAnsweredState}
                                          id={`WC-IN-${i}`}
                                          name="wc-in"
                                          placeholder={i === 0 ? FIRST_WC_PLACEHOLDER : OTHER_WC_PLACEHOLDER}
                                          maxLength={WC_ANSWER_LENGTH}
                                          style={{paddingRight: "20px", boxSizing: "border-box"}}
                                          value={checkBlankAnswered(curAnswer) ? "" : curAnswer[i]}
                                          onChange={(e) => {
                                            const copy = curAnswer === FIXED_ANSWER.UNANSWERED ? {} : {...curAnswer}
                                            copy[i] = e.target.value;
                                            setCurAnswer(copy);
                                            wcInputValRef.current = e.target.value;
                                            if (i === shownEntries - 1 && shownEntries < entries && e.target.value.length) {
                                              setShownEntries(shownEntries + 1);
                                            }
                                          }}
                                          containerStyle={{width: isMobile ? "75%": "100%", border: "2.5px solid #D0D0D0", borderRadius: "10px"}}
                                      />
                                      <div className="wc-char-count">
                                        {WC_ANSWER_LENGTH - (curAnswer[i] ? curAnswer[i].length : 0)}
                                      </div>

                                      <img
                                          src={deleteIcon}
                                          className="delete-entry-icon"
                                          tabIndex={0}
                                          role={BUTTON}
                                          alt="Delete Answer"
                                          onClick={() => handleDelete(i)}
                                          style={{ width: "25px", height: "25px", marginLeft: "15px" }}
                                      />

                                    </div>
                                );
                              })}
                            </div>
                        }

                        {WCElements.length === 1 &&
                            <div id="body-WC-odd" className="wc-center-odd"  style={{width: isMobile ? "100%" : ""}}>
                              <div className="wc-ans-container"  style={{width: isMobile ? "100%" : ""}}>
                                <YuJaTextBox
                                    containerStyle={{width: isMobile ? "75%": "40vw", border: "2.5px solid #D0D0D0", borderRadius: "10px"}}
                                    disabled={isAnsweredState}
                                    id={WCElements[WCElements.length - 1]}
                                    name="wc-in"
                                    placeholder={WCElements.length === 1 ? FIRST_WC_PLACEHOLDER : OTHER_WC_PLACEHOLDER}
                                    maxLength={WC_ANSWER_LENGTH}
                                    style={{paddingRight: "20px", boxSizing: "border-box"}}
                                    value={checkBlankAnswered(curAnswer) ? "" : curAnswer[0]}
                                    onChange={(e) => {
                                      const copy = curAnswer === FIXED_ANSWER.UNANSWERED ? {} : {...curAnswer}
                                      copy[0] = e.target.value;
                                      setCurAnswer(copy);
                                      wcInputValRef.current = e.target.value;
                                    }}
                                />
                                <div className="wc-char-count">
                                  {WC_ANSWER_LENGTH - (curAnswer[0] ? curAnswer[0].length : 0)}
                                </div>
                                <img
                                    src={deleteIcon}
                                    className="delete-entry-icon"
                                    role={BUTTON}
                                    tabIndex={0}
                                    alt="Delete Answer"
                                    onClick={() => handleDelete(0)}
                                    style={{width: "25px", height: "25px", marginLeft: "15px"}}
                                />
                              </div>
                            </div>
                        }
                      </>
                  }
                  {(!!question.directLinkEvp || !!question.imageURL) && !isMobile &&(
                      <div className="img-container-question" style={{alignSelf: "center", margin: "0px", marginTop: "30px", width: questionType === QUESTION_TYPES.CI.name ? "100%" : ""}}>
                        {questionType !== QUESTION_TYPES.CI.name && <>
                          {!isVideo && <img className="img-question" tabIndex={0} aria-label={imageAlt ? imageAlt : DEFAULT_IMAGE_ALT} src={question.imageURL} alt="Question"/>}
                          {isVideo && <iframe allowFullScreen className="img-question" src={question.directLinkEvp}/>}
                        </>}
                        {questionType === QUESTION_TYPES.CI.name &&
                            <div style={{position: "relative"}}>
                              <img
                                  className="img"
                                  ref={imageRef}
                                  src={question.imageURL}
                                  onLoad={onLoad}
                                  onClick={handleClickImage}
                                  alt="Question"
                                  tabIndex={0} />
                              {isLoaded
                                  && !!curAnswer
                                  && curAnswer.hasOwnProperty("x")
                                  && curAnswer.hasOwnProperty("y")
                                  &&
                                  <COORDINATE
                                      style={{
                                        position: "absolute",
                                        top: `${decodeCIParam(curAnswer.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                        left: `${decodeCIParam(curAnswer.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                      }}
                                  />
                              }
                            </div>
                        }
                        {/*multiple click image*/}
                        {/*{questionType === QUESTION_TYPES.CI.name && pollType === POLL_TYPE.SURVEY  &&*/}
                        {/*    <div style={{position: "relative"}}>*/}
                        {/*      <img*/}
                        {/*          className="img"*/}
                        {/*          ref={imageRef}*/}
                        {/*          src={questionImageURL}*/}
                        {/*          onLoad={onLoad}*/}
                        {/*          onClick={handleMultipleClick}*/}
                        {/*          alt="Question"/>*/}
                        {/*      {isLoaded &&*/}
                        {/*          curAnswer.filter(point => point !== FIXED_ANSWER.UNANSWERED).map((point, idx) => (*/}
                        {/*              <div*/}
                        {/*                  onMouseEnter={() => onMouseEnter(idx)}*/}
                        {/*                  onMouseLeave={() => onMouseLeave(idx)}*/}
                        {/*                  key={idx}*/}
                        {/*                  style={{*/}
                        {/*                    position: "absolute",*/}
                        {/*                    display: "flex",*/}
                        {/*                    padding: "0.5px",*/}
                        {/*                    top: `${decodeCIParam(point.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,*/}
                        {/*                    left: `${decodeCIParam(point.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`*/}
                        {/*                  }}*/}
                        {/*              >*/}
                        {/*                <COORDINATE/>*/}
                        {/*                <RiCloseCircleFill*/}
                        {/*                    onClick={() => onDelete(idx)}*/}
                        {/*                    className={"coordinate-close"}*/}
                        {/*                    id={`coordinate-close${idx}`}*/}
                        {/*                    style={{height: "15px", width: "15px"}}*/}
                        {/*                />*/}
                        {/*              </div>*/}

                        {/*          ))*/}
                        {/*      }*/}
                        {/*    </div>*/}
                        {/*}*/}
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* </NormalBox> */}
        </div>

        <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end",
              padding: 20, paddingBottom: 80,
              backgroundColor: "#FCFBFF",
          }}
        >

          {(!isAnsweredState) && questionType !== QUESTION_TYPES.OE.name &&
              <>
                <div id="sub-spc" />

                <div style={{ right: isMobile ? "21px" : "61px", display: "flex", justifyContent: "end", width: isMobile ? "75px" : "95px" }}>
                  <YuJaButton id="sub-btn" onClick={handleClickSub} wrapperStyle={{ height: isMobile ? "100%" : "" }} style={{ fontSize: isMobile ? "14px" : "20px", padding: isMobile ? "auto" : "10px 15px", height: isMobile ? "36px" : "", display: "flex", alignItems: "center", color: isMobile ? "#FFF" : "", backgroundColor: isMobile ? "#42296E" : "", width: isMobile ? "70px" : "95px" }}>
                    {SUBMIT_TEXT}
                  </YuJaButton>
                </div>
              </>
          }
        </div>

          {modalShow &&
            <div style={{backgroundColor: '#282828', borderRadius: 8, padding: 15, flexDirection: "column", display: "flex", alignItems: "center", width: "90%", position: "absolute", left: "5%", bottom: "5%"}}>
                <div style={{display: "flex", alignItems: "center", width: "100%", gap: 5}}>
                        <div tabIndex={0} style={{ fontSize: 13, color: "#FFFFFF", textAlign: "left", flex: 1, minWidth: 0}}>
                          {modalMessage}
                        </div>
                        <div tabIndex={0} style={{ fontSize: 13, color: "#6793FA", }} onClick={() => setModalShow(false)}>
                          Close
                        </div>
                  </div>
            </div>
        }
        
      </div>
  );
}

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthenticationApiCalls } from "../apis/AuthenticationApiCalls";
import { useLocalStorageNew } from "../hooks/useLocalStorageNew";
import { ReactComponent as ArrowLeft } from "../images/ArrowCircleLeft.svg";
import { ReactComponent as ArrowRight } from "../images/ArrowCircleRight.svg";
import ActiveAttendanceGradebook from "../images/links/active_attendance_gradebook_icon.svg";
import ActiveAttendance from "../images/links/active_attendance_icon.svg";
import ActiveCreatePollIcon from "../images/links/active_create_polls_icon.svg";
import ActiveSurveyIcon from "../images/links/active_create_survey_icon.svg";
import { ReactComponent as ActiveDown } from "../images/links/active_down.svg";
import ActivePollGradebookIcon from "../images/links/active_poll_gradebook_icon.svg";
import { ReactComponent as ActiveUp } from "../images/links/active_up.svg";
import ActiveViewerPollSurveyIcon from "../images/links/active_viewer_polls_surveys.svg";
import AttendanceGradebookIcon from "../images/links/attendance_gradebook.svg";
import AttendanceIcon from "../images/links/create_attendance.svg";
import CreatePollIcon from "../images/links/create_poll_icon.svg";
import SurveyIcon from "../images/links/create_survey.svg";
import { ReactComponent as Down } from "../images/links/down.svg";
import PollGradeBookIcon from "../images/links/poll_gradebook_icon.svg";
import { ReactComponent as Up } from "../images/links/up.svg";
import ViewerPollSurveyIcon from "../images/links/viewer_polls_surveys.svg";
import { ReactComponent as Logout } from "../images/logout_mobile.svg";
import styles from "../layouts/Layout.module.css";
import {
    ATTENDANCE,
    BUTTON,
    COLLAPSE_SIDEBAR,
    CREATE_ATTENDANCE,
    CREATE_POLL_PATH,
    CREATE_SURVEY,
    EDIT_POLL_PATH,
    EXPAND_SIDEBAR,
    GRADEBOOK_PATH,
    GRADED_POLL,
    IDENTITY_PROVIDER_TYPES,
    JOIN_POLL_PATH,
    LOGOUT_LABEL,
    MENU_LIST,
    MERGED_POLL_GRADEBOOK,
    NAVBAR_ENGAGE,
    POLL_TEXT,
    POLL_TYPE,
    ROLES,
    SURVEY,
    TAKEN_ACTIVITIES_PATH
} from "../utils/constants";
import { SHOW_CREATE_OPTIONS, SHOW_GRADEBOOK_OPTIONS, VIEWER_SHOW_GRADEBOOK_OPTIONS, updateCreateMode, updateGradebookMode, updateShowCreateOptions, updateShowGradebookOptions, updateShowViewerGradebookOptions } from "../utils/helpers";
import JoinPollModal from "./modals/JoinPollModal";
import WarningModal from "./modals/WarningModal";
import { YuJaTooltip } from "./standardization/YuJaTooltip";
import {useWindowDimensions} from "react-native-web";


export default function NewSidebar({ sideBarCollapseAble, isCreator = false, isViewer = false, isMobile = false, collapsed, setCollapsed }) {
    const history = useHistory();
    let {width: windowWidth} = useWindowDimensions();
    const [showCreateOptions, setShowCreateOptions] = useState(SHOW_CREATE_OPTIONS);
    const [showGradebookOptions, setShowGradebookOptions] = useState(SHOW_GRADEBOOK_OPTIONS);
    const [showViewerGBOptions, setShowViewerGBOptions] = useState(VIEWER_SHOW_GRADEBOOK_OPTIONS);
    const [, getSession] = useLocalStorageNew("session", {});
    const { pathname, state } = useLocation();
    const session = getSession();
    const { role = 'ANONYMOUS' } = session;
    const isEmbeddedFrame = (session.idpType && session.idpType === IDENTITY_PROVIDER_TYPES.LMS && window.location !== window.parent.location);
    const [code, setCode] = useState("");
    const [joinModalShow, setJoinModalShow] = useState(false);
    const [warningModalShow, setWarningModalShow] = useState(false);
    const { logout } = AuthenticationApiCalls();

    useEffect(() => {
        if (windowWidth >= 1350 && !sideBarCollapseAble) {
            setCollapsed(false);
        }
        if (windowWidth < 1350) {
            setCollapsed(true);
        }
    }, []);

    const mouseOver = (e, name) => {
        const menu = Object.values(MENU_LIST).find(i => i.name === name);
        if (window.location.pathname === menu?.path) {
            return;
        }
    }

    const mouseLeave = (e, name) => {
        const menu = Object.values(MENU_LIST).find(i => i.name === name);
        if (window.location.pathname === menu?.path) {
            return;
        }
        const img = document.getElementById(`menu-icon-${name}`);
        if (!!img) {
            img.src = menu?.icon;
        }
    };

    const isActiveCreatePollPage = () => {
        return (window.location.pathname === CREATE_POLL_PATH || window.location.pathname.includes(EDIT_POLL_PATH));
    };

    const isActiveGradebookPage = () => {
        return window.location.pathname === GRADEBOOK_PATH;
    };

    const isActiveTakenActivitiesPage = () => {
        return window.location.pathname === TAKEN_ACTIVITIES_PATH;
    };

    useEffect(() => {
        if (window.location.pathname.includes(JOIN_POLL_PATH)) {
            setCollapsed(true);
        }
    }, [window])

    return (<>
        {window.innerWidth >= 1200 &&
            <div className={styles.sideBarContainer} style={{ width: (collapsed ? "60px" : "240px") }}>
                <div className={styles.sidebarMenu}>
                    <div style={{ padding: "20px 5px 0 5px" }}>
                        <div className={styles.sidebarTitle}>
                            {!collapsed && <div>{NAVBAR_ENGAGE}</div>}
                            {!collapsed && (sideBarCollapseAble || window.innerWidth < 1200) && <div style={{ cursor: "pointer" }} tabIndex={0} aria-label={COLLAPSE_SIDEBAR} role={BUTTON} onClick={() => { setCollapsed(true) }}>
                                <ArrowLeft />
                            </div>}
                            {collapsed && <div style={{ cursor: "pointer" }} aria-label={EXPAND_SIDEBAR} role={BUTTON} tabIndex={0} onClick={() => { setCollapsed(false) }}>
                                <ArrowRight />
                            </div>}
                        </div>
                        <div className={styles.sidebarDivideLine} />
                        {Object.values(MENU_LIST).filter(menu => menu.roles.includes(role)).map((menu, idx) => (
                            <div
                                key={idx}
                                onMouseOver={(e) => mouseOver(e, menu.name)}
                                onMouseLeave={(e) => mouseLeave(e, menu.name)}
                            >
                                {menu.name === MENU_LIST.CREATE.name && <>
                                    <div
                                        tabIndex={0}
                                        aria-label={menu.name}
                                        role={BUTTON}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            setShowCreateOptions(collapsed ? true : !showCreateOptions);
                                            updateShowCreateOptions(collapsed ? true : !showCreateOptions);
                                            setCollapsed(false);
                                        }}
                                        className={`${(isActiveCreatePollPage() && collapsed) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div style={{ width: "100%" }}>
                                            {!collapsed && <div>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={menu.icon}
                                                    style={{ width: "50px", height: "18px", marginRight: "5px" }}
                                                    alt={`${menu.name} icon`}
                                                />
                                                <span className={styles.sidebarText}>{menu.name}</span>
                                            </div>}
                                            {collapsed && <YuJaTooltip placement={"right"} text={menu.name}><div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={isActiveCreatePollPage() ? menu.activeIcon : menu.icon}
                                                    style={{ height: "18px" }}
                                                    alt={`${menu.name} icon`}
                                                    className={`${isActiveCreatePollPage() ? styles.activeSubIcon : ""}`}
                                                />
                                            </div></YuJaTooltip>}
                                        </div>
                                        {!collapsed && showCreateOptions && window.location.pathname === menu.path &&
                                            <ActiveUp style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && showCreateOptions && window.location.pathname !== menu.path &&
                                            <Up style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }

                                        {!collapsed && !showCreateOptions && window.location.pathname === menu.path &&
                                            <ActiveDown style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && !showCreateOptions && window.location.pathname !== menu.path &&
                                            <Down style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                    </div>
                                    {!collapsed && showCreateOptions &&
                                        <div className={styles.createOptionWrap}>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateCreateMode(POLL_TYPE.MERGED_POLL);
                                                    history.push({
                                                        pathname: CREATE_POLL_PATH,
                                                        state: {
                                                            pollType: POLL_TYPE.MERGED_POLL,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.MERGED_POLL) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.MERGED_POLL) ? ActiveCreatePollIcon : CreatePollIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} poll icon`}
                                                        className={`${(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.MERGED_POLL) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{POLL_TEXT}</span>
                                                </div>
                                            </span>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateCreateMode(POLL_TYPE.ATTENDANCE);
                                                    history.push({
                                                        pathname: CREATE_POLL_PATH,
                                                        state: {
                                                            pollType: POLL_TYPE.ATTENDANCE,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? ActiveAttendance : AttendanceIcon}
                                                        style={{ width: "20px", height: "18px", marginRight: "20px", marginLeft: "30px" }}
                                                        alt={`${menu.name} attendance icon`}
                                                        className={`${(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{CREATE_ATTENDANCE}</span>
                                                </div>
                                            </span>
                                        </div>
                                    }
                                </>}
                                {menu.name === MENU_LIST.GRADEBOOK.name && <>
                                    <div
                                        tabIndex={0}
                                        aria-label={menu.name}
                                        role={BUTTON}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            setShowGradebookOptions(collapsed ? true : !showGradebookOptions);
                                            updateShowGradebookOptions(collapsed ? true : !showGradebookOptions);
                                            setCollapsed(false);
                                        }}
                                        className={`${(isActiveGradebookPage() && collapsed) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div style={{ width: "100%" }}>
                                            {!collapsed && <div>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={menu.icon}
                                                    style={{ width: "50px", height: "18px", marginRight: "5px" }}
                                                    alt={`${menu.name} icon`}
                                                />
                                                <span className={styles.sidebarText}>{menu.name}</span>
                                            </div>}
                                            {collapsed && <YuJaTooltip placement={"right"} text={menu.name}><div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={isActiveGradebookPage() ? menu.activeIcon : menu.icon}
                                                    style={{ height: "18px" }}
                                                    alt={`${menu.name} icon`}
                                                    className={`${isActiveGradebookPage() ? styles.activeSubIcon : ""}`}
                                                />
                                            </div></YuJaTooltip>}
                                        </div>
                                        {!collapsed && showGradebookOptions && window.location.pathname === menu.path &&
                                            <ActiveUp style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && showGradebookOptions && window.location.pathname !== menu.path &&
                                            <Up style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }

                                        {!collapsed && !showGradebookOptions && window.location.pathname === menu.path &&
                                            <ActiveDown style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && !showGradebookOptions && window.location.pathname !== menu.path &&
                                            <Down style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                    </div>
                                    {!collapsed && showGradebookOptions &&
                                        <div className={styles.createOptionWrap}>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateGradebookMode(POLL_TYPE.GRADED_POLL);
                                                    history.push({
                                                        pathname: GRADEBOOK_PATH,
                                                        state: {
                                                            pollType: GRADED_POLL,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveGradebookPage() && state?.pollType === GRADED_POLL) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveGradebookPage() && state?.pollType === GRADED_POLL) ? ActivePollGradebookIcon : PollGradeBookIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} poll icon`}
                                                        className={`${(isActiveGradebookPage() && state?.pollType === GRADED_POLL) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{MERGED_POLL_GRADEBOOK}</span>
                                                </div>
                                            </span>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateGradebookMode(POLL_TYPE.ATTENDANCE);
                                                    history.push({
                                                        pathname: GRADEBOOK_PATH,
                                                        state: {
                                                            pollType: POLL_TYPE.ATTENDANCE,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveGradebookPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveGradebookPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? ActiveAttendanceGradebook : AttendanceGradebookIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} attendance icon`}
                                                        className={`${(isActiveGradebookPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{ATTENDANCE}</span>
                                                </div>
                                            </span>
                                        </div>
                                    }
                                </>}
                                {menu.path === MENU_LIST.ACTIVITIES_STUDENT.path && <>
                                    <div
                                        tabIndex={0}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            setShowViewerGBOptions(collapsed ? true : !showViewerGBOptions);
                                            updateShowViewerGradebookOptions(collapsed ? true : !showViewerGBOptions);
                                            setCollapsed(false);
                                        }}
                                        className={`${(isActiveTakenActivitiesPage() && collapsed) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div style={{ width: "100%" }}>
                                            <div>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                                    style={{ width: "50px", height: "18px", marginRight: "5px" }}
                                                    alt={`${menu.name} icon`}
                                                    className={`${window.location.pathname === menu.path ? styles.activeSubIcon : ""}`}
                                                />
                                                <span className={styles.sidebarText}>{menu.name}</span>
                                            </div>
                                        </div>
                                        {!collapsed && showViewerGBOptions && window.location.pathname === menu.path &&
                                            <ActiveUp style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && showViewerGBOptions && window.location.pathname !== menu.path &&
                                            <Up style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }

                                        {!collapsed && !showViewerGBOptions && window.location.pathname === menu.path &&
                                            <ActiveDown style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && !showViewerGBOptions && window.location.pathname !== menu.path &&
                                            <Down style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                    </div>
                                    {!collapsed && showViewerGBOptions &&
                                        <div className={styles.createOptionWrap}>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateCreateMode(POLL_TYPE.GRADED_POLL);
                                                    history.push({
                                                        pathname: TAKEN_ACTIVITIES_PATH,
                                                        state: {
                                                            pollType: GRADED_POLL,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveTakenActivitiesPage() && state?.pollType === GRADED_POLL) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveTakenActivitiesPage() && state?.pollType === GRADED_POLL) ? ActiveViewerPollSurveyIcon : ViewerPollSurveyIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} icon`}
                                                        className={`${(isActiveTakenActivitiesPage() && state?.pollType === GRADED_POLL) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{MERGED_POLL_GRADEBOOK}</span>
                                                </div>
                                            </span>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateCreateMode(POLL_TYPE.ATTENDANCE);
                                                    history.push({
                                                        pathname: TAKEN_ACTIVITIES_PATH,
                                                        state: {
                                                            pollType: POLL_TYPE.ATTENDANCE,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveTakenActivitiesPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveTakenActivitiesPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? ActiveAttendanceGradebook : AttendanceGradebookIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} icon`}
                                                        className={`${(isActiveTakenActivitiesPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{ATTENDANCE}</span>
                                                </div>
                                            </span>
                                        </div>
                                    }
                                </>
                                }
                                {menu.name !== MENU_LIST.CREATE.name && menu.name !== MENU_LIST.GRADEBOOK.name && menu.path !== MENU_LIST.ACTIVITIES_STUDENT.path &&
                                    <span
                                        tabIndex={0}
                                        aria-label={menu.name}
                                        role={BUTTON}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            history.push({ pathname: menu.path });
                                        }}
                                        className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                    >
                                        {!collapsed && <div>
                                            <img
                                                id={`menu-icon-${menu.name}`}
                                                src={(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? menu.activeIcon : menu.icon}
                                                style={{ width: "50px", height: "18px", marginRight: "5px" }}
                                                alt={`${menu.name} icon`}
                                                className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSubIcon : ""}`}
                                            />
                                            <span className={styles.sidebarText}>{menu.name}</span>
                                        </div>}
                                        {collapsed && <YuJaTooltip placement={"right"} text={menu.name}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? menu.activeIcon : menu.icon}
                                                    style={{ height: "18px" }}
                                                    alt={`${menu.name} icon`}
                                                    className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSubIcon : ""}`}
                                                />
                                            </div>
                                        </YuJaTooltip>}
                                    </span>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        }
        {isMobile &&
            <div className={styles.sideBarContainerViewer} style={{ width: "240px" }}>
                <div className={styles.sidebarMenu}>
                    <div style={{ padding: "20px 5px 0 5px" }}>
                        <div className={styles.sidebarTitle}>
                            <div>{NAVBAR_ENGAGE}</div>
                            {role !== ROLES.ANONYMOUS.value && !isEmbeddedFrame &&
                                <div>
                                    <Logout style={{ cursor: "pointer", marginRight: "5px" }} aria-label={LOGOUT_LABEL} role={BUTTON} tabIndex={0} onClick={logout} />
                                </div>
                            }
                        </div>
                        <div className={styles.sidebarDivideLine} />
                        {Object.values(MENU_LIST).filter(menu => menu.roles.includes(role)).map((menu, idx) => (
                            <div
                                key={idx}
                                onMouseOver={(e) => mouseOver(e, menu.name)}
                                onMouseLeave={(e) => mouseLeave(e, menu.name)}
                            >
                                {menu.name === MENU_LIST.CREATE.name && <>
                                    <div
                                        tabIndex={0}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            setShowCreateOptions(collapsed ? true : !showCreateOptions);
                                            updateShowCreateOptions(collapsed ? true : !showCreateOptions);
                                            setCollapsed(false);
                                        }}
                                        className={`${(isActiveCreatePollPage() && collapsed) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div style={{ width: "100%" }}>
                                            <div>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                                    style={{ width: "50px", height: "18px", marginRight: "5px" }}
                                                    alt={`${menu.name} icon`}
                                                    className={`${window.location.pathname === menu.path ? styles.activeSubIcon : ""}`}
                                                />
                                                <span className={styles.sidebarText}>{menu.name}</span>
                                            </div>
                                        </div>
                                        {!collapsed && showCreateOptions && window.location.pathname === menu.path &&
                                            <ActiveUp style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && showCreateOptions && window.location.pathname !== menu.path &&
                                            <Up style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }

                                        {!collapsed && !showCreateOptions && window.location.pathname === menu.path &&
                                            <ActiveDown style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && !showCreateOptions && window.location.pathname !== menu.path &&
                                            <Down style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                    </div>
                                    {!collapsed && showCreateOptions &&
                                        <div className={styles.createOptionWrap}>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateCreateMode(POLL_TYPE.MERGED_POLL);
                                                    history.push({
                                                        pathname: CREATE_POLL_PATH,
                                                        state: {
                                                            pollType: POLL_TYPE.MERGED_POLL,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.MERGED_POLL) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.MERGED_POLL) ? ActiveCreatePollIcon : CreatePollIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} icon`}
                                                        className={`${(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.MERGED_POLL) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{POLL_TEXT}</span>
                                                </div>
                                            </span>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateCreateMode(POLL_TYPE.ATTENDANCE);
                                                    history.push({
                                                        pathname: CREATE_POLL_PATH,
                                                        state: {
                                                            pollType: POLL_TYPE.ATTENDANCE,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? ActiveAttendance : AttendanceIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} icon`}
                                                        className={`${(isActiveCreatePollPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{CREATE_ATTENDANCE}</span>
                                                </div>
                                            </span>
                                        </div>
                                    }
                                </>
                                }
                                {menu.name === MENU_LIST.GRADEBOOK.name && <>
                                    <div
                                        tabIndex={0}
                                        aria-label={menu.name}
                                        role={BUTTON}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            setShowGradebookOptions(collapsed ? true : !showGradebookOptions);
                                            updateShowGradebookOptions(collapsed ? true : !showGradebookOptions);
                                            setCollapsed(false);
                                        }}
                                        className={`${(isActiveGradebookPage() && collapsed) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div style={{ width: "100%" }}>
                                            {!collapsed && <div>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={menu.icon}
                                                    style={{ width: "50px", height: "18px", marginRight: "5px" }}
                                                    alt={`${menu.name} icon`}
                                                />
                                                <span className={styles.sidebarText}>{menu.name}</span>
                                            </div>}
                                            {collapsed && <YuJaTooltip placement={"right"} text={menu.name}><div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={isActiveGradebookPage() ? menu.activeIcon : menu.icon}
                                                    style={{ height: "18px" }}
                                                    alt={`${menu.name} icon`}
                                                    className={`${isActiveGradebookPage() ? styles.activeSubIcon : ""}`}
                                                />
                                            </div></YuJaTooltip>}
                                        </div>
                                        {!collapsed && showGradebookOptions && window.location.pathname === menu.path &&
                                            <ActiveUp style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && showGradebookOptions && window.location.pathname !== menu.path &&
                                            <Up style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }

                                        {!collapsed && !showGradebookOptions && window.location.pathname === menu.path &&
                                            <ActiveDown style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && !showGradebookOptions && window.location.pathname !== menu.path &&
                                            <Down style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                    </div>
                                    {!collapsed && showGradebookOptions &&
                                        <div className={styles.createOptionWrap}>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateGradebookMode(POLL_TYPE.GRADED_POLL);
                                                    history.push({
                                                        pathname: GRADEBOOK_PATH,
                                                        state: {
                                                            pollType: GRADED_POLL,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveGradebookPage() && state?.pollType === GRADED_POLL) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveGradebookPage() && state?.pollType === GRADED_POLL) ? ActivePollGradebookIcon : PollGradeBookIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} poll icon`}
                                                        className={`${(isActiveGradebookPage() && state?.pollType === GRADED_POLL) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{MERGED_POLL_GRADEBOOK}</span>
                                                </div>
                                            </span>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateGradebookMode(POLL_TYPE.ATTENDANCE);
                                                    history.push({
                                                        pathname: GRADEBOOK_PATH,
                                                        state: {
                                                            pollType: POLL_TYPE.ATTENDANCE,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveGradebookPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveGradebookPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? ActiveAttendanceGradebook : AttendanceGradebookIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} attendance icon`}
                                                        className={`${(isActiveGradebookPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{ATTENDANCE}</span>
                                                </div>
                                            </span>
                                        </div>
                                    }
                                </>
                                }
                                {menu.path === MENU_LIST.ACTIVITIES_STUDENT.path && <>
                                    <div
                                        tabIndex={0}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            setShowViewerGBOptions(collapsed ? true : !showViewerGBOptions);
                                            updateShowViewerGradebookOptions(collapsed ? true : !showViewerGBOptions);
                                            setCollapsed(false);
                                        }}
                                        className={`${(isActiveTakenActivitiesPage() && collapsed) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <div style={{ width: "100%" }}>
                                            <div>
                                                <img
                                                    id={`menu-icon-${menu.name}`}
                                                    src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                                    style={{ width: "50px", height: "18px", marginRight: "5px" }}
                                                    alt={`${menu.name} icon`}
                                                    className={`${window.location.pathname === menu.path ? styles.activeSubIcon : ""}`}
                                                />
                                                <span className={styles.sidebarText}>{menu.name}</span>
                                            </div>
                                        </div>
                                        {!collapsed && showViewerGBOptions && window.location.pathname === menu.path &&
                                            <ActiveUp style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && showViewerGBOptions && window.location.pathname !== menu.path &&
                                            <Up style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }

                                        {!collapsed && !showViewerGBOptions && window.location.pathname === menu.path &&
                                            <ActiveDown style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                        {!collapsed && !showViewerGBOptions && window.location.pathname !== menu.path &&
                                            <Down style={{ width: "18px", cursor: "pointer", marginRight: "5px" }} />
                                        }
                                    </div>
                                    {!collapsed && showViewerGBOptions &&
                                        <div className={styles.createOptionWrap}>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateCreateMode(POLL_TYPE.GRADED_POLL);
                                                    history.push({
                                                        pathname: TAKEN_ACTIVITIES_PATH,
                                                        state: {
                                                            pollType: GRADED_POLL,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveTakenActivitiesPage() && state?.pollType === GRADED_POLL) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveTakenActivitiesPage() && state?.pollType === GRADED_POLL) ? ActiveViewerPollSurveyIcon : ViewerPollSurveyIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} icon`}
                                                        className={`${(isActiveTakenActivitiesPage() && state?.pollType === GRADED_POLL) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{MERGED_POLL_GRADEBOOK}</span>
                                                </div>
                                            </span>
                                            <span
                                                tabIndex={0}
                                                onClick={() => {
                                                    updateCreateMode(POLL_TYPE.ATTENDANCE);
                                                    history.push({
                                                        pathname: TAKEN_ACTIVITIES_PATH,
                                                        state: {
                                                            pollType: POLL_TYPE.ATTENDANCE,
                                                            fromNavbar: true,
                                                            previous: pathname
                                                        }
                                                    });
                                                }}
                                                className={`${(isActiveTakenActivitiesPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSideBarLink : styles.createOption}`}
                                            >
                                                <div>
                                                    <img
                                                        id={`menu-icon-${menu.name}`}
                                                        src={(isActiveTakenActivitiesPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? ActiveAttendanceGradebook : AttendanceGradebookIcon}
                                                        style={{ width: "50px", height: "18px", marginRight: "5px", marginLeft: "15px" }}
                                                        alt={`${menu.name} icon`}
                                                        className={`${(isActiveTakenActivitiesPage() && state?.pollType === POLL_TYPE.ATTENDANCE) ? styles.activeSubIcon : ""}`}
                                                    />
                                                    <span className={styles.sidebarText}>{ATTENDANCE}</span>
                                                </div>
                                            </span>
                                        </div>
                                    }
                                </>}
                                {menu.name !== MENU_LIST.CREATE.name && menu.name !== MENU_LIST.GRADEBOOK.name && menu.path !== MENU_LIST.ACTIVITIES_STUDENT.path &&
                                    <span
                                        tabIndex={0}
                                        id={`menu-link-${idx}`}
                                        onClick={() => {
                                            history.push({ pathname: menu.path });
                                        }}
                                        className={`${(window.location.pathname === menu.path || (!!menu.parentPage && window.location.pathname.includes(menu.parentPage))) ? styles.activeSideBarLink : styles.sidebarLink}`}
                                        hidden={!menu.roles.includes(role)}
                                    >
                                        <div>
                                            <img
                                                id={`menu-icon-${menu.name}`}
                                                src={window.location.pathname === menu.path ? menu.activeIcon : menu.icon}
                                                style={{ width: "50px", height: "18px", marginRight: "5px" }}
                                                alt={`${menu.name} icon`}
                                                className={`${window.location.pathname === menu.path ? styles.activeSubIcon : ""}`}
                                            />
                                            <span className={styles.sidebarText}>{menu.name}</span>
                                        </div>
                                    </span>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        }
        <JoinPollModal
            code={code}
            setCode={setCode}
            modalShow={joinModalShow}
            setModalShow={setJoinModalShow}
            setWarningModalShow={setWarningModalShow}
        />
        {warningModalShow && <WarningModal
            show={warningModalShow}
            setModalShow={setWarningModalShow}
            code={code}
            usecase={"2"}
        />}
    </>)
};

import React, { useEffect, useRef, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { BsQuestionCircle, BsQuestionCircleFill } from "react-icons/bs";
import SavedLocationsModal from "../../components/modals/SavedLocationsModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaCheckbox } from "../../components/standardization/YuJaCheckbox";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { YuJaRadioBox } from "../../components/standardization/YuJaRadiobox";
import { YuJaTooltip } from "../../components/standardization/YuJaTooltip";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import AttendanceDetailsIcon from "../../images/attendance_details.svg";
import PollDetailsIcon from "../../images/poll_details.svg";
import QuestionDetailsIcon from "../../images/question_details.svg";
import ToastIcon from "../../images/toast_icon.svg";
import {
    ATMPT_DROPDOWN,
    ATTEMPTS_BOX,
    ATTENDANCE_DETAILS_SETTINGS,
    ATTENDANCE_LENGTH_SETTING_DESC,
    ATTENDANCE_LENGTH_SETTING_TEXT,
    ATTENDANCE_OPTION_TIME_TEXT,
    ATTENDANCE_SAVED_LOCATION_DESCRIPTION,
    CLASS_RESULT_DURATION_DESCRIPTION_SUFFIX,
    CLASS_RESULT_DURATION_LABEL,
    COUNTDOWN_TIMER_DESCRIPTION,
    COUNTDOWN_TIMER_LABEL,
    COUNTDOWN_TIMER_POLL_DESCRIPTION,
    CUSTOM_COUNTDOWN,
    CUSTOM_ENTRIES,
    CUSTOM_MAX_ATTEMPTS_TEXT,
    CUSTOM_POINTS,
    CUSTOM_TIME_LIMIT,
    DEFAULT_ATTENDANCE_SAVED_LOCATIONS,
    DEFAULT_CLASS_RESULT_TIME_LIMIT,
    DEFAULT_ENTRIES_PER_PARTICIPANT,
    DEFAULT_GET_READY_ALL_TIME_LIMIT,
    DEFAULT_GET_READY_TIME_LIMIT,
    DEFAULT_POINTS,
    DEFAULT_POLL_BREAK_TIME_LIMIT,
    DEFAULT_TIME_LIMIT,
    DISABLE_ANONYMOUS_PLATFORM,
    HOURS,
    LMS_GRADEBOOK_SETTINGS_DESCRIPTION,
    LMS_GRADEBOOK_SETTINGS_LABEL,
    LMS_GRADEBOOK_SYNC,
    MAXIMUM_ENTRIES_DESCRIPTION,
    MAXIMUM_ENTRIES_LABEL,
    MINUTES,
    NO_SELECTION_TEXT,
    PARTICIPANTS_LABEL,
    PARTICIPANT_TIME_INTRO,
    PARTICIPANT_TIME_INTRO_ATTEDNANCE,
    POINTS_CHECKBOX_LABEL,
    POINTS_DESCRIPTION,
    POLL_ATTEMPT_SETTINGS_TEXT,
    POLL_BREAK_DURATION_LABEL,
    POLL_BREAK_DURATION_SUFFIX,
    POLL_DETAILS_SETTINGS,
    POLL_LENGTH_SETTING_DESCRIPTION,
    POLL_LENGTH_SETTING_TEXT,
    PPT_AUTO_SHARE_ATTENDANCE_DESCRIPTION,
    PPT_AUTO_SHARE_CHECKBOX_TEXT,
    PPT_AUTO_SHARE_CHECKBOX_TEXT_ATTENDANCE,
    PPT_AUTO_SHARE_DESCRIPTION,
    PPT_AUTO_SHARE_TITLE,
    QUESTION_DETAILS_SETTINGS,
    QUESTION_DURATION_LABEL,
    QUESTION_POINT_LABEL,
    QUES_BANK_CHECKBOX,
    RADIAL_LABEL,
    RECORD_ATTEMPT,
    RECORD_ATTEMPT_TITLE,
    SAVED_LOCATIONS,
    SAVED_LOCATIONS_TITLE,
    SECONDS,
    SHOW_CORRECT_ANSWER_CHECKBOX_LABEL,
    SYNC_ATTEMPT,
    TEXTBOX,
    TIME_LIMIT_CHECKBOX_LABEL,
    TIME_LIMIT_DESCRIPTION,
    TIME_LIMIT_DESCRIPTION_SUFFIX,
    TOAST_DROPDOWN,
    TOAST_MESSAGE_SETTINGS,
    UNLIMITED_MAX_ATTEMPTS_SETTING_TEXT,
    USER_ATTENDANCE_MESSAGE_LABEL,
    USER_POLL_MESSAGE_LABEL,
    USER_QUESTION_MESSAGE_LABEL,
    USER_SETTINGS,
    USER_TOAST_MESSAGE_LABEL,
    USER_TOAST_MESSAGE_TOOLTIP, WC_MAXIMUM_ENTRIES, DEFAULT_ATTENDANCE_TIME_LIMIT
} from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import {
    timePollCustomToSeconds,
    timeSecondsToCustomNew,
    timeSecondsToCustomNewMinutes
} from "../../utils/questionUtils";
import {
    CUSTOM_TIME_LIMIT_MAX_ERROR,
    CUSTOM_TIME_LIMIT_MINUTES_FORMAT_ERROR,
    CUSTOM_TIME_LIMIT_MIN_ERROR,
    INVALID_CUSTOM_POINTS_ERROR,
    MINIMUM_CLASS_RESULT_DURATION_SETTING_ERROR, MINIMUM_POLL_BREAK_DURATION_SETTING_ERROR
} from "../../utils/toast-message-constants";
import styles from "./platform.module.css";

export default function UserSettingTab({userSettings, setUserSettings, isViewer=false}) {
    const [customPoints, setCustomPoints] = useState(!!userSettings[USER_SETTINGS.QUESTION_POINTS] && !!parseInt(userSettings[USER_SETTINGS.QUESTION_POINTS]) ? parseInt(userSettings[USER_SETTINGS.QUESTION_POINTS]) : DEFAULT_POINTS);
    const [customTimeLimit, setCustomTimeLimit] = useState(!!userSettings[USER_SETTINGS.QUESTION_DURATION] && !!parseInt(userSettings[USER_SETTINGS.QUESTION_DURATION]) ? timeSecondsToCustomNewMinutes(userSettings[USER_SETTINGS.QUESTION_DURATION]) : null);
    const [, getSession] = useLocalStorageNew("session", {});
    const session = getSession();
    const [pollTimeLimitH, setPollTimeLimitH] = useState(timeSecondsToCustomNew(userSettings[USER_SETTINGS.POLL_DURATION]).split(":")[0]);
    const [pollTimeLimitM, setPollTimeLimitM] = useState(timeSecondsToCustomNew(userSettings[USER_SETTINGS.POLL_DURATION]).split(":")[1]);
    const [pollTimeLimit, setPollTimeLimit] = useState(timeSecondsToCustomNew(userSettings[USER_SETTINGS.POLL_DURATION]));

    // const [queCRDurationS, setQueCRDurationS] = useState(timeSecondsToCustomNewMinutes(userSettings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION]).split(":")[1]);
    // const [queCRDurationM, setQueCRDurationM] = useState(timeSecondsToCustomNewMinutes(userSettings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION]).split(":")[0]);
    const [queCRDuration, setQueCRDuration] = useState(timeSecondsToCustomNewMinutes(userSettings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION]));
    const [pollBreakDuration, setPollBreakDuration] = useState(timeSecondsToCustomNewMinutes(userSettings[USER_SETTINGS.POLL_BREAK_DURATION]));

    const [attendanceTimeLimitH, setAttendanceTimeLimitH] = useState(timeSecondsToCustomNew(userSettings[USER_SETTINGS.ATTENDANCE_DURATION]).split(":")[0]);
    const [attendanceTimeLimitM, setAttendanceTimeLimitM] = useState(timeSecondsToCustomNew(userSettings[USER_SETTINGS.ATTENDANCE_DURATION]).split(":")[1]);
    // const [attendanceTimeLimitS, setAttendanceTimeLimitS] = useState(timeSecondsToCustomNew(userSettings[USER_SETTINGS.ATTENDANCE_DURATION]).split(":")[2]);
    const [attendanceTimeLimit, setAttendanceTimeLimit] = useState(timeSecondsToCustomNew(userSettings[USER_SETTINGS.ATTENDANCE_DURATION]));
    const [showSavedLocationModal, setShowSavedLocationModal] = useState(false);
    // savedLocations is an array of {name: string, lat: number, lng: number, rad: number}
    const [savedLocations, setSavedLocations] = useState(JSON.parse(userSettings && userSettings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS) ? userSettings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS] : DEFAULT_ATTENDANCE_SAVED_LOCATIONS));
    const optionArr = [
        {value: "5", label: "5 Seconds"},
        {value: "10", label: "10 Seconds"},
        {value: "-1", label: "Manual Dismissal"},
    ];

    const attemptSettingsArr = [
        LMS_GRADEBOOK_SYNC.FIRST,
        LMS_GRADEBOOK_SYNC.RECENT,
        LMS_GRADEBOOK_SYNC.HIGHEST
    ];
    const recordAttemptOptions = useRef([ RECORD_ATTEMPT.ALL, RECORD_ATTEMPT.RECENT ]);

    const [isHovered, setIsHovered] = useState(false);

    const onSelect = (item, setting) => {
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.TOAST_MESSAGE_DURATION] = item.value;
        setUserSettings(copy);
    };

    const handleAttendanceManualEndChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.ATTENDANCE_MANUAL_END] = input.checked;
        setUserSettings(copy);
    };

    const handlePptAutoShareChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.PPT_AUTO_SHARE] = input.checked;
        setUserSettings(copy);
    };

    const handleAttendancePptAutoShareChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] = input.checked;
        setUserSettings(copy);
    };

    const handleAnonymousJoinSettingChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.POLL_REQUIRED_NAME] = input.checked;
        setUserSettings(copy);
    };

    const handleCorrectAnswerChange = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = input.checked;
        setUserSettings(copy);
    };
    
    const handleLMSAttemptSettingChange = (item) => {
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.POLL_LMS_SYNC] = item.value;
        setUserSettings(copy);
    };

    const handleToggleQuestionPoints = (e) => {
        const input = e.currentTarget;
        if (input.checked) {
            const copy = JSON.parse(JSON.stringify(userSettings));
            copy[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
            setUserSettings(copy);
            // if (!customPoints) {
            setCustomPoints(DEFAULT_POINTS);
            // }
        } else {
            const copy = JSON.parse(JSON.stringify(userSettings));
            copy[USER_SETTINGS.QUESTION_POINTS] = 0;
            setUserSettings(copy);
            // setCustomPoints(0);
        }
    };

    const handleToggleQuestionDuration = (e) => {
        const input = e.currentTarget;
        if (input.checked) {
            const copy = JSON.parse(JSON.stringify(userSettings));
            copy[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
            setUserSettings(copy);
            // if (!customTimeLimit) {
            setCustomTimeLimit(timeSecondsToCustomNewMinutes(DEFAULT_TIME_LIMIT));
            // }
        } else {
            const copy = JSON.parse(JSON.stringify(userSettings));
            copy[USER_SETTINGS.QUESTION_DURATION] = 0;
            setUserSettings(copy);
            // setCustomTimeLimit(timeSecondsToCustomNewMinutes(0));
        }
    };

    const onBlurCustomTime = (event) => {
        const value = event.target.value;
        if(value.trim() === "") {
            return
        }
        const seconds = Math.max(0, getSecondsFromHHMMSS(value));
        if(seconds < 10 || seconds >= 3600) {
            setCustomTimeLimit(timeSecondsToCustomNewMinutes(DEFAULT_TIME_LIMIT));
            const copy = JSON.parse(JSON.stringify(userSettings));
            copy[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
            setUserSettings(copy);
        } else {
            const time = toHHMMSS(seconds);
            setCustomTimeLimit(time);
            const copy = JSON.parse(JSON.stringify(userSettings));
            copy[USER_SETTINGS.QUESTION_DURATION] = seconds;
            setUserSettings(copy);
        }
    };

    const onChangeCustomTime = (event) => {
        const value = event.target.value;
        setCustomTimeLimit(value);
    };

    const getSecondsFromHHMMSS = (value) => {
        const [str1, str2, str3] = value.split(":");
    
        const val1 = Number(str1);
        const val2 = Number(str2); 
        const val3 = Number(str3);
    
        if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
        // seconds
        if(val1 > 3600) {
            notifyError(CUSTOM_TIME_LIMIT_MAX_ERROR);
            return 0;
        } else if(val1 < 10) {
            notifyError(CUSTOM_TIME_LIMIT_MIN_ERROR);
            return 0;
        }
          return val1;
        }
    
        if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
        // minutes * 60 + seconds
            if((val1 * 60 + val2) > 3600) {
                notifyError(CUSTOM_TIME_LIMIT_MAX_ERROR);
                return 0;
            }
          return val1 * 60 + val2;
        }
    
        if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
        // hours * 60 * 60 + minutes * 60 + seconds
            notifyError(CUSTOM_TIME_LIMIT_MINUTES_FORMAT_ERROR);
            return 0;
        //   return val1 * 60 * 60 + val2 * 60 + val3;
        }
    
        return 0;
    };

    const toHHMMSS = (secs) => {
        const secNum = parseInt(secs.toString(), 10);
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor(secNum / 60) % 60;
        const seconds = secNum % 60;
    
        return [hours, minutes, seconds]
          .map((val) => (val < 10 ? `0${val}` : val))
          .filter((val, index) => val !== "00" || index > 0)
          .join(":")
    };

    const onChangeCustomPoints = (e) => {
        let w = e.target.value.replace(/[^0-9]/g, '');
        setCustomPoints(parseInt(w));
    };
    
    const onBlurCustomPoints = () => {
        const copy = JSON.parse(JSON.stringify(userSettings));
        if (!isNaN(customPoints) && customPoints <= 100 && customPoints > 0) {
            copy[USER_SETTINGS.QUESTION_POINTS] = customPoints;
        } else {
            notifyError(INVALID_CUSTOM_POINTS_ERROR);
            setCustomPoints(DEFAULT_POINTS);
            copy[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS; 
        }
        setUserSettings(copy);
    };

    const onChangeCustomEntries = (e) => {
        let w = e.target.value.replace(/[^0-9]/g, '');
        w = parseInt(w);
        const copy = JSON.parse(JSON.stringify(userSettings));
        if(!isNaN(w) && w <= WC_MAXIMUM_ENTRIES && w > 0 ) {
            copy[USER_SETTINGS.QUESTION_ENTRIES] = w;
        }
        else {
            copy[USER_SETTINGS.QUESTION_ENTRIES] = ""; 
        }
        setUserSettings(copy);
    };

    const onChangeCustomGetReadyTime = (event) => {
        let value = event.target.value.replace(/[^0-9]/g, '');
        value = parseInt(value, 10);
        const copy = JSON.parse(JSON.stringify(userSettings));
        if(!isNaN(value) && value < 100 && value > 0) {
            copy[USER_SETTINGS.QUESTION_COUNTDOWN] = value;
        }
        else {
            copy[USER_SETTINGS.QUESTION_COUNTDOWN] = "";
        }
        setUserSettings(copy);
    };

    const OnChangePollCountDown = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, '');
        value = parseInt(value, 10);
        const copy = JSON.parse(JSON.stringify(userSettings));
        if(!isNaN(value) && value < 100 && value > 0) {
            copy[USER_SETTINGS.POLL_COUNTDOWN] = value;
        }
        else {
            copy[USER_SETTINGS.POLL_COUNTDOWN] = "";
        }
        setUserSettings(copy);
    }

    const onChangeMaximumAttempts = (e) => {
        const input = e.currentTarget;
        const copy = JSON.parse(JSON.stringify(userSettings));
        if (e.target.checked) {
            copy[USER_SETTINGS.POLL_MAX_ATTEMPTS] = -1;
        }
        else {
            copy[USER_SETTINGS.POLL_MAX_ATTEMPTS] = 1;
        }
        setUserSettings(copy);
    }

    const onChangeCustomMaxAttempts = (e) => {
        let a = e.target.value.replace(/[^0-9]/g, '');
        const copy = JSON.parse(JSON.stringify(userSettings));
        if(!isNaN(parseInt(a, 10)) && a > 0)
        {
        copy[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(a, 10);
        }
        else {
            copy[USER_SETTINGS.POLL_MAX_ATTEMPTS] = "";
        }
        setUserSettings(copy);
    };

    const handleQueCRDurationChangeM = (e) => {
        let minutes = e.target.value;
        if (!minutes) {
            setQueCRDuration("" + queCRDuration.substring(2));
            return;
        }
        const isValidNumber = /^\d+$/.test(minutes);
        if (!isValidNumber) {
            setQueCRDuration(minutes.substring(0, minutes.length - 1) + ":" + queCRDuration.split(":")[1]);
            return;
        }
        minutes = minutes.length <= 2 ? minutes : minutes.substring(0,2);

        if (parseInt(minutes) > 59) {
            minutes = '59';
        }

        setQueCRDuration(minutes + ":" + queCRDuration.split(":")[1]);
    }

    const handleQueCRDurationChangeS = (e) => {
        let seconds = e.target.value;
        if (!seconds) {
            setQueCRDuration(queCRDuration.substring(0, 3) + "");
            return;
        }
        const isValidNumber = /^\d+$/.test(seconds);
        if (!isValidNumber) {
            setQueCRDuration(queCRDuration.split(":")[0] + ":" + seconds.substring(0, seconds.length - 1));
            return;
        }

        seconds = seconds.length <= 2 ? seconds : seconds.substring(0,2);
        if (parseInt(seconds) > 59) {
            seconds = '59';
        }

        setQueCRDuration(queCRDuration.split(":")[0] + ":" + seconds);
    }

    const handleQueCRDurationBlur = () => {
        const minStr = queCRDuration.split(":")[0];
        const secStr = queCRDuration.split(":")[1];

        let minutes = !!minStr ? parseInt(minStr) : 0;
        let seconds = !!secStr ? parseInt(secStr) : 0;


        const copy = JSON.parse(JSON.stringify(userSettings));
        if (minutes * 60 + seconds < DEFAULT_CLASS_RESULT_TIME_LIMIT) {
            notifyError(MINIMUM_CLASS_RESULT_DURATION_SETTING_ERROR);
            copy[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION] = DEFAULT_CLASS_RESULT_TIME_LIMIT;
            setUserSettings(copy);
            setQueCRDuration("00:15");
            return;
        }


        let newMinStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
        let newSecStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
        setQueCRDuration(`${newMinStr}:${newSecStr}`);

        copy[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION] = minutes * 60 + seconds;
        setUserSettings(copy);
    }





    const handlePollBreakDurationChangeM = (e) => {
        let minutes = e.target.value;
        if (!minutes) {
            setPollBreakDuration("" + pollBreakDuration.substring(2));
            return;
        }
        const isValidNumber = /^\d+$/.test(minutes);
        if (!isValidNumber) {
            setPollBreakDuration(minutes.substring(0, minutes.length - 1) + ":" + pollBreakDuration.split(":")[1]);
            return;
        }
        minutes = minutes.length <= 2 ? minutes : minutes.substring(0,2);

        if (parseInt(minutes) > 59) {
            minutes = '59';
        }

        setPollBreakDuration(minutes + ":" + pollBreakDuration.split(":")[1]);
    }



    const handlePollBreakDurationChangeS = (e) => {
        let seconds = e.target.value;
        if (!seconds) {
            setPollBreakDuration(pollBreakDuration.substring(0, 3) + "");
            return;
        }
        const isValidNumber = /^\d+$/.test(seconds);
        if (!isValidNumber) {
            setPollBreakDuration(pollBreakDuration.split(":")[0] + ":" + seconds.substring(0, seconds.length - 1));
            return;
        }

        seconds = seconds.length <= 2 ? seconds : seconds.substring(0,2);
        if (parseInt(seconds) > 59) {
            seconds = '59';
        }

        setPollBreakDuration(pollBreakDuration.split(":")[0] + ":" + seconds);
    }

    const handlePollBreakDurationBlur = () => {
        const minStr = pollBreakDuration.split(":")[0];
        const secStr = pollBreakDuration.split(":")[1];

        let minutes = !!minStr ? parseInt(minStr) : 0;
        let seconds = !!secStr ? parseInt(secStr) : 0;


        const copy = JSON.parse(JSON.stringify(userSettings));
        if (minutes * 60 + seconds < DEFAULT_POLL_BREAK_TIME_LIMIT) {
            notifyError(MINIMUM_POLL_BREAK_DURATION_SETTING_ERROR);
            copy[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION] = DEFAULT_POLL_BREAK_TIME_LIMIT;
            setUserSettings(copy);
            setPollBreakDuration("00:05");
            return;
        }

        let newMinStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
        let newSecStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
        setPollBreakDuration(`${newMinStr}:${newSecStr}`);
        copy[USER_SETTINGS.POLL_BREAK_DURATION] = minutes * 60 + seconds;
        setUserSettings(copy);
    }


    const handlePollTimeChangeH = (e) => {
        let hoursStr = e.target.value;
        if (!hoursStr) {
            setAttendanceTimeLimitH("");
            return;
        }
        const isValidNumber = /^\d+$/.test(hoursStr);
        if (!isValidNumber) {
            return;
        }
        hoursStr = hoursStr.length <= 2 ? hoursStr : hoursStr.substring(0,2);

        if (parseInt(hoursStr) > 23) {
            hoursStr = '23';
        }
        setPollTimeLimitH(hoursStr);
    };

    const handlePollTimeonBlurH = (e) => {
        let hours = pollTimeLimitH;
        const isValidNumber = /^\d+$/.test(pollTimeLimitH);
        if(isValidNumber && parseInt(pollTimeLimitH) > 0) {
            if(hours.length === 1) {
                hours = "0" + hours;
                setPollTimeLimitH(hours);
            }
            setPollTimeLimit(hours + pollTimeLimit.substring(2));
        } else {
            hours = "00";
            setPollTimeLimitH("00");
            setPollTimeLimit(hours + pollTimeLimit.substring(2));
        }
    }

    const handlePollTimeChangeM = (e) => {
        let minStr = e.target.value;
        if (!minStr) {
            setAttendanceTimeLimitM("");
            return;
        }
        const isValidNumber = /^\d+$/.test(minStr);
        if (!isValidNumber) {
            return;
        }
        minStr = minStr.length <= 2 ? minStr : minStr.substring(0,2);

        if (parseInt(minStr) > 59) {
            minStr = '59';
        }
        setPollTimeLimitM(minStr);
    };

    const handlePollTimeonBlurM = (e) => {
        let minStr = pollTimeLimitM;
        const isValidNumber = /^\d+$/.test(pollTimeLimitM);
        if(isValidNumber && parseInt(pollTimeLimitM) > 0) {
            if(minStr.length === 1) {
                minStr = "0" + minStr;
                setPollTimeLimitM(minStr);
            }

            setPollTimeLimit(pollTimeLimit.substring(0, 3) + minStr);
        } else {
            setPollTimeLimitM("00");
            setPollTimeLimit(pollTimeLimit.substring(0, 3) + "00");
        }
    }

    const handleAttendanceTimeChangeH = (e) => {
        let hoursStr = e.target.value;
        if (!hoursStr) {
            setAttendanceTimeLimitH("");
            return;
        }
        const isValidNumber = /^\d+$/.test(hoursStr);
        if (!isValidNumber) {
            return;
        }
        hoursStr = hoursStr.length <= 2 ? hoursStr : hoursStr.substring(0,2);

        if (parseInt(hoursStr) > 23) {
            hoursStr = '23';
        }
        setAttendanceTimeLimitH(hoursStr);
    };

    const handleAttendanceTimeonBlurH = (e) => {
        let hours = attendanceTimeLimitH; 
        const isValidNumber = /^\d+$/.test(attendanceTimeLimitH);
        if(isValidNumber && parseInt(attendanceTimeLimitH) > 0) {
            if(hours.length === 1) {
              hours = "0" + hours;
              setAttendanceTimeLimitH(hours);
            }
            setAttendanceTimeLimit(hours + attendanceTimeLimit.substring(2));
        } else {
            hours = "00";
            setAttendanceTimeLimitH("00");
            setAttendanceTimeLimit(hours + attendanceTimeLimit.substring(2));
        }
    }

    const handleAttendanceTimeChangeM = (e) => {
        let minStr = e.target.value;
        if (!minStr) {
            setAttendanceTimeLimitM("");
            return;
        }
        const isValidNumber = /^\d+$/.test(minStr);
        if (!isValidNumber) {
            return;
        }
        minStr = minStr.length <= 2 ? minStr : minStr.substring(0,2);

        if (parseInt(minStr) > 59) {
            minStr = '59';
        }
        setAttendanceTimeLimitM(minStr);
    };

    const handleAttendanceTimeonBlurM = (e) => {
        let minStr = attendanceTimeLimitM;
        const isValidNumber = /^\d+$/.test(attendanceTimeLimitM);
        if(isValidNumber && parseInt(attendanceTimeLimitM) > 0) {
            if(minStr.length === 1) {
                minStr = "0" + minStr;
                setAttendanceTimeLimitM(minStr);
            }

            setAttendanceTimeLimit(attendanceTimeLimit.substring(0, 3) + minStr);
        } else {
            setAttendanceTimeLimitM("00");
            setAttendanceTimeLimit(attendanceTimeLimit.substring(0, 3) + "00");
        }
    }

    // const handleAttendanceTimeChangeS = (e) => {
    //     let secs = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
    //     setAttendanceTimeLimitS(secs);
    // };

    // const handleAttendanceTimeonBlurS = (e) => {
    //     let secs = attendanceTimeLimitS;
    //     if (!/^\d+$/.test(attendanceTimeLimitS) || isNaN(parseInt(attendanceTimeLimitS, 10))) {
    //         setAttendanceTimeLimitS("00");
    //         return;
    //     }
    //     let unroundedSecs = (secs < 10 ? "0" + secs.toString() : secs.toString());
    //     let mins = Math.floor(parseInt(secs, 10) / 60);
    //     secs = parseInt(secs, 10) % 60;
    //     if (mins + parseInt(attendanceTimeLimitM, 10) > 59) {
    //         unroundedSecs = (secs < 10 ? "0" + secs.toString() : secs.toString());
    //     }
    //     mins = mins + parseInt(attendanceTimeLimitM, 10) > 59 ? 59 : mins + parseInt(attendanceTimeLimitM, 10);

    //     setAttendanceTimeLimitS((secs < 10 ? "0" : "") + secs);
    //     setAttendanceTimeLimitM((mins < 10 ? "0" : "") + mins);
    //     const copy = JSON.parse(JSON.stringify(userSettings));
    //     copy[USER_SETTINGS.ATTENDANCE_DURATION] = timePollCustomToSeconds(attendanceTimeLimit.substring(0, 6) + unroundedSecs);
    //     setUserSettings(copy);
    //     setAttendanceTimeLimit(attendanceTimeLimit.substring(0, 6) + unroundedSecs);
    //     if (parseInt(attendanceTimeLimitS, 10) === 0) {
    //         setAttendanceTimeLimitS("00");
    //     }
    // };

    const handleRecordAttemptOptionClick = (value) => {
        const copy = JSON.parse(JSON.stringify(userSettings));
        if (value === RECORD_ATTEMPT.RECENT.value) {
            copy[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = SYNC_ATTEMPT.RECENT.value;
            copy[USER_SETTINGS.POLL_LMS_SYNC] = LMS_GRADEBOOK_SYNC.RECENT.value; 
        } else if (value === RECORD_ATTEMPT.ALL.value) {
            copy[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = RECORD_ATTEMPT.ALL.value;
            const {settings={}} = session;
            if (settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
                copy[USER_SETTINGS.POLL_LMS_SYNC] =settings[USER_SETTINGS.POLL_LMS_SYNC]; 
            }
        }
        setUserSettings(copy);
    };

    useEffect(() => {
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.ATTENDANCE_DURATION] = timePollCustomToSeconds(attendanceTimeLimit + ":00");
        setUserSettings(copy);
    }, [attendanceTimeLimit]);

    useEffect(() => {
        const copy = JSON.parse(JSON.stringify(userSettings));
        copy[USER_SETTINGS.POLL_DURATION] = timePollCustomToSeconds(pollTimeLimit + ":00");
        setUserSettings(copy);
    }, [pollTimeLimit]);

    return (
        <div>
            <SavedLocationsModal
                show={showSavedLocationModal}
                setShow={setShowSavedLocationModal}
                savedLocations={savedLocations}
                setSavedLocations={setSavedLocations}
                userSettings={userSettings}
                setUserSettings={setUserSettings}
            />
            <div style={{ display: "flex", gap: "50px" }}>
                <div style={{ flexBasis: "50%" }}>
                    {!isViewer && <>
                        <Card className={styles.smallBoxUser} style={{ width: "100%" }}>
                            <Card.Header>
                                <img src={AttendanceDetailsIcon} alt="AttendanceDetailsIcon" width={"24px"} height={"24px"} />
                                <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{ATTENDANCE_DETAILS_SETTINGS}</span>
                            </Card.Header>
                            <Card.Body>
                                <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{USER_ATTENDANCE_MESSAGE_LABEL}</span>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <div style={{ fontSize: "16px",  fontWeight: "700", whiteSpace: "nowrap" }} role={TEXTBOX} tabIndex={0}> {ATTENDANCE_LENGTH_SETTING_TEXT} </div>
                                    <div className="activatePollText" style={{ fontWeight: 400 }}>
                                        {ATTENDANCE_LENGTH_SETTING_DESC}
                                    </div>
                                    <div style={{ display: "flex", width: "100%", gap: "10px", marginBottom: "11px", alignItems: "center" }}>
                                        <YuJaCheckbox
                                            ariaLabel={QUES_BANK_CHECKBOX + ATTENDANCE_OPTION_TIME_TEXT + ((userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true" || userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true) ? "checked" : "unchecked")}
                                            name="anonymous"
                                            checked={userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true" || userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true}
                                            onClick={handleAttendanceManualEndChange}
                                        />
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{ATTENDANCE_OPTION_TIME_TEXT}</span>
                                    </div>
                                    {(userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "false" || userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === false) &&
                                        <div style={{ display: "flex", gap: "5px", whiteSpace: "nowrap" }}>
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ marginTop: "2px" }}> {PARTICIPANT_TIME_INTRO_ATTEDNANCE}</span>
                                            <div style={{ display: "flex", gap: "5px" }}>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <input
                                                        aria-label={attendanceTimeLimitH + " hours"}
                                                        role={TEXTBOX}
                                                        className="custom-input-setting"
                                                        type="text"
                                                        value={attendanceTimeLimitH}
                                                        onChange={handleAttendanceTimeChangeH}
                                                        onBlur={handleAttendanceTimeonBlurH}
                                                        style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                                                    />
                                                    <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}>{HOURS}</div>
                                                </div>
                                                <div style={{ alignItems: "end", justifyContent: "center", display: "flex", height: "21px" }}> : </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <input
                                                        aria-label={attendanceTimeLimitM + " minutes"}
                                                        role={TEXTBOX}
                                                        className="custom-input-setting"
                                                        type="text"
                                                        value={attendanceTimeLimitM}
                                                        onChange={handleAttendanceTimeChangeM}
                                                        onBlur={handleAttendanceTimeonBlurM}
                                                        style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                                                    />
                                                    <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}>{MINUTES}</div>
                                                </div>
                                                {/* <div style={{ alignItems: "end", justifyContent: "center", display: "flex", height: "21px" }}> : </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <input
                                                        aria-label={attendanceTimeLimitS + " minutes"}
                                                        role={TEXTBOX}
                                                        className="custom-input-setting"
                                                        type="text"
                                                        value={attendanceTimeLimitS}
                                                        onChange={handleAttendanceTimeChangeS}
                                                        onBlur={handleAttendanceTimeonBlurS}
                                                        style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                                                    />
                                                    <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}>{SECONDS}</div>
                                                </div> */}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <div style={{ fontSize: "16px",  fontWeight: "700", whiteSpace: "nowrap" }} role={TEXTBOX} tabIndex={0}> {PPT_AUTO_SHARE_TITLE} </div>
                                    <div className="activatePollText" style={{ fontWeight: 400 }}>
                                        {PPT_AUTO_SHARE_ATTENDANCE_DESCRIPTION}
                                    </div>
                                    <div style={{ display: "flex", width: "100%", gap: "10px", marginBottom: "11px", alignItems: "center" }}>
                                        <YuJaCheckbox
                                            ariaLabel={QUES_BANK_CHECKBOX + PPT_AUTO_SHARE_CHECKBOX_TEXT_ATTENDANCE + ((userSettings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === "true" || userSettings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === true) ? "checked" : "unchecked")}
                                            name="anonymous"
                                            checked={userSettings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === "true" || userSettings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === true}
                                            onClick={handleAttendancePptAutoShareChange}
                                        />
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{PPT_AUTO_SHARE_CHECKBOX_TEXT_ATTENDANCE}</span>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <div style={{ fontSize: "16px", fontWeight: "700", whiteSpace: "nowrap" }} role={TEXTBOX} tabIndex={0}> {SAVED_LOCATIONS_TITLE} </div>
                                    <div className="activatePollText" style={{ fontWeight: 400 }}>
                                        {ATTENDANCE_SAVED_LOCATION_DESCRIPTION}
                                    </div>
                                    <div style={{ display: "flex", width: "100%", gap: "10px", marginBottom: "11px", alignItems: "center" }}>
                                        <YuJaButton onClick={() => setShowSavedLocationModal(true)}>
                                            {SAVED_LOCATIONS}
                                        </YuJaButton>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className={styles.smallBoxUser} style={{ width: "100%" }}>
                            <Card.Header>
                                <img src={QuestionDetailsIcon} alt="QuestionDetailsIcon" width={"24px"} height={"24px"} />
                                <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{QUESTION_DETAILS_SETTINGS}</span>
                            </Card.Header>
                            <Card.Body>
                                <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{USER_QUESTION_MESSAGE_LABEL}</span>
                                <div className={styles.cardRow} style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <span className={styles.cardText} style={{ fontWeight: "700" }} role={TEXTBOX} tabIndex={0}> {QUESTION_DURATION_LABEL}</span>
                                    <div style={{ display: "flex", width: "100%", gap: "10px", marginTop: "11px", alignItems: "center" }}>
                                        <YuJaCheckbox
                                            ariaLabel={QUES_BANK_CHECKBOX + QUESTION_DURATION_LABEL + (!!userSettings[USER_SETTINGS.QUESTION_DURATION] && !!parseInt(userSettings[USER_SETTINGS.QUESTION_DURATION]) ? " checked" : " unchecked")}
                                            name="anonymous"
                                            checked={!!userSettings[USER_SETTINGS.QUESTION_DURATION] && !!parseInt(userSettings[USER_SETTINGS.QUESTION_DURATION])}
                                            onClick={handleToggleQuestionDuration}
                                        />
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{TIME_LIMIT_CHECKBOX_LABEL}</span>
                                    </div>
                                    {!!userSettings[USER_SETTINGS.QUESTION_DURATION] && !!parseInt(userSettings[USER_SETTINGS.QUESTION_DURATION]) &&
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px", marginTop: "11px" }}>
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{TIME_LIMIT_DESCRIPTION}</span>
                                            <input
                                                className="custom-input-setting"
                                                aria-label={CUSTOM_TIME_LIMIT}
                                                tabIndex={0}
                                                type="text"
                                                pattern="[0-5][0-9]:[0-5][0-9]"
                                                placeholder="00:00"
                                                value={customTimeLimit}
                                                onChange={onChangeCustomTime}
                                                onBlur={onBlurCustomTime}
                                                style={{
                                                    
                                                    fontSize: "15px",
                                                    // fontWeight: "700",
                                                    lineHeight: "15px",
                                                    marginTop: "0px",
                                                    width: "60px", height: "26px", borderRadius: "2px"
                                                }}
                                            />
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{TIME_LIMIT_DESCRIPTION_SUFFIX}</span>
                                        </div>
                                    }
                                </div>
                                <div className={styles.cardRow} style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ fontWeight: "700" }}>{QUESTION_POINT_LABEL}</span>
                                    <div style={{ display: "flex", width: "100%", gap: "10px", marginTop: "11px", alignItems: "center" }}>
                                        <YuJaCheckbox
                                            ariaLabel={QUES_BANK_CHECKBOX + QUESTION_DURATION_LABEL + (!!userSettings[USER_SETTINGS.QUESTION_POINTS] && !!parseInt(userSettings[USER_SETTINGS.QUESTION_POINTS]) ? " checked" : " unchecked")}
                                            name="anonymous"
                                            checked={!!userSettings[USER_SETTINGS.QUESTION_POINTS] && !!parseInt(userSettings[USER_SETTINGS.QUESTION_POINTS])}
                                            onClick={handleToggleQuestionPoints}
                                        />
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{POINTS_CHECKBOX_LABEL}</span>
                                    </div>
                                    {!!userSettings[USER_SETTINGS.QUESTION_POINTS] && !!parseInt(userSettings[USER_SETTINGS.QUESTION_POINTS]) &&
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px", marginTop: "11px" }}>
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0}> {POINTS_DESCRIPTION}</span>
                                            <input
                                                className="custom-input-setting"
                                                aria-label={CUSTOM_POINTS}
                                                tabIndex={0}
                                                type="number"
                                                min="0"
                                                max="100"
                                                placeholder="00"
                                                value={customPoints}
                                                onChange={onChangeCustomPoints}
                                                onBlur={onBlurCustomPoints}
                                                style={{ display: "flex", justifyContent: "center", width: "60px", height: "26px", borderRadius: "2px" }}
                                            />
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ display: "flex" }}> point(s).</span>
                                        </div>
                                    }
                                </div>
                                <div className={styles.cardRow} style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ fontWeight: "700" }}>{COUNTDOWN_TIMER_LABEL}</span>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0}> {PARTICIPANT_TIME_INTRO}</span>
                                            <input
                                                className="custom-input-setting"
                                                type="number"
                                                min="1"
                                                max="99"
                                                tabIndex={0}
                                                placeholder="00"
                                                aria-label={CUSTOM_COUNTDOWN}
                                                value={userSettings[USER_SETTINGS.QUESTION_COUNTDOWN]}
                                                onChange={onChangeCustomGetReadyTime}
                                                onBlur={() => {
                                                    if (userSettings[USER_SETTINGS.QUESTION_COUNTDOWN] === "") {
                                                        const copy = JSON.parse(JSON.stringify(userSettings));
                                                        copy[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
                                                        setUserSettings(copy);
                                                    }
                                                }}
                                                style={{ display: "flex", justifyContent: "center", width: "60px", height: "26px", borderRadius: "2px" }}
                                            />
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ display: "flex" }}>  {COUNTDOWN_TIMER_DESCRIPTION} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.cardRow} style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ fontWeight: "700" }}>{MAXIMUM_ENTRIES_LABEL}</span>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0}> {MAXIMUM_ENTRIES_DESCRIPTION}</span>
                                            <input
                                                className="custom-input-setting"
                                                type="number"
                                                min="1"
                                                max="10"
                                                tabIndex={0}
                                                placeholder="00"
                                                aria-label={CUSTOM_ENTRIES}
                                                value={userSettings[USER_SETTINGS.QUESTION_ENTRIES]}
                                                onChange={onChangeCustomEntries}
                                                onBlur={() => {
                                                    if (userSettings[USER_SETTINGS.QUESTION_ENTRIES] === "") {
                                                        const copy = JSON.parse(JSON.stringify(userSettings));
                                                        copy[USER_SETTINGS.QUESTION_ENTRIES] = DEFAULT_ENTRIES_PER_PARTICIPANT;
                                                        setUserSettings(copy);
                                                    }
                                                }}
                                                style={{ display: "flex", justifyContent: "center",  width: "60px", height: "26px", borderRadius: "2px" }}
                                            />
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ display: "flex" }}> entries. </span>
                                        </div>
                                    </div>
                                </div>


                                {/*class result duration*/}
                                <div className={styles.cardRow} style={{ display: "flex", flexDirection: "column", marginTop: "21px",gap: 3}}>
                                    <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ fontWeight: "700" }}>{CLASS_RESULT_DURATION_LABEL}</span>
                                    <div style={{ display: "flex", gap: "5px" }}>
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}> {PARTICIPANT_TIME_INTRO}</span>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <input
                                                aria-label={queCRDuration.split(":")[0] + " minutes"}
                                                role={TEXTBOX}
                                                className="custom-input-setting"
                                                type="text"
                                                value={queCRDuration.split(":")[0]}
                                                onChange={handleQueCRDurationChangeM}
                                                onBlur={handleQueCRDurationBlur}
                                                style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                                            />
                                            <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}>{MINUTES}</div>
                                        </div>
                                        <div style={{ alignItems: "end", justifyContent: "center", display: "flex", height: "21px" }}> : </div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <input
                                                aria-label={queCRDuration.split(":")[1] + " seconds"}
                                                role={TEXTBOX}
                                                className="custom-input-setting"
                                                type="text"
                                                value={queCRDuration.split(":")[1]}
                                                onChange={handleQueCRDurationChangeS}
                                                onBlur={handleQueCRDurationBlur}
                                                style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                                            />
                                            <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}>{SECONDS}</div>
                                        </div>
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ display: "flex" }}>  {CLASS_RESULT_DURATION_DESCRIPTION_SUFFIX} </span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </>
                    }
                    <Card className={styles.smallBoxUser} style={{ width: "100%" }}>
                        <Card.Header>
                            <img src={ToastIcon} alt="ToastIcon" width={"24px"} height={"24px"} />
                            <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{TOAST_MESSAGE_SETTINGS}</span>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <div className="form-labelReg">
                                    <span role={TEXTBOX} tabIndex={0}>{USER_TOAST_MESSAGE_LABEL}</span>
                                    <YuJaTooltip
                                        text={USER_TOAST_MESSAGE_TOOLTIP}
                                        placement={"top"}
                                        tooltipStyle={{ maxWidth: 400 }}
                                    >
                                        <span
                                            style={{ margin: '0 10px' }}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                            aria-label={USER_TOAST_MESSAGE_TOOLTIP}
                                            role={TEXTBOX}
                                        >

                                            {isHovered && <BsQuestionCircleFill />}
                                            {!isHovered && <BsQuestionCircle />}
                                        </span>
                                    </YuJaTooltip>
                                </div>

                                <YuJaDropdown
                                    ariaLabel={TOAST_DROPDOWN + userSettings[USER_SETTINGS.TOAST_MESSAGE_DURATION] + "Seconds"}
                                    containerStyle={{ maxWidth: "250px", marginTop: "15px" }}
                                    data={optionArr}
                                    value={userSettings[USER_SETTINGS.TOAST_MESSAGE_DURATION]}
                                    onChange={item => onSelect(item, USER_SETTINGS.TOAST_MESSAGE_DURATION)}
                                    id="userLastElem"
                                />
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                <div style={{ flex: 1 }}>
                    {!isViewer &&
                        <Card className={styles.smallBoxUser} style={{ width: "100%" }}>
                            <Card.Header>
                                <img src={PollDetailsIcon} alt="QuestionDetailsIcon" width={"24px"} height={"24px"} />
                                <span tabIndex={0} role={TEXTBOX} className={styles.cardHeader}>{POLL_DETAILS_SETTINGS}</span>
                            </Card.Header>
                            <Card.Body>
                                <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{USER_POLL_MESSAGE_LABEL}</span>
                                <div className={styles.cardRow} style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <span className={styles.cardText} style={{ fontWeight: "700" }} role={TEXTBOX} tabIndex={0}>{COUNTDOWN_TIMER_LABEL}</span>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0}> {PARTICIPANT_TIME_INTRO}</span>
                                            <input
                                                className="custom-input-setting"
                                                type="number"
                                                min="1"
                                                max="99"
                                                tabIndex={0}
                                                placeholder="00"
                                                aria-label={CUSTOM_COUNTDOWN}
                                                value={userSettings[USER_SETTINGS.POLL_COUNTDOWN]}
                                                onChange={OnChangePollCountDown}
                                                onBlur={() => {
                                                    if (userSettings[USER_SETTINGS.POLL_COUNTDOWN] === "") {
                                                        const copy = JSON.parse(JSON.stringify(userSettings));
                                                        copy[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
                                                        setUserSettings(copy);
                                                    }
                                                }}
                                                style={{ display: "flex", justifyContent: "center", width: "60px", height: "26px", borderRadius: "2px" }}
                                            />
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ display: "flex" }}> {COUNTDOWN_TIMER_POLL_DESCRIPTION} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.cardRow} style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <span className={styles.cardText} style={{ fontWeight: "700" }} role={TEXTBOX} tabIndex={0}>{PARTICIPANTS_LABEL}</span>
                                    <div style={{ display: "flex", width: "100%", gap: "10px", marginTop: "11px", alignItems: "center" }}>
                                        <YuJaCheckbox
                                            ariaLabel={QUES_BANK_CHECKBOX + DISABLE_ANONYMOUS_PLATFORM + ((userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true" || userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] === true) ? "checked" : "unchecked")}
                                            name="anonymous"
                                            checked={userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true" || userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] === true}
                                            onClick={handleAnonymousJoinSettingChange}
                                        />
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{DISABLE_ANONYMOUS_PLATFORM}</span>
                                    </div>
                                    <div style={{ display: "flex", width: "100%", gap: "10px", marginTop: "11px", alignItems: "center" }}>
                                        <YuJaCheckbox
                                            ariaLabel={QUES_BANK_CHECKBOX + SHOW_CORRECT_ANSWER_CHECKBOX_LABEL + ((userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === "true" || userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === true) ? "checked" : "unchecked")}
                                            name="anonymous"
                                            checked={userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === "true" || userSettings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === true}
                                            onClick={handleCorrectAnswerChange}
                                        />
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{SHOW_CORRECT_ANSWER_CHECKBOX_LABEL}</span>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <span className={styles.cardText} style={{ fontWeight: "700" }} role={TEXTBOX} tabIndex={0}> {RECORD_ATTEMPT_TITLE} </span>
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "11px" }}>
                                        {Object.entries(recordAttemptOptions.current).map((optionData, index) => {
                                            const { value, label } = optionData[1];
                                            return (
                                                <div key={index} style={{ padding: "0.5vh 0px" }}>
                                                    <YuJaRadioBox
                                                        aria-label={label + RADIAL_LABEL + (value === userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] ? "selected" : "unselected")}
                                                        label={label}
                                                        checked={value === userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS]}
                                                        onClick={() => handleRecordAttemptOptionClick(value)}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <span className={styles.cardText} style={{ fontWeight: "700", display: "flex", alignItems: "center" }} role={TEXTBOX} tabIndex={0}>{LMS_GRADEBOOK_SETTINGS_LABEL}</span>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px", marginTop: "11px" }}>
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>  The </span>
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <YuJaDropdown
                                                ariaLabel={ATMPT_DROPDOWN + userSettings[USER_SETTINGS.POLL_LMS_SYNC]}
                                                containerStyle={{ maxWidth: "250px", flex: 1 }}
                                                data={attemptSettingsArr}
                                                placeholder={NO_SELECTION_TEXT}
                                                value={userSettings[USER_SETTINGS.POLL_LMS_SYNC]}
                                                onChange={handleLMSAttemptSettingChange}
                                                disabled={userSettings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] == SYNC_ATTEMPT.RECENT.value}
                                            />
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0}> {LMS_GRADEBOOK_SETTINGS_DESCRIPTION}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <span className={styles.cardText} style={{ fontWeight: "700" }} role={TEXTBOX} tabIndex={0}>{POLL_ATTEMPT_SETTINGS_TEXT}</span>
                                    <YuJaCheckbox
                                        size={20}
                                        ariaLabel={ATTEMPTS_BOX + (userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS] == -1 ? "checked" : "unchecked")}
                                        label={UNLIMITED_MAX_ATTEMPTS_SETTING_TEXT}
                                        checked={userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS] == -1}
                                        onClick={onChangeMaximumAttempts}
                                        style={{ marginTop: "11px" }}
                                    />
                                </div>
                                {userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS] != -1 && (
                                    <div style={{ display: "flex", flexDirection: "column", marginTop: "11px" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <input
                                                className="custom-input-setting"
                                                type="number"
                                                min="1"
                                                tabIndex={0}
                                                placeholder="1"
                                                value={userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS]}
                                                onChange={onChangeCustomMaxAttempts}
                                                onBlur={() => {
                                                    if (userSettings[USER_SETTINGS.POLL_MAX_ATTEMPTS] === "") {
                                                        const copy = JSON.parse(JSON.stringify(userSettings));
                                                        copy[USER_SETTINGS.POLL_MAX_ATTEMPTS] = 1;
                                                        setUserSettings(copy);
                                                    }
                                                }}
                                                style={{ display: "flex", justifyContent: "center", width: 60, height: 26, borderRadius: 2, marginRight: 8 }}
                                            />
                                            <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ display: "flex" }}>{CUSTOM_MAX_ATTEMPTS_TEXT}</span>
                                        </div>
                                    </div>
                                )}
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "21px" }}>
                                    <div style={{ fontSize: "16px",  fontWeight: "700", whiteSpace: "nowrap" }} role={TEXTBOX} tabIndex={0}> {POLL_LENGTH_SETTING_TEXT} </div>
                                    <div style={{ display: "flex", gap: "5px", whiteSpace: "nowrap" }}>
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ marginTop: "2px" }}> {PARTICIPANT_TIME_INTRO}</span>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <input
                                                    aria-label={pollTimeLimitH + " hours"}
                                                    role={TEXTBOX}
                                                    className="custom-input-setting"
                                                    type="text"
                                                    value={pollTimeLimitH}
                                                    onChange={handlePollTimeChangeH}
                                                    onBlur={handlePollTimeonBlurH}
                                                    style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                                                />
                                                <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}>{HOURS}</div>
                                            </div>
                                            <div style={{ alignItems: "end", justifyContent: "center", display: "flex", height: "21px" }}> : </div>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <input
                                                    aria-label={pollTimeLimitM + " minutes"}
                                                    role={TEXTBOX}
                                                    className="custom-input-setting"
                                                    type="text"
                                                    value={pollTimeLimitM}
                                                    onChange={handlePollTimeChangeM}
                                                    onBlur={handlePollTimeonBlurM}
                                                    style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                                                />
                                                <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}>{MINUTES}</div>
                                            </div>
                                            {/* <input
                                                role={TEXTBOX}
                                                aria-label={pollTimeLimitS + " seconds"}
                                                className="custom-input2"
                                                type="text"
                                                value={pollTimeLimitS}
                                                onChange={handlePollTimeChangeS}
                                                style={{background: "#ffffff", color: "#565656", fontSize: "13.5px", height: "17px"}}
                                            /> */}
                                        </div>
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ marginTop: "2px" }}> {POLL_LENGTH_SETTING_DESCRIPTION}</span>
                                    </div>
                                </div>


                                {/*poll break duration*/}
                                <div className={styles.cardRow} style={{ display: "flex", flexDirection: "column", gap: 3, marginTop: 21}}>
                                    <span className={styles.cardText} role={TEXTBOX} tabIndex={0} style={{ fontWeight: "700" }}>{POLL_BREAK_DURATION_LABEL}</span>
                                    <span style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}> {PARTICIPANT_TIME_INTRO}</span>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <input
                                                aria-label={pollBreakDuration.split(":")[0] + " minutes"}
                                                role={TEXTBOX}
                                                className="custom-input-setting"
                                                type="text"
                                                value={pollBreakDuration.split(":")[0]}
                                                onChange={handlePollBreakDurationChangeM}
                                                onBlur={handlePollBreakDurationBlur}
                                                style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                                            />
                                            <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}>{MINUTES}</div>
                                        </div>
                                        <div style={{ alignItems: "end", justifyContent: "center", display: "flex", height: "21px" }}> : </div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <input
                                                aria-label={pollBreakDuration.split(":")[1] + " seconds"}
                                                role={TEXTBOX}
                                                className="custom-input-setting"
                                                type="text"
                                                value={pollBreakDuration.split(":")[1]}
                                                onChange={handlePollBreakDurationChangeS}
                                                onBlur={handlePollBreakDurationBlur}
                                                style={{ background: "#ffffff", color: "#565656", fontSize: "13.5px", width: "60px", height: "26px", borderRadius: "2px" }}
                                            />
                                            <div style={{ fontSize: "13px",  fontWeight: "400", textAlign: "center" }}>{SECONDS}</div>
                                        </div>
                                        {POLL_BREAK_DURATION_SUFFIX.split(" ").map((word, index) =>
                                            <span className={styles.cardText} key={index} role={TEXTBOX} tabIndex={0}> {word} </span>
                                        )}
                                    </span>
                                </div>

                                <div className={styles.cardRow} style={{ display: "flex", flexDirection: "column", gap: 3, marginTop: 21 }}>
                                    <div className={styles.cardText} style={{ fontWeight: "700" }} role={TEXTBOX} tabIndex={0}> {PPT_AUTO_SHARE_TITLE} </div>
                                    <div className="activatePollText" style={{ fontWeight: 400, margin: "5px 0px"}}>
                                        {PPT_AUTO_SHARE_DESCRIPTION}
                                    </div>
                                    <div style={{ display: "flex", width: "100%", gap: "10px", alignItems: "center" }}>
                                        <YuJaCheckbox
                                            ariaLabel={QUES_BANK_CHECKBOX + PPT_AUTO_SHARE_CHECKBOX_TEXT + ((userSettings[USER_SETTINGS.PPT_AUTO_SHARE] === "true" || userSettings[USER_SETTINGS.PPT_AUTO_SHARE] === true) ? "checked" : "unchecked")}
                                            name="anonymous"
                                            checked={userSettings[USER_SETTINGS.PPT_AUTO_SHARE] === "true" || userSettings[USER_SETTINGS.PPT_AUTO_SHARE] === true}
                                            onClick={handlePptAutoShareChange}
                                        />
                                        <span className={styles.cardText} role={TEXTBOX} tabIndex={0}>{PPT_AUTO_SHARE_CHECKBOX_TEXT}</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    }
                </div>
            </div>
        </div>
    );
}
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import LTI3LoginComponent from "./components/LTI3LoginComponent";
import LoggingSystem from "./components/LoggingSystem";
import SSOLoginComponent from "./components/SSOLoginComponent";
import { Spin } from "./components/standardization/YuJaLoading";
import Layout from "./layouts/Layout";
import IntegrationPageParent from "./pages/Admin/IntegrationPage/IntegrationPageParent";
import CourseContentPage from "./pages/CourseContentPage/CourseContentPage";
import CreateQuizPageParent from "./pages/CreateQuizPage/CreateQuizPageParent";
import EditQuizPage from "./pages/CreateQuizPage/EditQuizPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import GradeBookPage from "./pages/GradeBookPage/GradeBookPage";
import GuestLoginPage from "./pages/GuestLoginPage/GuestLoginPage";
import StudentDashboard from "./pages/HomePage/StudentDashboard";
import JoinQuizParentNew from "./pages/JoinQuizPage/JoinQuizParentNew";
import OverallResultsPage from "./pages/JoinQuizPage/OverallResultsPage";
import D2LAuthTokenPage from "./pages/LTI/D2LAuthTokenPage";
import EmbeddedPollGuide from "./pages/LTI/EmbeddedPollGuide";
import OpenInNewTab from "./pages/LTI/OpenInNewTab";
import ForgotPasswordPage from "./pages/LoginPage/ForgotPasswordPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import PasswordResetPage from "./pages/LoginPage/PasswordResetPage";
import ManageCoursePollsPage from "./pages/ManageCoursePollsPage/ManageCoursePollPage";
import ManageCoursesPage from "./pages/ManageCoursesPage/ManageCoursesPage";
import FeedbackPage from "./pages/ManagePollsPage/FeedbackPage";
import ManagePollsPage from "./pages/ManagePollsPage/ManagePollsPage";
import ManageUserPage from "./pages/ManageUserPage/ManageUserPage";
import LTIDeepLinkingAutoSubmitForm from "./pages/MediaChooser/LTIDeepLinkingAutoSubmitForm";
import PollChooser from "./pages/MediaChooser/PollChooser";
import MobileMapView from "./pages/MobileMapView/MobileMapView";
import MobileResultPage from "./pages/MobileResultPage/MobileResultPage";
import NoMatchPage from "./pages/NoMatchPage/NoMatchPage";
import PPTEditPollPage from "./pages/PPTPlugin/PPTEditPollPage";
import PPTInsertCompletedPage from "./pages/PPTPlugin/PPTInsertCompletedPage";
import PPTSSOWaitingPage from "./pages/PPTPlugin/PPTInsertWaitingPage";
import PPTLayout from "./pages/PPTPlugin/PPTLayout";
import PPTPollDetailPage from "./pages/PPTPlugin/PPTPollDetailPage";
import PPTPollsPage from "./pages/PPTPlugin/PPTPollsPage";
import PPTSplashScreen from "./pages/PPTPlugin/PPTSplashScreen";
import PlatformSetting from "./pages/PlatformSetting/PlatformSetting";
import ViewerSettings from "./pages/PlatformSetting/ViewerSettings";
import PresentationQuestionPage from "./pages/PresentationPage/PresentationQuestionPage";
import DisabledActivityPage from "./pages/DisabledActivityPage/DisabledActivityPage";
import PPTCreateActivityParentPage from "./pages/PPTPlugin/PPTCreateActivityParentPage";
import SharePollPage from "./pages/PublishedPollPage/SharePollPage";
import EditQuestionProfilePage from "./pages/QuestionProfilesPage/EditQuestionProfilePage";
import QuestionBankPage from "./pages/QuestionProfilesPage/QuestionBankPage";
import MobileSSOLoginPage from "./pages/SAML/MobileSSOLoginPage";
import ScanQRPage from "./pages/ScanQRPage/ScanQRPage";
import ViewEnrolledCoursesPage from "./pages/ViewEnrolledCoursesPage/ViewEnrolledCoursesPage";
import ViewTakenPollsPage from "./pages/ViewTakenPollsPage/ViewTakenPollsPage";
import ToastCollection from "./public/toast/ToastCollection";
import { YuJaGlobalState, useLoading } from "./utils/LoadingContext";
import CreatorWarning from "./pages/LoginPage/CreatorWarning";
import { ROLES } from "./utils/constants";
import RecycleBinPage from "./pages/ManagePollsPage/RecycleBinPage";
import ReportInstPage from "./pages/Report/ReportInstPage";
import ReportCoursePage from "./pages/Report/ReportCoursePage";
import ReportActivityPage from "./pages/Report/ReportActivityPage";
import ReportStandalonePage from "./pages/Report/ReportStandalonePage";

function App() {
    const {isCookieEnabled} = useContext(YuJaGlobalState);
    const { loading } = useLoading();

    // function enables accessibility shortcuts by pressing enter
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                if (document.activeElement.tagName === 'svg') {
                    const clickEvent = new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window,
                    });
                    
                    document.activeElement.dispatchEvent(clickEvent);
                } else {
                    document.activeElement.click();
                }
            }
        };
    
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <>
            {/*!isCookieEnabled &&
                <div style={{width: " 100%", height: "100%"}}>
                    <div style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", marginTop: "200px"}}>
                        <div style={{fontSize: "30px", color: "rgb(102, 102, 102)", fontFamily: "Geomanist,Roboto,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif"}}>Please enable third-party cookies </div>
                        <p style={{fontSize: "18px",  color: "rgb(102, 102, 102)", fontFamily: "Geomanist,Roboto,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif"}}> Engage's LMS integration requires third-party cookies to be enabled. Please <span  onClick={() => window.open("https://support.yuja.com/hc/en-us/articles/360049583574-Enabling-Third-Party-Cookies", "_blank")} style={{textDecoration: "underline", cursor: "pointer"}}>click here</span> to learn how to do this</p>
                    </div>
                </div>
            */}
                <BrowserRouter>
                    <ToastCollection />
                    <LoggingSystem />
                    <Switch>
                        <Route exact path="/"
                               render={() => (
                                   <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                       <LoginPage />
                                   </Spin>)}
                        />

                        <Route exact path="/home"
                               render={() => (
                                   <DashboardPage/>
                               )}
                        />

                        <Route exact path="/reset/:userName/:resetCode"
                               render={() => (
                                   <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                       <PasswordResetPage />
                                   </Spin>)}
                        />

                        <Route exact path="/forgot"
                               render={() => (
                                   <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                       <ForgotPasswordPage />
                                   </Spin>)}
                        />

                        <Route exact path="/join/:pollUniqueCode"
                               render = {() => (
                                   <Layout pageRoles={[ROLES.VIEWER.value, ROLES.ANONYMOUS.value]}>
                                       <JoinQuizParentNew/>
                                   </Layout>
                               )}
                        />

                        <Route exact path="/scan"
                               render = {() => (
                                   <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                       <ScanQRPage/>
                                   </Spin>
                               )}
                        />

                        <Route exact path="/qr/:pollUniqueCode"
                               render = {() => (
                                   <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                        <GuestLoginPage />
                                   </Spin>
                               )}
                        />


                        <Route exact path="/home/sso"
                               render={() => (
                                   <SSOLoginComponent />
                               )}
                        />

                        <Route exact path="/sso/:endType/:result"
                               render={() => (
                                   <MobileSSOLoginPage />
                               )}
                        />

                        <Route exact path="/home/lti"
                               render={() => (
                                   <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                       <LTI3LoginComponent />
                                   </Spin>
                               )}
                        />

                        <Route exact path="/home/create"
                               render={() => (
                                   <Layout flex={true} hasPin={true}>
                                       <StudentDashboard show={"nav"} />
                                   </Layout>)}
                        />

                        <Route exact path="/create"
                               render={() => (
                                   <Layout isSidebar={true}>
                                        <CreateQuizPageParent />
                                   </Layout>)}
                        />

                        <Route exact path="/edit/:pollKey/:pollCode"
                               render={() => (
                                   <>
                                       <Layout isSidebar={true}>
                                           <EditQuizPage />
                                       </Layout>
                                   </>)}
                        />

                        <Route exact path="/warning"
                               render={() => (
                                   <>
                                           <CreatorWarning />
                                   </>)}
                        />

                        <Route exact path="/results"
                               render={() => (
                                   <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                       <OverallResultsPage />
                                   </Spin>
                               )}
                        />

                        <Route exact path="/" component={GuestLoginPage} />

                        <Route exact path="/admin/integration"
                               render={() => (
                                   <Layout hasPin={true}>
                                       <IntegrationPageParent />
                                   </Layout>)}
                        />

                        <Route exact path="/admin/platformsetting"
                               render={() => (
                                   <Layout hasPin={true}>
                                       < PlatformSetting/>
                                   </Layout>)}
                        />
                        <Route exact path="/student/settings"
                               render={() => (
                                   <Layout hasPin={true} pageRoles={[ROLES.VIEWER.value]}>
                                       <ViewerSettings/>
                                   </Layout>)}
                        />

                        <Route exact path="/qr"
                               render={() => (
                                   <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                       <GuestLoginPage />
                                   </Spin>
                               )}
                        />

                        <Route exact path="/course/content/:courseId"
                               render={() => (
                                   <Layout hasPin={true}>
                                       <CourseContentPage />
                                   </Layout>)}
                        />

                        <Route exact path="/poll/user/"
                               render={() => (
                                   <Layout flex={false} hasPin={true}>
                                       <ManagePollsPage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/poll/course/:courseId"
                               render={() => (
                                   <Layout flex={false} hasPin={true}>
                                       <ManageCoursePollsPage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/poll/choose/:courseId"
                               render={() => (
                                   <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                                       <PollChooser />
                                   </Spin>
                               )}
                        />

                        <Route exact path="/lti/deeplink/form"
                               render={() => (
                                   <LTIDeepLinkingAutoSubmitForm />
                               )}
                        />

                        <Route exact path="/questions/edit/:questionProfileId"
                               render={() => (
                                   <Layout isSidebar={true}>
                                       <EditQuestionProfilePage />
                                   </Layout>
                               )}
                        />


                        <Route exact path="/gradeBook"
                               render={() => (
                                   <Layout hasPin={true}>
                                       <GradeBookPage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/student/polls"
                               render={() => (
                                   <Layout flex={true} hasPin={true} pageRoles={[ROLES.VIEWER.value]}>
                                       <ViewTakenPollsPage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/student/courses"
                               render={() => (
                                   <Layout flex={true} hasPin={true} pageRoles={[ROLES.VIEWER.value]}>
                                       <ViewEnrolledCoursesPage/>
                                   </Layout>
                               )}
                        />

                        <Route exact path="/course/user/"
                               render={() => (
                                   <Layout flex={false} hasPin={true}>
                                       <ManageCoursesPage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/course/user/create"
                               render={() => (
                                   <Layout flex={false} hasPin={true}>
                                       <ManageCoursesPage show={"nav"}/>
                                   </Layout>
                               )}
                        />

                        {/* <Route exact path="/poll/published/:pollKey/:pollCode"
                               render={() => (
                                   <Layout width={"0%"} hasPin={true}>
                                       <PublishedPollPage />
                                   </Layout>
                               )}
                        /> */}

                        <Route exact path="/poll/share/:pollKey/:pollCode"
                               render={() => (
                                   <Layout width={"0%"} hasPin={true}>
                                       <SharePollPage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/disabled/poll-survey"
                            render={() => (
                                <Layout flex={false} hasPin={true}>
                                    <DisabledActivityPage />
                                </Layout>
                            )}
                        />

                        <Route exact path="/poll/feedback/:pollKey/:pollCode"
                               render={() => (
                                   <Layout width={"0%"} hasPin={true}>
                                       <FeedbackPage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/recyclebin"
                               render={() => (
                                   <Layout width={"0%"} hasPin={true}>
                                       <RecycleBinPage />
                                   </Layout>
                               )}
                        />

                        < Route exact path="/questionBank"
                               render={() => (
                                   <Layout width={"0%"} hasPin={true}>
                                       <QuestionBankPage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/users"
                               render={() => (
                                   <Layout flex={true} hasPin={true}>
                                       <ManageUserPage />
                                   </Layout>
                               )}
                        />
                        <Route exact path="/report/inst"
                               render={() => (
                                   <Layout  hasPin={true}>
                                       <ReportInstPage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/report/standalone"
                               render={() => (
                                   <Layout hasPin={true}>
                                       <ReportStandalonePage />
                                   </Layout>
                               )}
                        />
                        <Route exact path="/report/course/:courseId"
                               render={() => (
                                   <Layout  hasPin={true}>
                                       <ReportCoursePage />
                                   </Layout>
                               )}
                        />

                        <Route exact path="/report/activity/:pollKey/:pollCode"
                               render={() => (
                                   <Layout hasPin={true}>
                                       <ReportActivityPage />
                                   </Layout>
                               )}
                        />



                        <Route exact path="/presentation/:pollCode/:serialNo"
                               render={() => (
                                   <PresentationQuestionPage/>
                               )}
                        />
                        <Route exact path="/mobile/map/:lat/:lng/:rad"
                                render={() => (
                                    <MobileMapView/>
                               )}
                        />
                        <Route exact path="/mobile/result/:pollKey/:pollCode/:serialNo"
                               render={() => (
                                   <MobileResultPage/>
                               )}
                        />
                        <Route exact path="/portal"
                                render={() => (
                                    <OpenInNewTab/>
                                )}
                        />
                        <Route exact path="/lti/:pollCode/guide"
                               render = {() => (
                                   <>
                                       <Layout pageRoles={[ROLES.VIEWER.value, ROLES.ANONYMOUS.value]}>
                                           <EmbeddedPollGuide/>
                                       </Layout>
                                   </>
                               )}
                        />
                        <Route exact path="/api/d2l/success"
                               render = {() => (
                                   <>
                                    <D2LAuthTokenPage/>
                                   </>
                               )}
                        />
                        <Route exact path="/PPT/:appId/polls"
                               render={() => (
                                   <PPTLayout>
                                       <PPTPollsPage/>
                                   </PPTLayout>
                               )}
                        />
                        <Route exact path="/PPT/:appId/polls/:pollKey/:pollCode?/detail"
                               render={() => (
                                   <PPTLayout>
                                       <PPTPollDetailPage/>
                                   </PPTLayout>
                               )}
                        />
                        <Route exact path="/PPT/:appId/poll/:pollType/create"
                               render={() => (
                                   <PPTLayout>
                                       <PPTCreateActivityParentPage/>
                                   </PPTLayout>
                               )}
                        />

                        <Route exact path="/PPT/:appId/poll/:pollKey/edit"
                               render={() => (
                                   <PPTLayout>
                                       <PPTEditPollPage/>
                                   </PPTLayout>
                               )}
                        />
                        <Route exact path="/PPT/:appId/waiting"
                               render={() => (
                                   <PPTLayout>
                                       <PPTSSOWaitingPage/>
                                   </PPTLayout>
                               )}
                        />

                        <Route exact path="/PPT/:appId/:addedSlideCount/completed"
                               render={() => (
                                   <PPTLayout>
                                       <PPTInsertCompletedPage/>
                                   </PPTLayout>
                               )}
                        />
                        <Route exact path="/PPT/splash"
                               render={() => (
                                   <PPTSplashScreen/>
                               )}
                        />
                        <Route component={NoMatchPage} />
                    </Switch>
                </BrowserRouter>
        </>

    );
}

export default App;

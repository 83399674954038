import React, { useEffect, useRef } from "react";
import { ReactComponent as ICIcon } from "../../images/clickable_purple.svg";
import { ReactComponent as DropDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as FITBIcon } from "../../images/fitb_purple.svg";
import { ReactComponent as MHIcon } from "../../images/matching_purple.svg";
import { ReactComponent as MCSSIcon } from "../../images/mcss_purple.svg";
import { ReactComponent as SAIcon } from "../../images/sa_purple.svg";
import { ReactComponent as OEIcon } from "../../images/OpenEnded.svg";
import { ReactComponent as TFIcon } from "../../images/tf_purple.svg";
import { ReactComponent as WCIcon } from "../../images/wcloud_purple.svg";
import { ReactComponent as RKIcon } from "../../images/RankingIcon.svg";
import { CREATE_QUES_TYPE, QUESTION_TYPES, TEXTBOX } from "../../utils/constants";
import { questionCodeToName, questionNameToCode } from "../../utils/questionUtils";
import { useYuJaEventBus } from "./YuJaEventBusProvider";
import styles from "./YuJaKebabMenu.module.css";
import QuestionTypeOption from "../../pages/CreateQuizPage/QuestionTypeOption";

export const CreatePollDropDown = ({options=[], isCreateOpen, setIsCreateOpen, questionType, setShownQuestionType, children, handleQuestionTypeUpdate, ...props }) => {
    // const [isOpen, setIsOpen] = useState(false);
    const iconRef = useRef();
    const menuRef = useRef();
    const { addClickEventHandler, removeClickEventHandler } = useYuJaEventBus();

    useEffect(() => {
        const handleGlobalClick = (event) => {
            // Check if the click occurred outside of the dropdown
            if (isCreateOpen && menuRef.current && !iconRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
                setIsCreateOpen(false);
            }
        };

        if (isCreateOpen) {
            addClickEventHandler(handleGlobalClick);
        }

        return () => {
            removeClickEventHandler(handleGlobalClick);
        };
    }, [isCreateOpen]);


    const open = () => {
        setIsCreateOpen(!isCreateOpen);
    };

    const onClick = (e) => {
        setIsCreateOpen(false);
        handleQuestionTypeUpdate(e.target.innerText);
    }

    const onHover = (e) => {
        setShownQuestionType(questionNameToCode(e.target.innerText));
    }


    return (
        <div className={`${styles.container} ${styles.questionTypeDropdownContainer}`} onMouseLeave={() => setShownQuestionType(questionType)}>
            <div ref={iconRef} onClick={open} tabIndex={0} role={TEXTBOX} aria-label={questionCodeToName(questionType) + CREATE_QUES_TYPE} style={{  display: "flex", justifyContent: "center", alignItems: "center", gap: "8.41px", whiteSpace: "nowrap", cursor: "pointer" }}>
                {questionType === QUESTION_TYPES.MCSS.name && <MCSSIcon alt="MCSS Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.SA.name && <SAIcon alt="SA Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.OE.name && <OEIcon alt="QA Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.TF.name && <TFIcon alt="TF Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.FITB.name && <FITBIcon alt="FITB Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.CI.name && <ICIcon alt="CI Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.WC.name && <WCIcon alt="WC Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.MH.name && <MHIcon alt="MH Icon" style={{ width: "24px", height: "24px" }} />}
                {questionType === QUESTION_TYPES.RK.name && <RKIcon alt="RK Icon" style={{ width: "24px", height: "24px" }} />}
                {" " + questionCodeToName(questionType)}
                <DropDown style={{height: 17, width: 17}}/>
            </div>
            {isCreateOpen &&
                <div className={styles.createMenuContainer} ref={menuRef}>
                    <div className={`${styles.menuList}`} style={{ width: "183px" }} >
                        {options.map((item, index) =>
                            <div key={index} className={`${styles.menuItem}`} role={TEXTBOX} onClick={onClick} onMouseEnter={onHover} tabIndex={0} >
                                <QuestionTypeOption
                                    optionData={item}
                                    handleQuestionTypeUpdate={() => { }}
                                />
                            </div>
                        )}
                    </div>
                </div>

            }
        </div>
    );
}

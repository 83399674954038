import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdArrowBackIosNew } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { UserApiCalls } from "../../apis/UserApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import YuJaButton from "../../components/standardization/YuJaButton";
import { Spin } from "../../components/standardization/YuJaLoading";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { usePPTWebView } from "../../hooks/usePPTWebView";
import BaseQuestionTitle from "../../questions/BaseQuestionTitle";
import SAQuestion from "../../questions/SA/SAQuestion";
import { useLoading } from "../../utils/LoadingContext";
import {
    ATTENDANCE_DEFAULT_QUESTION_TITLE,
    ATTENDANCE_DEFAULT_TITLE,
    ATTENDANCE_TITLE_PLACEHOLDER,
    AUDIENCE_RESTRICTION,
    CREATE_ATTENDANCE_LABEL,
    DATE_TO_REPLACE,
    DEFAULT_ATTENDANCE_MANUAL_END,
    DEFAULT_ATTENDANCE_TIME_LIMIT, DEFAULT_CLASS_RESULT_TIME_LIMIT,
    DEFAULT_GET_READY_ALL_TIME_LIMIT,
    DEFAULT_GET_READY_TIME_LIMIT,
    DEFAULT_POINTS, DEFAULT_POLL_LMS_SYNC, DEFAULT_POLL_MAX_ATTEMPTS, DEFAULT_POLL_RECORD_ATTEMPTS,
    DEFAULT_POLL_REQUIRED_NAME,
    DEFAULT_POLL_SHOW_CORRECT_ANS,
    DEFAULT_POLL_TIME_LIMIT,
    DEFAULT_PPT_AUTO_SHARE,
    DEFAULT_TIME_LIMIT,
    GO_BACK_POLLS_LINK,
    POLL_SHARE_MODE,
    POLL_TIME_LIMIT_MODAL_CANCEL,
    POLL_TITLE_LENGTH,
    POLL_TYPE,
    QUESTION_LENGTH,
    QUESTION_TYPES,
    SAVE_CLOSE_BUTTON_TEXT,
    SWITCH_POLL_MESSAGE,
    SWITCH_POLL_MODAL_TEXT,
    SWITCH_POLL_MODAL_TITLE,
    USER_SETTINGS,
} from "../../utils/constants";
import { getDefaultUserSettings, notifyError, updateCreateMode, validateText } from "../../utils/helpers";
import { updateTitleInput } from "../../utils/questionUpdate";
import { addQuestionPPT, chooseQuestion, questionError } from "../../utils/questionUtils";
import {
    ATTENDANCE_TITLE_CHARACTER_LIMIT_EXCEEDED,
    ATTENDANCE_TITLE_ERROR,
    QUESTION_CHARACTER_LIMIT_EXCEEDED,
    QUESTION_MAX_POINTS_ERROR
} from "../../utils/toast-message-constants";
import styles from "./PPTCreatePollPage.module.css";

export default function PPTCreateAttendancePage() {
    const { goPollsPage, isFromSidebar, parseToken} = usePPTWebView();

    const { loading } = useLoading();
    const history = useHistory();
    const location = useLocation();
    const isNarrow = useMobileAccess(1100);

    const { saveAndPublish, fakeCall } = PollApiCalls();
    const { loadUser } = UserApiCalls();

    const pollKey = useRef("");

    const [, getSession] = useLocalStorageNew("session", {});
    const session = getSession();
    const [setPollTitle, getPollTitle] = useLocalStorageNew("attendanceTitle", "");
    const [setQues, getQues] = useLocalStorageNew("attendanceQuestions", []);
    const [setType, _] = useLocalStorageNew("pollType", "");
    const [setSaveExit, getsaveExit] = useLocalStorageNew("saveExit", false);
    const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentAttendanceQuestions", []);
    const [setRecentTitle, getRecentTitle] = useLocalStorageNew("recentAttendanceTitle", "");
    const [setRecentGeofence, getRecentGeofence] = useLocalStorageNew("recentGeofence", {});
    const [setRecentSyncLms, getRecentSyncLms] = useLocalStorageNew("recentSyncLms", true);

    // const hostResource = useHostname(window.location.hostname);
    // const { institutionId = "" } = hostResource;
    const [title, setTitle] = useState("");
    // const [pollType, setPollType] = useState(POLL_TYPE.ATTENDANCE);
    const [questions, setQuestions] = useState([]);
    const [userSettings, setUserSettings] = useState(getDefaultUserSettings());

    const [focusSAElem, setFocusSAElem] = useState(-1);

    const [disableSave, setDisableSave] = useState(false);

    const [isConfirmationPending, setIsConfirmationPending] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [nextRouterPath, setNextRouterPath] = useState({ pathname: '', state: null });
    const [alertContent,] = useState(SWITCH_POLL_MESSAGE);

    const [currQueDisplay, setCurrQueDisplay] = useState(1);
    const [geofence, setGeofence] = useState({});
    const [syncLms, setSyncLms] = useState(true);
    const [mapInitialized, setMapInitialized] = useState(false);

    //
    // const {websocketMsg, buildConnection, closeConnection, sendMsg} = useJoinQuizWebsocketManager(
    //         () => `${WSS_BASE_URL}?role=${WEBSOCKET_PPT}&endType=${PPT_WEBSOCKET_END_TYPE}&applicationId=${getAppId()}`,
    //     null
    // );


    if (questions.length < 1) {
        if (title === "") {
            setTitle(ATTENDANCE_DEFAULT_TITLE.replace(DATE_TO_REPLACE, new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })));
        }

        const qs = addQuestionPPT([], QUESTION_TYPES.SA.name, POLL_TYPE.ATTENDANCE);
        qs[0].queTitle = ATTENDANCE_DEFAULT_QUESTION_TITLE;
        qs[0].timeLimit = DEFAULT_ATTENDANCE_TIME_LIMIT;
        qs[0].getReadyTimeLimit = 0; // no get ready time for attendance
        setQuestions(qs);
        setCurrQueDisplay(qs[0].serialNo);
        setGeofence({});
        setSyncLms(true);
    }


    useEffect(() => {
        const {institutionId, userId} = parseToken();
        loadUser(institutionId, userId).then(res => {
            const { settings = {} } = res;
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_DURATION)) {
                settings[USER_SETTINGS.QUESTION_DURATION] = DEFAULT_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_DURATION] = parseInt(settings[USER_SETTINGS.QUESTION_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_POINTS)) {
                settings[USER_SETTINGS.QUESTION_POINTS] = DEFAULT_POINTS;
            }
            settings[USER_SETTINGS.QUESTION_POINTS] = parseInt(settings[USER_SETTINGS.QUESTION_POINTS], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_COUNTDOWN)) {
                settings[USER_SETTINGS.QUESTION_COUNTDOWN] = DEFAULT_GET_READY_TIME_LIMIT;
            }
            settings[USER_SETTINGS.QUESTION_COUNTDOWN] = parseInt(settings[USER_SETTINGS.QUESTION_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_DURATION)) {
                settings[USER_SETTINGS.POLL_DURATION] = DEFAULT_POLL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_DURATION] = parseInt(settings[USER_SETTINGS.POLL_DURATION], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_COUNTDOWN)) {
                settings[USER_SETTINGS.POLL_COUNTDOWN] = DEFAULT_GET_READY_ALL_TIME_LIMIT;
            }
            settings[USER_SETTINGS.POLL_COUNTDOWN] = parseInt(settings[USER_SETTINGS.POLL_COUNTDOWN], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
                settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
            }
            settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_SHOW_CORRECT_ANS)) {
                settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = DEFAULT_POLL_SHOW_CORRECT_ANS;
            }
            settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] = (settings[USER_SETTINGS.POLL_SHOW_CORRECT_ANS] === "true");
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_LMS_SYNC)) {
                settings[USER_SETTINGS.POLL_LMS_SYNC] = DEFAULT_POLL_LMS_SYNC;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_RECORD_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_RECORD_ATTEMPTS] = DEFAULT_POLL_RECORD_ATTEMPTS;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.POLL_MAX_ATTEMPTS)) {
                settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = DEFAULT_POLL_MAX_ATTEMPTS;
            }
            settings[USER_SETTINGS.POLL_MAX_ATTEMPTS] = parseInt(settings[USER_SETTINGS.POLL_MAX_ATTEMPTS], 10);
            if (!settings.hasOwnProperty(USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION)) {
                settings[USER_SETTINGS.QUESTION_CLASS_RESULT_DURATION] = DEFAULT_CLASS_RESULT_TIME_LIMIT;
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
                settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
            }
            settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = (settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true" || settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true);
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
                settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
            } else {
                settings[USER_SETTINGS.ATTENDANCE_DURATION] = parseInt(settings[USER_SETTINGS.ATTENDANCE_DURATION], 10);
            }
            if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE)) {
                settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
            } else {
                settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] = (settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === "true" || settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === true);
            }
            setUserSettings(settings);
        })
    }, []);



    useEffect(() => {
        updateCreateMode(POLL_TYPE.ATTENDANCE);
    }, []);

    const checkChanged = () => {
        const curr_ques = JSON.stringify(getRecentQuestions());
        const curr_saved = JSON.stringify(getQues());
        const defaultCorrectAnswerAttendance = '[""]';
        const defaultGeofence = "{}";
        const defaultSyncLms = true;
        if (curr_saved === "[]" && (getRecentQuestions().length === 0 ||
            (getRecentTitle() === ATTENDANCE_DEFAULT_TITLE.replace(DATE_TO_REPLACE, new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })) &&
                getRecentQuestions()[0].queTitle === ATTENDANCE_DEFAULT_QUESTION_TITLE &&
                JSON.stringify(getRecentQuestions()[0].correctAnswers) === defaultCorrectAnswerAttendance &&
                JSON.stringify(getRecentGeofence()) === defaultGeofence &&
                getRecentSyncLms() === defaultSyncLms))) {
            console.log("curr_saved is empty and recent is default");
            return false;
        } else if (curr_saved === "{}") {
            console.log("curr_saved is empty");
            return true;
        } else {
            console.log(curr_ques, curr_saved, !(curr_ques === curr_saved));
            return !(curr_ques === curr_saved);
        }
    };

    const alertConfig = {
        title: SWITCH_POLL_MODAL_TITLE,
        okText: SWITCH_POLL_MODAL_TEXT,
        cancelText: POLL_TIME_LIMIT_MODAL_CANCEL, 
        submit: async () => {
            setAlertShow(false);
            setIsConfirmationPending(true);
        },
        close: async () => {
            setAlertShow(false);
        },
    };

    useEffect(() => {
        setRecentQuestions(questions);
    }, [questions]);

    useEffect(() => {
        setRecentTitle(title);
    }, [title]);

    useEffect(() => {
        setRecentGeofence(geofence);
    }, [geofence]);

    useEffect(() => {
        setRecentSyncLms(syncLms);
    }, [syncLms]);

    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (!checkChanged() || getsaveExit() === true) {
                setSaveExit(false);
                resetPoll();
                return true;
            }
            if (location.pathname && !isConfirmationPending) {
                setNextRouterPath({ pathname: location.pathname, search: location.search });
                setAlertShow(true);
                return false;
            }
            return true;
        });

        if (isConfirmationPending) {
            resetPoll();
            history.push(`${nextRouterPath.pathname}${nextRouterPath.search}`);
            setIsConfirmationPending(false);
            unblock();
        }

        return () => {
            unblock();
        };
    }, [history, isConfirmationPending, nextRouterPath]);

    useEffect(() => {
        setTimeout(() => {
            switch (questions[currQueDisplay - 1].questionType) {
                case QUESTION_TYPES.SA.name:
                    if (focusSAElem !== -1) {
                        document.getElementsByName("question-text")[focusSAElem].focus();
                        setFocusSAElem(-1);
                    }
                    break;

            }
        }, 500);
    }, [currQueDisplay, focusSAElem]); // focusMCElem, focusTFElem, focusFITBElem, focusCIElem, focusMHElem, focusWCElem, focusRKElem

    // useEffect(() => {
    //     buildConnection();
    //     return () => {
    //         closeConnection();
    //     }
    // }, []);


    const validatePoll = (focusError = true) => {
        if (!validateText(title, POLL_TITLE_LENGTH)) {
            if (focusError) {
                notifyError(ATTENDANCE_TITLE_ERROR);
                document.getElementsByName("pollTitle")[0].focus();
            }
            return false;
        }

        let i = 0;
        for (let q of questions) {
            i++;
            if (q.weightage > 1000) {
                if (focusError) {
                    let errorWithQuestion = "Question " + i + ": " + QUESTION_MAX_POINTS_ERROR;
                    notifyError(errorWithQuestion);
                    setCurrQueDisplay(q.serialNo);
                }
                return false;
            }
        }

        let index = 0;
        for (let q of questions) {
            index++;
            if (focusError) setCurrQueDisplay(q.serialNo);
            let { quesError, elemIndex } = questionError(q, POLL_TYPE.ATTENDANCE);
            switch (q.questionType) {
                case QUESTION_TYPES.SA.name:
                    if (focusError) {
                        setFocusSAElem(elemIndex);
                    }
                    break;

            }

            if (quesError) {
                if (focusError) {
                    setQuestions(chooseQuestion(q.serialNo, questions));
                    notifyError(quesError);
                    return false;
                }
            }
        }
        return true;
    };

    const savePollClose = async (normalSave = true) => {
        setDisableSave(true);
        setSaveExit(true);
        if (!validatePoll()) {
            setDisableSave(false);
            return;
        }

        let pollToAdd = {
            pollType: POLL_TYPE.ATTENDANCE,
            questions: questions.map(q => {
                q.timeLimit = userSettings[USER_SETTINGS.ATTENDANCE_DURATION];
                return q;
            }),
            pollTitle: title,
            pollShareMode: POLL_SHARE_MODE.UNKNOWN,
            geofence: geofence,
            syncLms: syncLms,
            manualEnd: userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END],
            attendanceDuration: userSettings[USER_SETTINGS.ATTENDANCE_DURATION],
            audienceRestriction: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED,
            pptAutoShare: userSettings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE]
        };

        if (!!pollKey.current) {
            pollToAdd.pollKey = pollKey.current;
        }

        let result = await saveAndPublish(pollToAdd);
        if (!result) {
            setDisableSave(false);
            return;
        }

        setDisableSave(false);
        window.onbeforeunload = undefined;
        // history.push('/home');
        goPollsPage();
    };

    useEffect(() => {
        return () => {
            window.onbeforeunload = undefined;
        };
    }, []);

    const resetPoll = () => {
        pollKey.current = "";
        setDisableSave(false);
        setPollTitle(ATTENDANCE_DEFAULT_TITLE.replace(DATE_TO_REPLACE, new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })));
        setQuestions(addQuestionPPT([], QUESTION_TYPES.SA.name, POLL_TYPE.ATTENDANCE));
        setTitle("");
        setQues([]);
        setType("");
        setGeofence({});
        setSyncLms(true);
    };

    useEffect(async () => {
        if (!history.location.state || !history.location.state.previous || history.location.state.previous !== location.pathname) {
            resetPoll();
            // if (history.location.state && history.location.state.pollType) {
            //     console.log("resetting poll type", history.location.state.pollType);
            //     history.replace({
            //         pathname: CREATE_POLL_PATH,
            //         state: { pollType: history.location.state.pollType, fromNavbar: true, previous: CREATE_POLL_PATH }
            //     });
            // } else {
            //     console.log("resetting to default poll type", GRADED_POLL)
            //     history.replace({
            //         pathname: CREATE_POLL_PATH,
            //         state: { pollType: GRADED_POLL, fromNavbar: true, previous: CREATE_POLL_PATH }
            //     })
            // };
        }
        const ques = getQues();
        if (JSON.stringify(ques) !== "[]") {
            setQuestions(ques);
        }
        // const questitle = getPollTitle(); // changing poll type or creating new poll/survey should not retain title
        // if (questitle != "") {
        //   setTitle(questitle);
        // }
        const pollTitle = getPollTitle();
        if (pollTitle && pollTitle !== "") {
            setTitle(pollTitle);
        }
        // const showAnswer = getShowAnswer();
        // setShowAnswerOnViewer(showAnswer);
        if (!pollKey) {
            fakeCall().then();
        }
    }, []);

    const handleTitleUpdate = (e) => {
        if (e.target.value.length <= QUESTION_LENGTH) {
            setQuestions(updateTitleInput(e.target.value, currQueDisplay - 1, questions));
        }
        else {
            notifyError(QUESTION_CHARACTER_LIMIT_EXCEEDED);
            setQuestions(updateTitleInput(e.target.value.substring(0, QUESTION_LENGTH), currQueDisplay - 1, questions));
        }
    };

    return (
        <>
            <YuJaGeneralAlert
                show={alertShow}
                setModalShow={setAlertShow}
                content={alertContent}
                config={alertConfig}
            />

            <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                <div className={styles.questionEditViewParentContainer}>
                    {isFromSidebar() &&
                        <div style={{position: "absolute"}}>
                            <div style={{height: 5}}/>
                            <div className={styles.goBackLink} onClick={goPollsPage}>
                                <MdArrowBackIosNew/> {GO_BACK_POLLS_LINK}
                            </div>
                            <div style={{height: 5}}/>
                        </div>
                    }
                    {(questions.length > 0) && <>
                        <Row style={{ marginTop: "36px" }}>
                            <Col className="quiz-title-col margin-vertical-auto no-padding ">
                                <YuJaTextBox
                                    label={CREATE_ATTENDANCE_LABEL}
                                    containerStyle={{
                                        color: "#006DC7",
                                        fontSize: isNarrow ? "18px" : "20px",
                                        
                                        width: "auto",
                                        padding: isNarrow ? 8 : 12,
                                        fontWeight: 400,
                                        borderRadius: 5
                                    }}
                                    placeholder={ATTENDANCE_TITLE_PLACEHOLDER}
                                    inputAlign={"center"}
                                    name={"pollTitle"}
                                    value={title}
                                    maxLength={POLL_TITLE_LENGTH}
                                    onChange={(e) => {
                                        if (e.target.value.length <= POLL_TITLE_LENGTH) {
                                            setTitle(e.target.value);
                                        }
                                        else {
                                            notifyError(ATTENDANCE_TITLE_CHARACTER_LIMIT_EXCEEDED);
                                            setTitle(e.target.value.substring(0, POLL_TITLE_LENGTH));
                                        }
                                    }}
                                    inputClass={"PollTitleInput"}
                                />
                            </Col>
                        </Row>

                        <div
                            className="create-question-body"
                            style={{
                                backgroundColor: "#FFFFFF",
                                padding: "min(2%, 20px)",
                                borderRadius: "9px",
                                border: "1px solid #858687",
                                flex: 1,
                                overflow: "auto"
                            }}
                        >
                            <BaseQuestionTitle
                                handleTitleUpdate={handleTitleUpdate}
                                questionTitle={questions[currQueDisplay - 1].queTitle}
                                placeholder={"Question"}
                                editable={true}
                            />

                            <SAQuestion
                                questions={questions}
                                setQuestions={setQuestions}
                                selectedQuestion={questions[currQueDisplay - 1]}
                                pollType={POLL_TYPE.ATTENDANCE}
                                geofence={geofence}
                                setGeofence={setGeofence}
                                syncLms={syncLms}
                                setSyncLms={setSyncLms}
                                mapInitialized={mapInitialized}
                                setMapInitialized={setMapInitialized}
                                userSettings={userSettings}
                            />
                        </div>

                        <div style={{ display: "flex", margin: "10px 0", justifyContent: "center" }}>
                            <YuJaButton title={"Save & Close Attendance"} onClick={savePollClose} disabled={disableSave} style={{fontSize: 14, width: "277px", height: "35px", padding: "7.941px", borderRadius: "4.21px", border: "1.336px solid #42296E", justifyContent: "center", alignItems: "center", display: "flex",}} label={SAVE_CLOSE_BUTTON_TEXT}>
                                {SAVE_CLOSE_BUTTON_TEXT}
                            </YuJaButton>
                        </div>
                    </>}
                </div>
            </Spin>
        </>
    );
}
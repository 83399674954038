import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import { Spin } from "../../components/standardization/YuJaLoading";
import { EXPAND_WINDOW_LABEL, JOIN_ACTIVITY_TEXT, MINIMIZE_WINDOW_LABEL, TEXTBOX, UNIQUECODE_TEXT, VIEW_POLL_CODE_TEXT } from "../../utils/constants";
import { notifySuccess } from "../../utils/helpers";
import { HOST_URL } from "../../utils/properties";
import { CLIPBOARD_COPY_SUCCESS, RESPONSE_LINK_SUCCESS } from "../../utils/toast-message-constants";
import { ReactComponent as CopyIcon } from "./images/COPY_ICON.svg";
import { ReactComponent as DOWNLOAD_BIG } from "./images/DOWNLOAD_ICON_BIG.svg";
import { ReactComponent as DropDown } from "./images/DROPDOWN_ICON.svg";
import { ReactComponent as DropUp } from "./images/DROPUP_ICON.svg";
import "./styles.css";
import {useWindowDimensions} from "react-native-web";

export default function ViewPollCode({data={}, loading=true, initialized=false, collapsed}) {
    let {width: windowWidth} = useWindowDimensions();
    const [minimized, setMinimized] = useState(window.innerHeight > 810 ? (!data || !data.index3Pk) : true);

    useEffect(() => {
        setMinimized(window.innerHeight > 810 ? (!data || !data.index3Pk) : true);
    }, [data]);

    const hostname = window.location.hostname;
    let shortName = '';
          if (hostname.includes(HOST_URL) && hostname.includes("www")) {
              shortName = hostname.indexOf('.') >= 0 ?
                  hostname.substring(hostname.indexOf('.') + 1, hostname.indexOf("engage.yuja.com") - 1) :
                  hostname;
          } else {
              shortName = hostname.indexOf('.') >= 0 ?
                  hostname.substring(0, hostname.indexOf('.')) :
                  hostname;
          }
    const INST_URL = shortName + '.' + HOST_URL;

    const [isHovered, setIsHovered] = useState(false);
    const [qrBackground, setqrBackground] = useState("#f6f6f6")

    const handleMouseEnter = () => {
        setIsHovered(true);
        setqrBackground("rgba(0, 0, 0, 0.6)");
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        setqrBackground("#f6f6f6");
    };


    const downloadQR = () => {
        const canvas = document.getElementById("1234");
        
        const backgroundColor = "#FFFFFF"; 
        
        const qrCodeWidth = canvas.width;
        const qrCodeHeight = canvas.height;
        
        const padding = 10; 
        
        const newWidth = qrCodeWidth + 2 * padding;
        const newHeight = qrCodeHeight + 2 * padding;
        
        const newCanvas = document.createElement("canvas");
        newCanvas.width = newWidth;
        newCanvas.height = newHeight;
        const newContext = newCanvas.getContext("2d");
        
        newContext.fillStyle = backgroundColor;
        newContext.fillRect(0, 0, newWidth, newHeight);
        
        newContext.drawImage(canvas, padding, padding, qrCodeWidth, qrCodeHeight);

        const pngUrl = newCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${data.index3Pk.substring(3)}.png`;
        
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return(<div style={{marginBottom: "15px"}}>
        {minimized &&
            <div className="smallPanelBodyNew" style={{width: "100%"}}>
                <span className="smallPanelTitle" tabIndex={collapsed? -1 : 0} role={TEXTBOX}>How to Join The Activity?</span>
                <DropDown onMouseDown={e => e.preventDefault()} tabIndex={collapsed? -1 : 0} aria-label={EXPAND_WINDOW_LABEL} style={{cursor: "pointer"}} onClick={() => setMinimized(false)}/>
            </div>
        }
        {!minimized && 
        <div className="smallPanelBody2" style={{width: "100%"}}>
            <Spin size="large" wrapperClassName="panelSpin" spinning={false} tip="Loading..." style={{height: "100%"}}>
                {(initialized || (data && data.index3Pk)) && <div style={{width: "100%"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <span className="smallPanelTitle" tabIndex={collapsed? -1 : 0} role={TEXTBOX}>{JOIN_ACTIVITY_TEXT}</span>
                        <DropUp onMouseDown={e => e.preventDefault()} tabIndex={collapsed? -1 : 0} aria-label={MINIMIZE_WINDOW_LABEL} style={{cursor: "pointer"}} onClick={() => setMinimized(true)}/>
                    </div>
                    <div style={{ marginTop: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                            <div style={{position:"relative"}}>
                            <QRCode
                                id = "1234"
                                size={windowWidth > 1300 ? 128 : 100}
                                bgColor={qrBackground}
                                renderAs="canvas"
                                style={{cursor: "pointer"}}
                                value={INST_URL + "/qr/" + data.index3Pk.substring(3)}
                                onClick={async () => {await setqrBackground("#f6f6f6"); downloadQR();}} 
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            />
                            {isHovered && <DOWNLOAD_BIG className= "downloadIcon" style = {{width: "64px", height: "64px", cursor: "pointer", left: "25%", top: "25%"}}
                                onClick={async () => {await setqrBackground("#f6f6f6"); downloadQR();}} 
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}> </DOWNLOAD_BIG>}
                            </div> 
                            <div style={{display:"flex", flexDirection: "column", justifyContent: "center"}}>
                                <CopyToClipboard text={data.index3Pk.substring(3)} onCopy={()=> {notifySuccess(CLIPBOARD_COPY_SUCCESS.replace(UNIQUECODE_TEXT, data.index3Pk.substring(3)))}}>
                                <div className="gradientWrap" style={{cursor: "pointer"}}>
                                    <span className="gradientBtn pollCodePanelSubtitle" tabIndex={collapsed? -1 : 0} role={TEXTBOX} style={{padding: "8px 10px", fontSize: 18}}>{data.index3Pk.substring(3)}  <CopyIcon style={{margin: "2px"}}/></span>
                                </div>
                                </CopyToClipboard>
                                <div style={{marginTop: "20px"}}></div>
                                <CopyToClipboard text={`${INST_URL}/qr/${data.index3Pk.substring(3)}`} onCopy={()=> {notifySuccess(RESPONSE_LINK_SUCCESS)}}>
                                <div className="gradientWrap" style={{cursor: "pointer"}}>
                                    <span className="gradientBtn linkPanelSubtitle" tabIndex={collapsed? -1 : 0} role={TEXTBOX} style={{ fontSize: 18}}>Link<CopyIcon style={{margin: "2px 2px 2px 10px"}}/></span>
                                </div>
                                </CopyToClipboard>
                            </div>
                        </div>
                        <div className="pollCodePanelText" tabIndex={collapsed? -1 : 0} role={TEXTBOX}>
                            {VIEW_POLL_CODE_TEXT}
                        </div>
                    </div>
                </div>}
            </Spin>
        </div>}
    </div>);
}
